import { Component, OnInit, EventEmitter, Output, ViewChild, ElementRef } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Item, ItemResult, Category, SubCategory } from '../../_models';
import { LookupService, AuthenticationService } from '../../_services';
import { AngularFileUploaderComponent, AngularFileUploaderConfig } from 'angular-file-uploader';
import * as _ from 'underscore';
import { HttpRequest } from '@angular/common/http';
import { Observable, Subject, of, concat } from 'rxjs';
import { distinctUntilChanged, tap, switchMap, catchError, delay } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { GalleryItem, ImageItem, GalleryConfig, LoadingStrategy, SlidingDirection, ThumbnailsMode, ThumbnailsPosition } from '../../shared/index';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'event-item',
  templateUrl: './event-item.component.html',
  styleUrls: ['./event-item.component.scss']
})
export class EventItemComponent implements OnInit {
  itemResult: ItemResult;
  itemChangeEvent: EventEmitter<ItemResult> = new EventEmitter();
  isEdit: boolean;
  eventIdentifier: string;
  item_kind: string;
  categories: Category[];
  subCategories: SubCategory[];

  subCategories$: Observable<SubCategory[]>;
  peopleLoading = false;
  subcategoryInput$ = new Subject<string>();
  images: GalleryItem[];
  config: GalleryConfig | any;
  public addTagNowRef: (name) => void;
  loading = false;

  @ViewChild('fileUpload')
  private fileUpload: AngularFileUploaderComponent;

  afuConfig1: AngularFileUploaderConfig;

  get title() {
    return this.isEdit ? `Edit: ${this.itemResult.item.name}` : 'Add Item';
  }

  constructor(private bsModalRef: BsModalRef, private lookupService: LookupService, private authService: AuthenticationService) {
    this.getCategories();
    this.getSubCategories();
    this.addTagNowRef = (name) => this.addNewSubCategory(name);
  }

  transformAmount(element){
    this.itemResult.item.cost = Number(this.itemResult.item.formattedCost.replace('$', ''));
    if (this.itemResult.item.cost)
    {
      this.itemResult.item.formattedCost = '$' + this.itemResult.item.cost;
    }
}

  prepareGalleryImages () {
    this.images = [];
    this.itemResult.item.pictures?.map(picture => {
      this.images.push(
        new ImageItem({ src: decodeURIComponent(picture).replace('+', '%2B'), thumb: decodeURIComponent(picture).replace('+', '%2B') }),
      );
    })
  }

  ngOnInit() {
    this.config = {
      nav: true,
      loop: true,
      zoomOut: 0,
      dots: false,
      dotsPosition: 'bottom',
      counterPosition: 'top',
      thumb: true,
      counter: true,
      edit: true,
      gestures: true,
      autoPlay: false,
      thumbWidth: 120,
      thumbHeight: 90,
      imageSize: 'contain',
      panSensitivity: 25,
      disableThumb: false,
      playerInterval: 3000,
      thumbMode: ThumbnailsMode.Free,
      thumbPosition: ThumbnailsPosition.Bottom,
      loadingStrategy: LoadingStrategy.Default,
      slidingDirection: SlidingDirection.Horizontal,
    };
    console.log(`On Init Item: ${JSON.stringify(this.itemResult)}`);
    console.log(`On Is Edit: ${this.isEdit}`);
    console.log(`Item Kind: ${this.item_kind}`);
    console.log(`On EventIdentifier: ${this.eventIdentifier}`);
    this.prepareGalleryImages();
    this.itemResult.item.formattedCost = '0';
    if (this.itemResult.item.cost)
    {
      this.itemResult.item.formattedCost = '$' + this.itemResult.item.cost;
    }
    this.afuConfig1 = {
      multiple: true,
      limitSelection: 2,
      uploadAPI: {
        url: `${environment.api_origin}/event`,
        headers: {
          Authorization: `Bearer ${this.authService.sessionUser.token}`,
          EventIdentifier: this.eventIdentifier,
          PicturesToDeleteCount: '0'
        }
      },
      hideResetBtn: true
    };

  }

  getCategories() {
    this.lookupService.getCategories().subscribe(data => {
      this.categories = data;
    });
  }

  getSubCategories() {
    this.lookupService.getSubCategories().subscribe(data => {
      this.subCategories = data;
      // this.subCategories$ = of(data);
      // this.loadSubcategories();
    });
  }
  onDeleteClick(e) {
    if (!this.itemResult.item.pictures_to_delete) {
      this.itemResult.item.pictures_to_delete = [];
    }
    if (this.itemResult.item.pictures[e]) {
      this.itemResult.item.pictures_to_delete.push(this.itemResult.item.pictures[e]);
      this.itemResult.item.pictures.splice(e, 1);
    }
    this.prepareGalleryImages();

  }

  onClose() {
    this.bsModalRef.hide();
  }

  addNewSubCategory(tag: string) {
    console.log(`Category: ${JSON.stringify(this.itemResult)}`);
    return { category_name: this.itemResult.item.category, name: tag };
  }

  DocUpload(httpRequest) {
    this.itemResult.status = 'Ok';
    this.itemResult.item.approval_status = 'Pending';
    this.itemResult.item.pictures = [...this.itemResult.item.pictures, ...JSON.parse(httpRequest.response)];
    console.log(`item: ${JSON.stringify(this.itemResult)}`);
    this.itemChangeEvent.emit(this.itemResult);
    this.bsModalRef.hide();
  }

  saveItem() {
    this.loading = true;
    if (this.fileUpload.selectedFiles.length) {
      this.fileUpload.headers = { ...this.afuConfig1.uploadAPI.headers, PicturesToDeleteCount: this.itemResult.item.pictures_to_delete.length.toString() };
      //this.afuConfig1.uploadAPI.headers = {...this.afuConfig1.uploadAPI.headers, PicturesToDeleteCount: this.itemResult.item.pictures_to_delete.length.toString()}
      this.fileUpload.uploadFiles();
    } else {
      this.itemResult.status = 'Ok';
      console.log(`item: ${JSON.stringify(this.itemResult)}`);
      this.itemChangeEvent.emit(this.itemResult);
      this.bsModalRef.hide();
    }
  }

  onItemSelect(item: any) {
    console.log(item);
  }
  onSelectAll(items: any) {
    console.log(items);
  }

}
