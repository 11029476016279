<div class="app flex-row align-items-center">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-8">
        <div class="card-group mb-0">
          <div class="card p-4">
            <div class="card-body loginBox">
              <h1>Sign in</h1>
              <form name="form" (ngSubmit)="f.form.valid && login()" #f="ngForm" class="loginForm" novalidate>
                <p class="text-muted">Sign In to your account</p>
                <div class="input-group" [ngClass]="{ 'has-error': f.submitted && !username.valid}">
                  <span class="input-group-addon"><i class="icon-user"></i></span>
                  <input type="text" class="form-control" placeholder="Username or Email Address" name="username"
                    [(ngModel)]="model.username" #username="ngModel" required />
                </div>
                <div *ngIf="f.submitted && !username.valid" class="has-error"><span class="help-block">Username is
                    required</span></div>
                <div class="input-group mt-3" [ngClass]="{ 'has-error': f.submitted && !password.valid }">
                  <span class="input-group-addon"><i class="icon-lock"></i></span>
                  <input type="password" class="form-control" name="password" placeholder="Password"
                    [(ngModel)]="model.password" #password="ngModel" required />

                </div>
                <div *ngIf="f.submitted && !password.valid" class="has-error"><span class="help-block">Password is
                    required</span> </div>
                <div class="mt-3 loginbuttonwrap">
                  <a [routerLink]="['/forgotpassword']" tooltip="Click here to reset your password through your email"
                    class="btn btn-link px-0 forgotPass">Forgot Password ? Click Here </a>
                  <button [disabled]="loading" class="btn btn-primary px-4">Login</button>
                  <p></p>
                  <a [routerLink]="['/search-sale']" class="btn btn-link px-0 search-btn" target="_blank">Search for
                    Yard, Garage and
                    Moving Sales</a>
                  <p></p>
                  <a [routerLink]="['/help-assist-set-up']" class="btn btn-link px-0 search-btn" target="_blank"> Need
                    help setting up your Event Sale? Click here</a>

                  <img *ngIf="loading"
                    src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                </div>
              </form>
            </div>
          </div>
          <div class="card text-white bg-primary py-5" style="width:100%">
            <!--hidden-md-down-->
            <div class="card-body text-center">
              <div>
                <img style="width: 100%" src="../assets/img/logo-transparent.png">
              </div>
              <div>
                <h6>Enter your details and start the journey with Yargar Sales Broadcaster</h6>
                <button type="button" tooltip="Create your account" class="btn button button-white btn-primary mt-3"
                  [routerLink]="['/register']">Register</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-ad-banner></app-ad-banner>
  

  <!--1-->
  <div>ADVERTISEMENT</div>
  <div id="rowAdBanner" class="row">
    <a id="bannerLink"
      href="https://4ff43obio638tsj5o852ga3ked.hop.clickbank.net/?&traffic_source=YargarSite&traffic_type=paid&campaign=Aug2024&creative=b1&ad=Ag1HomePage"
      onclick="void window.open(this.href); return false;">
      <img id="bannerImage" src="../assets/img/adverts/img/recprofit.png" width="600" height="200"
        alt="recprofit:  Recession Profit Secrets V2 Ad">
    </a>
  </div>
  
  <!--2-->
  <div>ADVERTISEMENT</div>

  <div id="rowAdBanner" class="row">
    <a id="bannerLink"
      href="https://76866vppm657quibnbqgv6lnfh.hop.clickbank.net/?&traffic_source=YargarSite&traffic_type=paid&campaign=Aug2024&creative=c1&ad=Ag2SearchPage"
      onclick="void window.open(this.href); return false;">
      <img id="bannerImage" src="../assets/img/adverts/img/Ikaria.png" width="600" height="200"
        alt="lbjuice:  Ikaria Juice Ad">
    </a>
  </div>



  <!--3-->
  <div>ADVERTISEMENT</div>
  <div id="rowAdBanner" class="row">
    <a id="bannerLink"
      href="https://3a751ulhw83huhrxx-sd-8plqf.hop.clickbank.net/?&traffic_source=YargarSite&traffic_type=paid&campaign=Jun2024&creative=b1&ad=Ag1HomePage"
      onclick="void window.open(this.href); return false;">
      <img id="bannerImage" src="../assets/img/adverts/img/brainwclub.png" width="600" height="200"
        alt="brainwclub: Brainwave Ad">
    </a>
  </div>

  <!--4-->
  <div>ADVERTISEMENT</div>
  <div id="rowAdBanner" class="row">
    <a id="bannerLink"
      href="https://df633vhmnxwlwtgew3xzte3m8u.hop.clickbank.net/?&traffic_source=YargarSite&traffic_type=paid&campaign=Jun2024&creative=b1&ad=Ag1HomePage"
      onclick="void window.open(this.href); return false;">
      <img d="bannerImage" src="../assets/img/adverts/img/buildacontainerhome.png" width="600" height="200"
        alt="imcoders: Build Container Home Ad">
    </a>
  </div>

  <!--5-->
  <div>ADVERTISEMENT</div>
  <div id="rowAdBanner" class="row">
    <a id="bannerLink"
      href="https://eb9c7ieci-xgumo1lmkhw6rfnp.hop.clickbank.net/?&traffic_source=YargarSite&traffic_type=paid&campaign=Aug2024&creative=b1&ad=Ag1HomePage"
      onclick="void window.open(this.href); return false;">
      <img id="bannerImage" src="../assets/img/adverts/img/diyhomeene.png" width="600" height="200"
        alt="diyhomeene: DIY Home Energy Ad">
    </a>
  </div>

  <!--6-->
  <div>ADVERTISEMENT</div>
  <div id="rowAdBanner" class="row">
    <a id="bannerLink"
      href="https://447b8rmhv4uhygh22lj3x22ve4.hop.clickbank.net/?&traffic_source=YargarSite&traffic_type=Paid&campaign=Aug2024&creative=b1&ad=Ag1HomePage"
      onclick="void window.open(this.href); return false;">
      <img id="bannerImage" src="../assets/img/adverts/img/homedoc.png" width="600" height="200"
        alt="homedoc: Home Doctor Ad">
    </a>
  </div>

  <!--7-->
  <div>ADVERTISEMENT</div>
  <div id="rowAdBanner" class="row">
    <a id="bannerLink"
      href="https://07811komo73bzjriqijxzlom17.hop.clickbank.net/?&traffic_source=YargarSite&traffic_type=paid&campaign=Aug2024&creative=b1&ad=Ag1HomePage"
      onclick="void window.open(this.href); return false;">
      <img id="bannerImage" src="../assets/img/adverts/img/JavaBurn.png" width="600" height="200"
        alt="JavaBurn: JavaBurn Ad">
    </a>
  </div>


  <!--8-->
  <div>ADVERTISEMENT</div>
  <div id="rowAdBanner" class="row">
    <a id="bannerLink"
      href="https://84b15ldnv8vi2pobnn4i-39r0k.hop.clickbank.net/?&traffic_source=YargarSite&traffic_type=paid&campaign=Aug2024&creative=b1&ad=Ag1HomePage"
      onclick="void window.open(this.href); return false;">
      <img d="bannerImage" src="../assets/img/adverts/img/LivPure.png" width="600" height="200"
        alt="livpure: Liv Pure Ad">
    </a>
  </div>


  <!--9-->
  <div>ADVERTISEMENT</div>
  <div id="rowAdBanner" class="row">
    <a id="bannerLink"
      href="https://43a28uddo6070hg9w3ygs5q0cd.hop.clickbank.net/?&traffic_source=YargarSite&traffic_type=Paid&campaign=Aug2024&creative=b1&ad=Ag1HomePage"
      onclick="void window.open(this.href); return false;">
      <img id="bannerImage" src="../assets/img/adverts/img/mmarriage.png" width="600" height="200"
        alt="mmarriage: Mend the Marriage Ad">
    </a>
  </div>


  <!--10-->
  <div>ADVERTISEMENT</div>
  <div id="rowAdBanner" class="row">
    <a id="bannerLink"
      href="https://4bd92pjksx6h2ni8mhvao58oe9.hop.clickbank.net/?&traffic_source=YargarSite&traffic_type=paid&campaign=Aug2024&creative=b1&ad=Ag1HomePage"
      onclick="void window.open(this.href); return false;">
      <img id="bannerImage" src="../assets/img/adverts/img/NaganoTonic.png" width="600" height="200"
        alt="lbtonic: Nagano tonic Ad">
    </a>
  </div>


  <!--11-->
  <div>ADVERTISEMENT</div>

  <div id="rowAdBanner" class="row">
    <a id="bannerLink"
      href="https://a6fe0lkcm6vhzsp3co8m3b3ztl.hop.clickbank.net/?&traffic_source=YargarSite&traffic_type=paid&campaign=Aug2024&creative=b1&ad=Ag1HomePage"
      onclick="void window.open(this.href); return false;">
      <img id="bannerImage" src="../assets/img/adverts/img/easywriter.png" width="600" height="200"
        alt="easywriter: paidonlinewritingjobs.com Ad">
    </a>
  </div>


  <!--12-->
  <div>ADVERTISEMENT</div>

  <div id="rowAdBanner" class="row">
    <a id="bannerLink"
      href="https://57c90opiw84d0fjft3r5xdihjx.hop.clickbank.net/?&traffic_source=YargarSite&traffic_type=paid&campaign=Aug2024&creative=b1&ad=Ag1HomePage"
      onclick="void window.open(this.href); return false;">
      <img id="bannerImage" src="../assets/img/adverts/img/socialpaid.png" width="600" height="200"
        alt="socialpaid: payingsocialmediajobs.com Ad">
    </a>
  </div>

  <!--13-->

  <div>ADVERTISEMENT</div>
  <div id="rowAdBanner" class="row">
    <a id="bannerLink"
      href="https://c9931rjjn6581rkirlw9mc4t92.hop.clickbank.net/?&traffic_source=YargarSite&traffic_type=paid&campaign=Jun2024&creative=b1&ad=Ag1HomePage"
      onclick="void window.open(this.href); return false;">
      <img d="bannerImage" src="../assets/img/adverts/img/Septifix.png" width="600" height="200"
        alt="Septifix: Septifix Ad">
    </a>
  </div>



  <!--14-->

  <div>ADVERTISEMENT</div>

  <div id="rowAdBanner" class="row">
    <a id="bannerLink"
      href="https://3cc6fshkv9virjj6cod7ctarcq.hop.clickbank.net/?&traffic_source=yargarsite&traffic_type=paid&campaign=Aug2024&creative=b1&ad=Ag1HomePage"
      onclick="void window.open(this.href); return false;">
      <img id="bannerImage" src="../assets/img/adverts/img/j1r2c.png" width="600" height="200"
        alt="j1r2c: John Crestani Autowebinar Ad">
    </a>
  </div>

  <!--15-->
  <div>ADVERTISEMENT</div>

  <div id="rowAdBanner" class="row">
    <a id="bannerLink"
      href="https://8982cmqqi3wipmomk01on41i16.hop.clickbank.net/?&traffic_source=YargarSite&traffic_type=paid&campaign=Jun2024&creative=b1&ad=Ag1HomePage"
      onclick="void window.open(this.href); return false;">
      <img id="bannerImage" src="../assets/img/adverts/img/Tedswoodworking.png" width="600" height="200"
        alt="tedsplans: Tedswoodworking Ad">
    </a>
  </div>

  <!--16-->

  <div>ADVERTISEMENT</div>
  <div id="rowAdBanner" class="row">
    <a id="bannerLink"
      href="https://fc064uopk1ue3knomfq9jxyg0k.hop.clickbank.net/?&traffic_source=YargarSite&traffic_type=paid&campaign=Jun2024&creative=b1&ad=Ag1HomePage"
      onclick="void window.open(this.href); return false;">
      <img id="bannerImage" src="../assets/img/adverts/img/VinCheckUp.png" width="600" height="200"
        alt="vincheckup: VIN Check UP Ad">
    </a>
  </div>

  <!--17-->

  <div>ADVERTISEMENT</div>
  <div id="rowAdBanner" class="row">
    <a id="bannerLink"
      href="https://fc9eainmu30kwftdyhs7omap3d.hop.clickbank.net/?&traffic_source=YargarSite&traffic_type=paid&campaign=Jun2024&creative=b1&ad=Ag1HomePage"
      onclick="void window.open(this.href); return false;">
      <img id="bannerImage" src="../assets/img/adverts/img/vipindicators.png" width="600" height="200"
        alt="fxlife: VIP Indicators Ad">
    </a>
  </div>


  <!--18-->

  <div>ADVERTISEMENT</div>
  <div id="rowAdBanner" class="row">
    <a id="bannerLink"
      href="https://fa51apcdh85bzhirh9sow6svdj.hop.clickbank.net/?&traffic_source=YargarSite&traffic_type=paid&campaign=Aug2024&creative=b1&ad=Ag1HomePage"
      onclick="void window.open(this.href); return false;">
      <img id="bannerImage" src="../assets/img/adverts/img/wealthdna.png" width="600" height="200"
        alt="wealthdna: Wealth DNA Code Ad">
    </a>
  </div>

  <!--19-->
  <div>ADVERTISEMENT</div>
  <div id="rowAdBanner" class="row">
    <a id="bannerLink"
      href="https://9c6cfrdnqv0kpjqsi5xovmv79n.hop.clickbank.net/?&traffic_source=YargarSite&traffic_type=paid&campaign=Aug2024&creative=b1&ad=Ag1HomePage"
      onclick="void window.open(this.href); return false;">
      <img id="bannerImage" src="../assets/img/adverts/img/wealthsig.png" width="600" height="200"
        alt="wealthsig: Wealth Signal Ad">
    </a>
  </div>

  <!--20-->

  <div>ADVERTISEMENT</div>
  <div id="rowAdBanner" class="row">
    <a id="bannerLink"
      href="https://dfb86nejq6ygujgux4wcs8zetd.hop.clickbank.net/?&traffic_source=YargarSite&traffic_type=paid&campaign=Jun2024&creative=b1&ad=Ag1HomePage"
      onclick="void window.open(this.href); return false;">
      <img id="bannerImage" src="../assets/img/adverts/img/whatliesin.png" width="600" height="200"
        alt="whatliesin: What Lies in your Debt">
    </a>
  </div>

</div>
</div>