import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { AuthenticationService, AlertService } from '../_services/index';
import { CurrentUser } from '../_models';

@Component({
  moduleId: module.id.toString(),
  templateUrl: 'login.component.html'
})
export class LoginComponent implements OnInit, OnDestroy {
  model: any = {};
  loading = false;
  returnUrl: string;
  
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private alertService: AlertService
  ) {}
  ngOnDestroy(): void {
    const bodyTag = document.body;
    bodyTag.classList.remove('sidebar-hidden');
  }

  ngOnInit() {
    this.authenticationService.logout();

    this.returnUrl = this.route.snapshot.queryParams.returnUrl || 'home';
    const bodyTag = document.body;
    bodyTag.classList.add('sidebar-hidden');
  }

  login() {
    this.loading = true;
    this.authenticationService
      .login(this.model.username, this.model.password)
      .subscribe(
        (data: CurrentUser) => {
          // tslint:disable-next-line:one-line
          if (data) {
            // store user to local storage
            localStorage.setItem('currentUser', JSON.stringify(data));
          }
          this.router.navigate([this.returnUrl]);
          this.loading = false;
          this.authenticationService
            .auditLogin(this.model.username)
            .subscribe();
        },
        error => {
          console.log(error);
          console.log(error.error.message);
          // console.log(JSON.parse(error.error));
          // this.alertService.error(JSON.parse(error._body || error).messsage);
          this.alertService.error(error.error.message);
          this.loading = false;
        }
      );
  }
}
