import { Component, OnInit } from '@angular/core';

import { AlertService } from '../_services/index';
import { ToastModule } from 'primeng/toast';
import { Message, MessageService } from 'primeng/api';

@Component({
  // moduleId: module.id.toString(),
  // tslint:disable-next-line:component-selector
  selector: 'alert',
  templateUrl: 'alert.component.html',
  providers: [MessageService]
})
export class AlertComponent implements OnInit {

  private showMessage(msg: Message) {
    this.messageService.add(msg);
  }

  constructor(private alertService: AlertService, private messageService: MessageService) {}

  ngOnInit() {
    this.alertService.getMessage().subscribe(message => {
      this.showMessage(message);
    });
  }
}
