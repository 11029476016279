<p-carousel [value]="ads" styleClass="custom-carousel" [numVisible]="1" [numScroll]="1" [circular]="true"
    [autoplayInterval]="5000">
    <p-header>
        <div class="container">
            <div class="row justify-content-center">
              <header id="Heading5">
                <h5>ADVERTISEMENTS </h5>
              </header> 
            </div>  
          </div>
    </p-header>
    <ng-template let-ad pTemplate="item">
        <div class="container">
            <div class="row justify-content-center">
                <a id="bannerLink" href="{{ad.adLink}}"
                    onclick="void window.open(this.href); return false;">
                    <img id="bannerImage" src="{{ad.imagePath}}" width="{{ad.imageWidth}}" height="{{ad.imageHeight}}"
                        alt="{{ad.label}}">
                </a>
            </div>
        </div>
    </ng-template>
    <ng-template let-ad pTemplate="item">
        <div class="container">
            <div class="row justify-content-center">
                <a id="bannerLink" href="{{ad.adLink}}"
                    onclick="void window.open(this.href); return false;">
                    <img id="bannerImage" src="{{ad.imagePath}}" width="{{ad.imageWidth}}" height="{{ad.imageHeight}}"
                        alt="{{ad.label}}">
                </a>
            </div>
        </div>
    </ng-template>
</p-carousel>