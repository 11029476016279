<div class="animated fadeIn infoBox col-8 mx-auto">
  <div class="row">
    <div class="col-lg-12 customLG">
      <div class="card">
        <div class="card-header">
          <i class="fa fa-at"></i> <b>Contact Us</b>
        </div>
        <div class="card-body">
          <form name="form" (ngSubmit)="f.form.valid && sendContactReqeust()" #f="ngForm" novalidate>



            <h6>You can fill out the form below with your comments or questions.</h6>
            <div class="row">
              <div class="form-group col-sm-4">
                <label class="col-form-label" for="firstName">First Name</label>
                <strong class="text-danger">*</strong>
                <div class="controls">
                  <div class="input-prepend input-group" [ngClass]="{ 'has-error': f.submitted && !firstName.valid }">
                    <input placeholder="First Name" type="text" class="form-control" name="firstName"
                      [(ngModel)]="model.firstName" #firstName="ngModel" required />
                  </div>
                  <div *ngIf="f.submitted && !firstName.valid" class="has-error">
                    <span class="help-block">First Name is required</span>
                  </div>
                </div>
              </div>
              <div class="form-group col-sm-4">
                <label class="col-form-label" for="lastName">Last Name</label>
                <strong class="text-danger">*</strong>
                <div class="controls">
                  <div class="input-group" [ngClass]="{ 'has-error': f.submitted && !lastName.valid }">
                    <input placeholder="Last Name" type="text" class="form-control" name="lastName"
                      [(ngModel)]="model.lastName" #lastName="ngModel" required />
                  </div>
                  <div *ngIf="f.submitted && !lastName.valid" class="has-error">
                    <span class="help-block">Last Name is required</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-sm-8">
                <label class="col-form-label" for="firstName">Email Address</label>
                <strong class="text-danger">*</strong>
                <div class="controls">
                  <div class="input-prepend input-group" [ngClass]="{ 'has-error': f.submitted && !email.valid }">
                    <input placeholder="Email" type="text" class="form-control" name="email" [(ngModel)]="model.email"
                      #email="ngModel" required email />
                  </div>
                  <div *ngIf="f.submitted && !email.valid" class="has-error">
                    <span *ngIf="email?.errors?.required" class="help-block">Email address is required.</span>
                    <span *ngIf="!email?.errors?.required && email?.errors?.email" class="help-block">Invalid email
                      address</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-sm-8">
                <label class="col-form-label" for="comments">Comments</label>
                <div class="controls">
                  <div class="input-prepend input-group">
                    <textarea name="Comments" class="form-control" style="width:250px; height:100px;"
                      [(ngModel)]="model.comments" #comments="ngModel"></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-actions">
              <button [disabled]="loading" class="btn btn-primary mr-2">Send</button>
              <button class="btn btn-secondary" type="reset">Reset</button>
            </div>
            <div class="row">
              <div class="form-group col-sm-8">
                <label> <br /><br />

                  <h6>Or feel free to send us a personal email</h6>
                  <a
                    href="mailto:info@YardGarageMovingSales.com?subject=Yard, Garage And Moving Sale Inquiry"><b>info@YardGarageMovingSales.com</b></a>
                </label>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-sm-8">
                <label> <br /><br />
                  <h6>Or give us a call at </h6>
                  <p>1 323 614 0612</p>
                </label>
              </div>
            </div>

            <div class="row">
              <div class="form-group col-sm-8">
                <label> <br /><br />
                  <h6>We can also be reached by mail, our mailing address is</h6>
                  <p>P.O. Box 27112</p>
                  <p>Barrie RPO Penetang</p>
                  <p>Ontario, L4M 6K4</p>
                  <p>Canada</p>
                </label>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>