<div class="app flex-row align-items-center">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-6">
        <div class="card-group mb-0">
          <div class="card p-4">
            <div class="card-body">

              <h1>Forgot Password</h1>
              <form name="form" (ngSubmit)="f.form.valid && fpassword()" #f="ngForm" novalidate>
                <p class="text-muted">Provide your registered email address to send password reset request.</p>
                <div class="input-group mt-1" [ngClass]="{ 'has-error': f.submitted && !username.valid}">
                  <span class="input-group-addon">
                    <i class="icon-envelope"></i>
                  </span>
                  <input type="text" class="form-control" placeholder="Email" name="username"
                    [(ngModel)]="model.username" #username="ngModel" required email/>
                </div>
                <div *ngIf="f.submitted && !username.valid" class="has-error">
                  <span *ngIf="username?.errors?.required" class="help-block">Email address is required.</span>
                  <span *ngIf="username?.errors?.email" class="help-block">Invalid email address.</span>
                </div>
                <!-- <div class="input-group mt-1" [ngClass]="{ 'has-error': f.submitted && !password.valid }">
                  <span class="input-group-addon">
                    <i class="icon-lock"></i>
                  </span>
                  <input type="password" class="form-control" name="password" placeholder="Password" [(ngModel)]="model.password" #password="ngModel"
                    required />

                </div>
                <div *ngIf="f.submitted && !password.valid" class="has-error">
                  <span class="help-block">Password is required</span>
                </div>

                <div class="input-group mt-1" [ngClass]="{ 'has-error': f.submitted && !confirmPassword.valid }">
                  <span class="input-group-addon">
                    <i class="icon-lock"></i>
                  </span>
                  <input type="password" placeholder="Re-type Password" class="form-control" ngModel name="confirmPassword" #confirmPassword="ngModel"
                    [equalTo]="password" />
                </div>
                <div *ngIf="f.submitted && !confirmPassword.valid" class="has-error">
                  <span class="help-block">Retyped password must be matched with password</span>
                </div> -->
                <div class="form-group mt-3">
                  <button [disabled]="loading" class="btn btn-primary">Reset Password</button>
                  <img *ngIf="loading"
                    src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                  <a [routerLink]="['/login']" class="btn btn-link withoutBg">Cancel</a>
                </div>
              </form>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>