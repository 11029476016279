import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { CurrentUser } from '../_models/index';
import { UserService } from '../_services/index';

@Component({
    moduleId: module.id.toString(),
    templateUrl: 'home.component.html'
})
export class HomeComponent implements OnInit {
    currentUser: CurrentUser;
    videoUrl1;
    videoUrl2;
    // users: User[] = [];
    constructor(private userService: UserService, private domSanitizer: DomSanitizer) {
        this.currentUser = userService.sessionUser;
    }
    ngOnInit(): void {
        this.videoUrl1 = this.domSanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/HCtZvxBjgkc?si=qwOjZPEqykz_J8xR');
        this.videoUrl2 = this.domSanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/-IZw04_2F1Q?si=rSDmQ2mgocoiNqFQ');
    }
}

