import { Injectable, TemplateRef } from '@angular/core'
import { ErrorComponent } from '../shared/error/error.component'
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Injectable()
export class ErrorService
{
    modalRef: BsModalRef;

    // config = {
    //     animated: true,
    //     keyboard: true,
    //     backdrop: true,
    //     ignoreBackdropClick: true
    //   };

    constructor(private modalService: BsModalService) { }

    viewError(errorTitle?: string,
        errorMsg?: string,
        statusCode?: string,
        httprequestDetail?: string,
        errorDetail?: string) {
            const initialState ={  errorTitle: errorTitle, errorMsg:errorMsg, statusCode:statusCode, httprequestDetail: httprequestDetail, errorDetail: errorDetail };
        this.modalRef = this.modalService.show(ErrorComponent, { initialState } );
      }
}