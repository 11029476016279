import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

// Layouts
import { FullLayoutComponent } from './layouts/full-layout.component';
import { SimpleLayoutComponent } from './layouts/simple-layout.component';
import { LoginComponent } from './login';
import { ResetPasswordComponent } from './resetpassword';
import { NoaccessComponent, HomeComponent } from './home';
import { AuthGuard, RoleGuard } from './_guards';
import { ForgotPasswordComponent } from './forgotpassword/index';
import { UserProfileComponent } from './user/index';
import { RegisterComponent } from './register/register.component';
// import { LocationComponent } from './event/location.component';
// import { ScheduleComponent } from './event/schedule.component';
// import { ItemComponent } from './event/item.component';
import { EventSearchComponent } from './event-search/event-search.component';
import { AboutusComponent } from './pages/aboutus/aboutus.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';
import { CopyrightOwnershipComponent } from './pages/copyright-ownership/copyright-ownership.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { BenefitsuseComponent } from './pages/benefitsuse/benefitsuse.component';
import { EventDetailComponent } from './event-detail/event-detail.component';
import { OrganizeComponent } from './pages/organize/organize.component'
import { ReviewFreewillPayComponent } from './pages/review-freewill-pay/review-freewill-pay.component';
import { HelpAssistSetUpComponent } from './pages/help-assist-set-up/help-assist-set-up.component';
import { KeepMePostedComponent } from './pages/keep-me-posted/keep-me-posted.component';

// import { NgWizardComponent } from '@cmdap/ng-wizard';
// import {Step1Component} from './test/step1/step1.component';
// import {Step2Component} from './test/step2/step2.component';
// import {Step3Component} from './test/step3/step3.component';


const PUBLIC_ROUTES: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'register', component: RegisterComponent },
  { path: 'login', component: LoginComponent },
  { path: 'forgotpassword', component: ForgotPasswordComponent },
  { path: 'resetpassword', component: ResetPasswordComponent }
];

const PUBLIC_ROUTES_FL: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'search-sale', component: EventSearchComponent },
  { path: 'about-us', component: AboutusComponent },
  { path: 'privacy', component: PrivacyComponent },
  { path: 'changepassword', component: ResetPasswordComponent },
  { path: 'copyright-ownership', component: CopyrightOwnershipComponent },
  { path: 'contact-us', component: ContactUsComponent },
  { path: 'benefits-use', component: BenefitsuseComponent },
  { path: 'event-detail/:eventIndentifier', component: EventDetailComponent },
  { path: 'organize', component: OrganizeComponent },
  { path: 'review-freewill-pay', component: ReviewFreewillPayComponent },
  { path: 'help-assist-set-up', component: HelpAssistSetUpComponent },
  { path: 'subscribe', component: KeepMePostedComponent }
];

const SECURE_ROUTES: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'noaccess', component: NoaccessComponent, pathMatch: 'full' },
  // { path: 'home', component: HomeComponent, data: { expectedRole: ['Admin', 'customer', 'Tutor'] } },
  {
    path: 'user/profile', component: UserProfileComponent, canActivateChild: [RoleGuard],
    data: { expectedRole: ['admin', 'customer'] }
  }
  // { path: 'event/location', component: LocationComponent, data: { expectedRole: ['admin', 'customer'] } },
  // { path: 'event/schedule', component: ScheduleComponent, data: { expectedRole: ['admin', 'customer'] } },
  // { path: 'event/items', component: ItemComponent, data: { expectedRole: ['admin', 'customer'] } }
];

const appRoutes: Routes = [
  // tslint:disable-next-line:max-line-length
  { path: '', component: FullLayoutComponent, canActivate: [AuthGuard], canActivateChild: [RoleGuard], data: { expectedRole: 'customer' }, children: SECURE_ROUTES },
  // { path: '', component: FullLayoutComponent, children: SECURE_ROUTES },
  { path: '', component: SimpleLayoutComponent, children: PUBLIC_ROUTES },
  { path: '', component: FullLayoutComponent, children: PUBLIC_ROUTES_FL },
  // { path : 'event', component: FullLayoutComponent, loadChildren: () => YagaEventModule},
  // tslint:disable-next-line: max-line-length
  { path: 'event', component: FullLayoutComponent, canActivate: [AuthGuard], loadChildren: () => import('./yaga-event/yaga-event.module').then(m => m.YagaEventModule) },
  { path: '**', redirectTo: 'index.html' }
];


// const routes: Routes = [
//   { path: 'test', component: NgWizardComponent, children: [
//       { path: 'location', component: Step1Component },
//       { path: 'schedule', component: Step2Component },
//       { path: 'items', component: Step3Component },
//       { path: '**', redirectTo: 'location' },
//   ], data: { name: 'myWizard' }},
//   { path: '**', redirectTo: '' },
// ];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes,
    {
      initialNavigation: 'enabled',
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      scrollOffset: [0, 64]
    })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
