import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { CurrentUser, ExchangeRefreshToken } from '../_models/index';
// import 'rxjs/add/operator/map';
import { map } from 'rxjs/operators';
import * as _ from 'underscore';
import { Router } from '@angular/router';
// import { httpFactory } from '@angular/http/src/http_module';

@Injectable()
export class AuthenticationService {
  // tslint:disable-next-line:one-line
  constructor(private http: HttpClient, private router: Router) {}

  login(userName: string, password: string) {
    // return this.http.post('/api/authenticate', JSON.stringify({username: userName, password: password}))
    return this.http.post(
      '/user/authenticate',
      JSON.stringify({ email: userName, password })
    );
  }

  redirectToLogin(){
    this.router.navigate(['login']);
  }

  auditLogin(email: string) {
    return this.http.post(`/user/auditLogin/${email}`, null);
  }

  // tslint:disable-next-line:one-line
  logout() {
    localStorage.clear();
    //sessionStorage.clear();
  }

  refreshAccessToken(): Observable<ExchangeRefreshToken> {
    const exchage_token = new ExchangeRefreshToken();
    exchage_token.access_token = this.sessionUser.token;
    exchage_token.refresh_token = this.sessionUser.refresh_token;

    return this.http.post<ExchangeRefreshToken>(
      '/token/refreshtoken',
      exchage_token
    );
  }

  public isAuthenticated(): boolean {
    return this.sessionUser ? true : false;
    // Check whether the token is expired and return
    // true or false
    // return !this.jwtHelper.isTokenExpired(token);
  }

  /**
   * Check user belongs to given roles or not
   * @param roles pass the roles to check user belongs to those roles or not.
   */
  public checkAuthorization(roles: string[]): Observable<boolean> {
    // if (this.isAuthenticated()) {
    //   // for now we have single role assigned to user.
    //   const userRole = this.sessionUser.user_type.toUpperCase().trim();
    //   const upperCaseRoles = _.map(roles, role => role.toUpperCase().trim());

    //   const hasRole = _.contains(upperCaseRoles, userRole);
    //   return of(hasRole);
    // } else {
    //   return of(false);
    // }
    return of(true);
  }

  get sessionUser(): CurrentUser {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    // tslint:disable-next-line:curly
    if (user) return user;
    return null;
  }
}
