<!-- <div class="app flex-row align-items-center">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-6">
        <div class="clearfix">
           <h1 class="float-left display-3 mr-4">404</h1>
          <h4 class="pt-3">Oops! No Access.</h4>
          <p class="text-muted">You are not allowed to access requested resource.</p>
        </div>
         <div class="input-prepend input-group">
          <span class="input-group-addon"><i class="fa fa-search"></i></span>
          <input id="prependedInput" class="form-control" size="16" type="text" placeholder="What are you looking for?">
          <span class="input-group-btn">
            <button class="btn btn-info" type="button">Search</button>
          </span>
        </div>
      </div>
    </div>
  </div>
</div> -->

<div class="animated fadeIn">
  <div class="row justify-content-center">
    <div class="col-md-6">
      <div class="clearfix">
        <!-- <h1 class="float-left display-3 mr-4">404</h1> -->
        <h4 class="pt-3">Oops! No Access.</h4>
        <p class="text-muted">You are not allowed to access requested resource.</p>
      </div>
      <!-- <div class="input-prepend input-group">
            <span class="input-group-addon"><i class="fa fa-search"></i></span>
            <input id="prependedInput" class="form-control" size="16" type="text" placeholder="What are you looking for?">
            <span class="input-group-btn">
              <button class="btn btn-info" type="button">Search</button>
            </span>
          </div> -->
    </div>
  </div>
</div>
