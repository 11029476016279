<div class="modal-dialog modal-lg">
    <div class="modal-content">
        <div class="modal-header">
            <div class="modal-title">
                <div class="row">
                    <h5>You have encountered an error. Please describe the issue you encountered and hit the submit button below</h5>                
                </div>
                <div class="row">
                    <h4>{{errorTitle}}</h4>
                </div>    
            </div>
            <button type="button" class="close pull-right" aria-label="Close" (click)="decline()">
                <span aria-hidden="true">&times;</span>
              </button>
        </div>
        <div class="modal-body text-center">
            <form name="form">
                <div class="modal-body">
                    <div class="row">
                        <textarea class="form-control" rows="6" type="text" name="errormsgdetail" readonly>{{errorMsg}}</textarea>
                    </div>
                    <div class="row">
                        <textarea class="form-control" rows="3" type="text" name="errorfeedbackdetail" placeholder="Additional Notes (Optional)">{{feedback}}</textarea>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" (click)="submitFeedback()" >Submit</button>
                    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="decline()">Close</button>
                </div>
            </form>
        </div>   
    </div>
</div>