import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Message } from 'primeng/api';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs/Subject';

@Injectable()
export class AlertService {
    private subject = new Subject<Message>();
    private keepAfterNavigationChange = false;

    constructor(private router: Router) {
        // clear alert message on route change
        router.events.subscribe(event => {
          if (event instanceof NavigationStart) {
           // alert(this.keepAfterNavigationChange);
                if (this.keepAfterNavigationChange) {
                    // only keep for a single location change
                    this.keepAfterNavigationChange = false;
                }
                // else {
                //     // clear alert
                //     this.subject.next();
                // }
            }
        });
    }

    success(message: string, keepAfterNavigationChange = false, timeOut?: number) {
        this.keepAfterNavigationChange = keepAfterNavigationChange;
        // tslint:disable-next-line:max-line-length
        this.subject.next({ severity: 'success', summary: 'Success', detail: message, life: timeOut }); //  { type: 'success', text: message });
    }

    error(message: string, keepAfterNavigationChange = false, timeOut?: number) {
      this.keepAfterNavigationChange = keepAfterNavigationChange;
      this.subject.next({ severity: 'error', summary: 'Error', detail: message, life: timeOut });
        // this.subject.next({ type: 'error', text: message });
    }

    warning(message: string, keepAfterNavigationChange = false, timeOut?: number) {
      this.keepAfterNavigationChange = keepAfterNavigationChange;
      this.subject.next({ severity: 'warn', summary: 'Warning', detail: message, life: timeOut });
        // this.subject.next({ type: 'error', text: message });
    }

    info(message: string, keepAfterNavigationChange = false, timeOut?: number) {
        this.keepAfterNavigationChange = keepAfterNavigationChange;
        this.subject.next({ severity: 'info', summary: 'Info', detail: message, life: timeOut });
          // this.subject.next({ type: 'error', text: message });
      }

    getMessage(): Observable<Message> {
        return this.subject.asObservable();
    }
}
