<div class="event-grid-view">
  <!-- <div class="event-grid-view-img-preivew">
    <img [src]="_eventImage" />
  </div> -->
  <div class="event-grid-view-title-area">
    {{_title}}
  </div>
  <!-- <div class="event-grid-view-price-area">
    ${{_price}}
  </div> -->
  <div class="event-grid-view-view-map">
    <a [routerLink]="_mapLink" target="_blank">
      view event
      <svg class="svg-icon" viewBox="0 0 20 20">
        <path d="M11.611,10.049l-4.76-4.873c-0.303-0.31-0.297-0.804,0.012-1.105c0.309-0.304,0.803-0.293,1.105,0.012l5.306,5.433c0.304,0.31,0.296,0.805-0.012,1.105L7.83,15.928c-0.152,0.148-0.35,0.223-0.547,0.223c-0.203,0-0.406-0.08-0.559-0.236c-0.303-0.309-0.295-0.803,0.012-1.104L11.611,10.049z"></path>
      </svg>
    </a>
  </div>
  <div class="event-grid-view-footer-info">
    <div class="date-time-area">
      <span></span>
      {{_eventDate}}
    </div>
    <div class="location-area">
      <span></span>
      {{_distance}} Mile
    </div>
  </div>
</div>
