import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { NavigationEnd, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

declare const gtag: Function;

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'yaga-app';

  constructor(public router: Router, @Inject(PLATFORM_ID) private platformId: Object) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (isPlatformBrowser(this.platformId)) {
          gtag('config', `'${environment.gtag}'`, { 'page_path': event.urlAfterRedirects });
        }
      }
    })
  }
}
