import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'KeyFilter'
})
export class KeyFilterPipe implements PipeTransform {
    transform(items: any[], field: string, value: any): any {
        if (!items || !field || (value == undefined || value == null)) {
            return items;
        }
        // filter items array, items which match and return true will be
        // kept, false will be filtered out
        // return items.filter(item => item[field].indexOf(value) !== -1);
        return items.filter(it => it[field] == value);
    }
}
