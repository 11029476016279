import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-copyright-ownership',
  templateUrl: './copyright-ownership.component.html',
  styleUrls: ['./copyright-ownership.component.scss']
})
export class CopyrightOwnershipComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
