<header class="app-header navbar">
  
  <button class="navbar-toggler d-lg-none" type="button" appMobileSidebarToggler>&#9776;</button>
  <a class="navbar-brand" href="#"></a>
  <button class="navbar-toggler d-md-down-none" type="button" appSidebarToggler>&#9776;</button>
  <!-- Anonymous -->
  <ng-container *ngIf="!IsUserLoggedIn">

    <ul class="nav navbar-nav ml-auto mr-2">
      <li *ngIf="!IsUserLoggedIn">
        <a class="nav-link" [routerLink]="['/login']"><i class="fa fa-sign-in"></i> Login</a>
      </li>
    </ul>
  </ng-container>

  <!-- LoggedIn -->
  <!-- <div *ngIf="IsUserLoggedIn"> -->
  <ng-container *ngIf="IsUserLoggedIn">
    <!-- <button class="navbar-toggler d-lg-none" type="button" appMobileSidebarToggler>&#9776;</button>
    <a class="navbar-brand" href="#"></a>
    <button class="navbar-toggler d-md-down-none" type="button" appSidebarToggler>&#9776;</button> -->
    <ul class="nav navbar-nav d-md-down-none">
      <!-- <li class="nav-item px-3">
      <a class="nav-link" href="#">&#9776;</a>
    </li> -->
      <li class="nav-item px-6">
        <a class="nav-link" href="#">Yard, Garage and Moving Sales</a>
      </li>
      
      <!--<li class="nav-item px-1">
      <a class="nav-link" href="#">Users</a>
    </li>
    <li class="nav-item px-1">
      <a class="nav-link" href="#">Settings</a>
    </li>-->
    </ul>
    <ul class="nav navbar-nav ml-auto mr-2">
      <li>
        <div class="testenv" *ngIf="isTest">THIS IS A TEST/DEMO SITES</div>
      </li>
      <!-- <li class="nav-item d-md-down-none">
        <a class="nav-link" href="#"><i class="icon-bell"></i><span class="badge badge-pill badge-danger">0</span></a>
      </li>
      <li class="nav-item d-md-down-none">
        <a class="nav-link" href="#"><i class="icon-list"></i></a>
      </li>
      <li class="nav-item d-md-down-none">
        <a class="nav-link" href="#"><i class="icon-location-pin"></i></a>
      </li> -->
      <li class="nav-item dropdown" dropdown (onToggle)="toggled($event)">

        <a href class="nav-link dropdown-toggle" dropdownToggle (click)="false">
          <img src="assets/img/avatars/noimage.png" class="img-avatar">
          <span class="d-md-down-none">{{currentUser.first_name}}</span>
        </a>
        <div class="dropdown-menu dropdown-menu-right" *dropdownMenu aria-labelledby="simple-dropdown">

          <div class="dropdown-header text-center"><strong>Account</strong></div>


          <a class="dropdown-item" [routerLink]="['/user/profile']"><i class="fa fa-user"></i> Profile</a>

          <a class="dropdown-item" [routerLink]="['/login']"><i class="fa fa-sign-out "></i> Logout</a>
        </div>
      </li>
      <!-- <button class="navbar-toggler d-md-down-none" type="button" appAsideMenuToggler>&#9776;</button> -->
    </ul>
  </ng-container>
  <!-- </div> -->
</header>
