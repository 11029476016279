<div class="bh-sl-container theme-new-green">
  <div id="page-header" class="banner-area-1">
    <h1 class="bh-sl-title">Search for Sale Event</h1>
  </div>

  <div class="bh-sl-form-container">
    <form name="form" (ngSubmit)="f.form.valid && search()" #f="ngForm" novalidate>
      <div class="form-group row">
        <div class="col-md-4">
          <div [ngClass]="{ 'has-error': f.submitted && !f.form.valid }">
            <input placeholder="Enter Street Name" type="text" class="form-control" [(ngModel)]="address" name="place"
              #place required />
            <span class="help-block">Enter Street Name to search for Yard, Garage and Moving Sales</span>
          </div>
          <div *ngIf="f.submitted && !f.form.valid" class="has-error"><span class="help-block">Place is
              required</span>
          </div>
        </div>
        <div class="col-md-3 make-flex-row">
          <div class="has-display-msg">
            <em> </em>
            <select class="form-control pdl-50" name="radious" [(ngModel)]="selectedRadius">
              <option *ngFor="let radios of radioses" [ngValue]="radios.value">
                {{radios.text}}</option>
            </select>
            <span class="help-block">Radius in km</span>
          </div>
          <button class="btn btn-primary bh-sl-submit search-btn" tooltip="Click here to Search"><i
              class="fa fa-search"></i></button>
        </div>
        <div class="col-md-5 grid-type-buttons">
          <div class="first-part">
            <label class="btn-grid-icon {{resultViewType === 'GridView' ? 'active' : ''}}"
              (click)="onViewRadioBtnClick('GridView')"></label>
            <label class="btn-list-icon {{resultViewType === 'ListView' ? 'active' : ''}} mg-left-15"
              (click)="onViewRadioBtnClick('ListView')"></label>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div id="bh-sl-map-container" class="bh-sl-map-container">
    <div class="bh-sl-loc-list">
      <div class="event-result {{resultViewType === 'GridView' ? 'grid-results' : 'list-results'}}">
        <div id="bh-sl-map"
          class="bh-sl-map bh-sl-map-open  {{resultViewType === 'GridView' ? 'grid-results' : 'list-results'}}"
          style="position: relative; overflow: hidden;">
          <agm-map #AgmMap [latitude]="center.lat" [longitude]="center.lng" (mapClick)="updateCenter($event)">
            <agm-circle [latitude]="+center.lat" [longitude]="+center.lng" [radius]="+selectedRadius"
              [fillColor]="'blue'" [strokePosition]="'OUTSIDE'" [strokeWeight]="'3'" [strokeColor]="'blue'"
              [circleDraggable]="false" [editable]="false" (radiusChange)="radiusChanged()">
            </agm-circle>

            <agm-marker [latitude]="center.lat" [longitude]="center.lng" [animation]="'DROP'"
              [iconUrl]="'assets/img/blue-marker.png'">
            </agm-marker>
            <agm-marker *ngFor="let marker of events;let i = index" [latitude]="marker.latitude"
              [longitude]="marker.longitude" [animation]="marker.animation" (mouseOver)="markerOver(marker)"
              (mouseOut)="markerOut(marker)" (markerClick)="openWindow(marker.id)">
              <agm-info-window [isOpen]="isInfoWindowOpen(marker.id)" [latitude]="marker.latitude"
                [longitude]="marker.longitude">
                <div class="event-list-view">
                  <div class="event-list-view-contents-area">
                    <div class="event-list-view-title-and-price">
                      <div class="event-list-view-title-area">
                        {{marker.sales_location}}
                      </div>
                    </div>
                    <div class="event-list-view-footer-info">
                      <div class="date-time-area">
                        <span></span>
                        {{marker.event_start_datetime | date:'mediumDate'}}
                      </div>
                      <div class="location-area">
                        <span></span>
                        {{marker.distance}} Mile
                      </div>
                    </div>
                    <div class="event-list-view-description">
                      {{[marker.city, marker.prov_state, marker.country, marker.postal_code]}}
                    </div>
                    <div class="event-list-view-view-map">
                      <a [routerLink]="['/event-detail',marker.event_id]" target="_blank">
                        view event
                        <svg class="svg-icon" viewBox="0 0 20 20">
                          <path
                            d="M11.611,10.049l-4.76-4.873c-0.303-0.31-0.297-0.804,0.012-1.105c0.309-0.304,0.803-0.293,1.105,0.012l5.306,5.433c0.304,0.31,0.296,0.805-0.012,1.105L7.83,15.928c-0.152,0.148-0.35,0.223-0.547,0.223c-0.203,0-0.406-0.08-0.559-0.236c-0.303-0.309-0.295-0.803,0.012-1.104L11.611,10.049z">
                          </path>
                        </svg>
                      </a>
                    </div>
                    <!-- <div class="loc-directions"><a
                      href='https://maps.google.com/maps?saddr={{encodedUri(address)}}&amp;daddr={{event.latitude}},{{event.longitude}}'
                      target="_blank">Directions</a></div> -->
                  </div>
                </div>
              </agm-info-window>
            </agm-marker>


          </agm-map>
        </div>
        <div class="no-result" *ngIf="events?.length == 0">
          <div class="bh-sl-noresults-title">No results</div><br>
          <div class="bh-sl-noresults-desc">
            No Event locations were found with the given criteria.
            Please modify your selections or input.
          </div>
        </div>
        <div class="found-results {{resultViewType === 'GridView' ? 'grid-results' : 'list-results'}}" id="{{event.id}}"
          (click)="openWindow(event.id)" *ngFor="let event of events;let i = index" #{{event.id}}>
          <!-- <pre>{{event | json}}</pre> -->
          <app-grid-view *ngIf="resultViewType === 'GridView'" title="{{event.sales_location}}"
            distance="{{event.distance}}" mapLink="{{event.event_id}}" price=""
            eventDate="{{event.event_start_datetime | date:'mediumDate'}}" eventImage="">
          </app-grid-view>
          <app-list-view id="{{event.id}}" *ngIf="resultViewType === 'ListView'" title="{{event.sales_location}}"
            distance="{{event.distance}}" mapLink="{{event.event_id}}" price=""
            eventDate="{{event.event_start_datetime | date:'mediumDate'}}" eventImage=""
            eventDetails="{{[event.city, event.prov_state, event.country, event.postal_code]}}">
          </app-list-view>
        </div>
      </div>
    </div>



    <!--1-->
    <div>ADVERTISEMENT</div>

    <div id="rowAdBanner" class="row">
      <a id="bannerLink"
        href="https://4ff43obio638tsj5o852ga3ked.hop.clickbank.net/?&traffic_source=YargarSite&traffic_type=paid&campaign=Aug2024&creative=c1&ad=Ag2SearchPage"
        onclick="void window.open(this.href); return false;">
        <img id="bannerImage" src="../assets/img/adverts/img/Ikaria.png" width="600" height="200"
          alt="lbjuice:  Ikaria Juice Ad">
      </a>
    </div>

    <!--2-->
    <div>ADVERTISEMENT</div>
    <div id="rowAdBanner" class="row">
      <a id="bannerLink"
        href="https://76866vppm657quibnbqgv6lnfh.hop.clickbank.net/?&traffic_source=YargarSite&traffic_type=paid&campaign=Aug2024&creative=c1&ad=Ag2SearchPage"
        onclick="void window.open(this.href); return false;">
        <img id="bannerImage" src="../assets/img/adverts/img/recprofit.png" width="600" height="200"
          alt="recprofit:  Recession Profit Secrets V2 Ad">
      </a>
    </div>

    <!--3-->
    <div>ADVERTISEMENT</div>
    <div id="rowAdBanner" class="row">
      <a id="bannerLink"
        href="https://3a751ulhw83huhrxx-sd-8plqf.hop.clickbank.net/?&traffic_source=YargarSite&traffic_type=paid&campaign=Jun2024&creative=c1&ad=Ag2SearchPage"
        onclick="void window.open(this.href); return false;">
        <img id="bannerImage" src="../assets/img/adverts/img/brainwclub.png" width="600" height="200"
          alt="brainwclub: Brainwave Ad">
      </a>
    </div>

    <!--4-->
    <div>ADVERTISEMENT</div>
    <div id="rowAdBanner" class="row">
      <a id="bannerLink"
        href="https://df633vhmnxwlwtgew3xzte3m8u.hop.clickbank.net/?&traffic_source=YargarSite&traffic_type=paid&campaign=Jun2024&creative=c1&ad=Ag2SearchPage"
        onclick="void window.open(this.href); return false;">
        <img d="bannerImage" src="../assets/img/adverts/img/buildacontainerhome.png" width="600" height="200"
          alt="imcoders: Build Container Home Ad">
      </a>
    </div>

    <!--5-->
    <div>ADVERTISEMENT</div>
    <div id="rowAdBanner" class="row">
      <a id="bannerLink"
        href="https://eb9c7ieci-xgumo1lmkhw6rfnp.hop.clickbank.net/?&traffic_source=YargarSite&traffic_type=paid&campaign=Aug2024&creative=c1&ad=Ag2SearchPage"
        onclick="void window.open(this.href); return false;">
        <img id="bannerImage" src="../assets/img/adverts/img/diyhomeene.png" width="600" height="200"
          alt="diyhomeene: DIY Home Energy Ad">
      </a>
    </div>

    <!--6-->
    <div>ADVERTISEMENT</div>
    <div id="rowAdBanner" class="row">
      <a id="bannerLink"
        href="https://447b8rmhv4uhygh22lj3x22ve4.hop.clickbank.net/?&traffic_source=YargarSite&traffic_type=Paid&campaign=Aug2024&creative=c1&ad=Ag2SearchPage"
        onclick="void window.open(this.href); return false;">
        <img id="bannerImage" src="../assets/img/adverts/img/homedoc.png" width="600" height="200"
          alt="homedoc: Home Doctor Ad">
      </a>
    </div>

    <!--7-->
    <div>ADVERTISEMENT</div>
    <div id="rowAdBanner" class="row">
      <a id="bannerLink"
        href="https://07811komo73bzjriqijxzlom17.hop.clickbank.net/?&traffic_source=YargarSite&traffic_type=paid&campaign=Aug2024&creative=c1&ad=Ag2SearchPage"
        onclick="void window.open(this.href); return false;">
        <img id="bannerImage" src="../assets/img/adverts/img/JavaBurn.png" width="600" height="200"
          alt="JavaBurn: JavaBurn Ad">
      </a>
    </div>


    <!--8-->
    <div>ADVERTISEMENT</div>
    <div id="rowAdBanner" class="row">
      <a id="bannerLink"
        href="https://84b15ldnv8vi2pobnn4i-39r0k.hop.clickbank.net/?&traffic_source=YargarSite&traffic_type=paid&campaign=Aug2024&creative=c1&ad=Ag2SearchPage"
        onclick="void window.open(this.href); return false;">
        <img d="bannerImage" src="../assets/img/adverts/img/LivPure.png" width="600" height="200"
          alt="livpure: Liv Pure Ad">
      </a>
    </div>


    <!--9-->
    <div>ADVERTISEMENT</div>
    <div id="rowAdBanner" class="row">
      <a id="bannerLink"
        href="https://43a28uddo6070hg9w3ygs5q0cd.hop.clickbank.net/?&traffic_source=YargarSite&traffic_type=Paid&campaign=Aug2024&creative=c1&ad=Ag2SearchPage"
        onclick="void window.open(this.href); return false;">
        <img id="bannerImage" src="../assets/img/adverts/img/mmarriage.png" width="600" height="200"
          alt="mmarriage: Mend the Marriage Ad">
      </a>
    </div>


    <!--10-->
    <div>ADVERTISEMENT</div>
    <div id="rowAdBanner" class="row">
      <a id="bannerLink"
        href="https://4bd92pjksx6h2ni8mhvao58oe9.hop.clickbank.net/?&traffic_source=YargarSite&traffic_type=paid&campaign=Aug2024&creative=c1&ad=Ag2SearchPage"
        onclick="void window.open(this.href); return false;">
        <img id="bannerImage" src="../assets/img/adverts/img/NaganoTonic.png" width="600" height="200"
          alt="lbtonic: Nagano tonic Ad">
      </a>
    </div>


    <!--11-->
    <div>ADVERTISEMENT</div>

    <div id="rowAdBanner" class="row">
      <a id="bannerLink"
        href="https://a6fe0lkcm6vhzsp3co8m3b3ztl.hop.clickbank.net/?&traffic_source=YargarSite&traffic_type=paid&campaign=Aug2024&creative=c1&ad=Ag2SearchPage"
        onclick="void window.open(this.href); return false;">
        <img id="bannerImage" src="../assets/img/adverts/img/easywriter.png" width="600" height="200"
          alt="easywriter: paidonlinewritingjobs.com Ad">
      </a>
    </div>


    <!--12-->
    <div>ADVERTISEMENT</div>

    <div id="rowAdBanner" class="row">
      <a id="bannerLink"
        href="https://57c90opiw84d0fjft3r5xdihjx.hop.clickbank.net/?&traffic_source=YargarSite&traffic_type=paid&campaign=Aug2024&creative=c1&ad=Ag2SearchPage"
        onclick="void window.open(this.href); return false;">
        <img id="bannerImage" src="../assets/img/adverts/img/socialpaid.png" width="600" height="200"
          alt="socialpaid: payingsocialmediajobs.com Ad">
      </a>
    </div>

    <!--13-->

    <div>ADVERTISEMENT</div>
    <div id="rowAdBanner" class="row">
      <a id="bannerLink"
        href="https://c9931rjjn6581rkirlw9mc4t92.hop.clickbank.net/?&traffic_source=YargarSite&traffic_type=paid&campaign=Jun2024&creative=c1&ad=Ag2SearchPage"
        onclick="void window.open(this.href); return false;">
        <img d="bannerImage" src="../assets/img/adverts/img/Septifix.png" width="600" height="200"
          alt="Septifix: Septifix Ad">
      </a>
    </div>



    <!--14-->

    <div>ADVERTISEMENT</div>

    <div id="rowAdBanner" class="row">
      <a id="bannerLink"
        href="https://3cc6fshkv9virjj6cod7ctarcq.hop.clickbank.net/?&traffic_source=yargarsite&traffic_type=paid&campaign=Aug2024&creative=c1&ad=Ag2SearchPage"
        onclick="void window.open(this.href); return false;">
        <img id="bannerImage" src="../assets/img/adverts/img/j1r2c.png" width="600" height="200"
          alt="j1r2c: John Crestani Autowebinar Ad">
      </a>
    </div>

    <!--15-->
    <div>ADVERTISEMENT</div>

    <div id="rowAdBanner" class="row">
      <a id="bannerLink"
        href="https://8982cmqqi3wipmomk01on41i16.hop.clickbank.net/?&traffic_source=YargarSite&traffic_type=paid&campaign=Jun2024&creative=c1&ad=Ag2SearchPage"
        onclick="void window.open(this.href); return false;">
        <img id="bannerImage" src="../assets/img/adverts/img/Tedswoodworking.png" width="600" height="200"
          alt="tedsplans: Tedswoodworking Ad">
      </a>
    </div>

    <!--16-->

    <div>ADVERTISEMENT</div>
    <div id="rowAdBanner" class="row">
      <a id="bannerLink"
        href="https://fc064uopk1ue3knomfq9jxyg0k.hop.clickbank.net/?&traffic_source=YargarSite&traffic_type=paid&campaign=Jun2024&creative=c1&ad=Ag2SearchPage"
        onclick="void window.open(this.href); return false;">
        <img id="bannerImage" src="../assets/img/adverts/img/VinCheckUp.png" width="600" height="200"
          alt="vincheckup: VIN Check UP Ad">
      </a>
    </div>

    <!--17-->

    <div>ADVERTISEMENT</div>
    <div id="rowAdBanner" class="row">
      <a id="bannerLink"
        href="https://fc9eainmu30kwftdyhs7omap3d.hop.clickbank.net/?&traffic_source=YargarSite&traffic_type=paid&campaign=Jun2024&creative=c1&ad=Ag2SearchPage"
        onclick="void window.open(this.href); return false;">
        <img id="bannerImage" src="../assets/img/adverts/img/vipindicators.png" width="600" height="200"
          alt="fxlife: VIP Indicators Ad">
      </a>
    </div>


    <!--18-->

    <div>ADVERTISEMENT</div>
    <div id="rowAdBanner" class="row">
      <a id="bannerLink"
        href="https://fa51apcdh85bzhirh9sow6svdj.hop.clickbank.net/?&traffic_source=YargarSite&traffic_type=paid&campaign=Aug2024&creative=c1&ad=Ag2SearchPage"
        onclick="void window.open(this.href); return false;">
        <img id="bannerImage" src="../assets/img/adverts/img/wealthdna.png" width="600" height="200"
          alt="wealthdna: Wealth DNA Code Ad">
      </a>
    </div>

    <!--19-->
    <div>ADVERTISEMENT</div>
    <div id="rowAdBanner" class="row">
      <a id="bannerLink"
        href="https://9c6cfrdnqv0kpjqsi5xovmv79n.hop.clickbank.net/?&traffic_source=YargarSite&traffic_type=paid&campaign=Aug2024&creative=c1&ad=Ag2SearchPage"
        onclick="void window.open(this.href); return false;">
        <img id="bannerImage" src="../assets/img/adverts/img/wealthsig.png" width="600" height="200"
          alt="wealthsig: Wealth Signal Ad">
      </a>
    </div>

    <!--20-->

    <div>ADVERTISEMENT</div>
    <div id="rowAdBanner" class="row">
      <a id="bannerLink"
        href="https://dfb86nejq6ygujgux4wcs8zetd.hop.clickbank.net/?&traffic_source=YargarSite&traffic_type=paid&campaign=Jun2024&creative=c1&ad=Ag2SearchPage"
        onclick="void window.open(this.href); return false;">
        <img id="bannerImage" src="../assets/img/adverts/img/whatliesin.png" width="600" height="200"
          alt="whatliesin: What Lies in your Debt">
      </a>
    </div>




    <!-- <div>ADVERTISEMENT</div>
    <div id="rowAdBanner"class="row">
	    <a id="bannerLink" href="https://16397rcfk2tjzfmdf7aufr4r4i.hop.clickbank.net" onclick="void window.open(this.href); return false;">
        <img id="bannerImage" src="../assets/img/adverts/img/DugiWorld.png" width="600" height="200" alt="Dugi World Ad">
      </a>
    </div> -->


  </div>


</div>