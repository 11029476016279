import {
  Component,
  OnInit,
  ChangeDetectorRef,
  TemplateRef
} from '@angular/core';
import { Router } from '@angular/router';

import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

import { AlertService, UserService, LookupService } from '../_services/index';
import {
  Customer,
  CurrentUser,
  User,
  Admin,
  UserRole,
  UserType,
  Country1,
  State1
  /*Country,
  Country1,
  State,
  State1,
  User,
  Address,
  Attendee,
  Tutor,
  UserRole*/
} from '../_models/index';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  moduleId: module.id.toString(),
  templateUrl: 'profile.component.html'
})
export class UserProfileComponent implements OnInit {
  model: User;
  currentUser: CurrentUser;
  user_detail: Customer | Admin;
  countries: Country1[];
  states: State1[];
  loading = false;
  userType = UserType;
  /*attendees: Attendee[];
  is_registrant_attendee = true;
  userRole = UserRole;

  countries: Country1[];
  states: State1[];
  loading = false;
  currentUser: CurrentUser;
  modalRef: BsModalRef;

  config = {
    animated: true,
    keyboard: true,
    backdrop: true,
    ignoreBackdropClick: true
  };

  asCustomer(val): Customer {
    return val;
  }*/

  constructor(
    private router: Router,
    private userService: UserService,
    private alertService: AlertService,
    private lookupService: LookupService,
    private cdRef: ChangeDetectorRef,
    private modalService: BsModalService
  ) {
    this.currentUser = userService.sessionUser;

    if (this.currentUser.user_type === UserType.Customer) {

      this.user_detail = {
        first_name: 'first name',
        last_name: 'last name',
        tel_no: '9999999',
        fax_no: '9999999',
        gender: 'M',
        reg_no: '6465646465',
        // initial: 'I',
        primary_address: {
          country: '',
          prov_state: '',
          address1: '',
          address2: '',
          city: '',
          postal_code: ''
        },
        billing_address: null,
        is_billing_address_same: false,
      } as Customer;
    } else if (this.currentUser.user_type === UserType.Admin) {

      this.user_detail = {
        first_name: 'first name',
        last_name: 'last name',
        tel_no: '9999999',
        fax_no: '9999999',
        gender: 'M',
        reg_no: '6465646465',
      } as Admin;
    }

    this.model = {
      email: '',
      password: '',
      reset_token: '',
      user_type: UserType.Customer || UserType.Admin,
      user_details: {
        customer: this.user_detail as Customer,
        admin: this.user_detail as Admin
      },
      kindOfSale: '',
      opt1RegEmailLetters: false,
      opt2NotifyOfSale: false
    };

    this.countries = [];
    this.states = [];
    /*this.is_registrant_attendee = true;

    const defaultAttendee: Attendee = {
      id: 0,
      first_name: '',
      last_name: '',
      age: 0
    };*/

    this.lookupService.getCountries1().subscribe(data => {
      this.countries = data;
    });

    this.lookupService.getStates1().subscribe(data => {
      this.states = data;
    });
  }

  ngOnInit() {
    this.userService.getUserProfile(this.currentUser.email).subscribe(data => {
      this.model = data;

      if (this.model.user_type === UserType.Customer) {
        this.user_detail = this.model.user_details.customer;
      } else if (this.model.user_type === UserType.Admin) {
        this.user_detail = this.model.user_details.admin;
      }
    });
  }

  updateProfile() {
    this.loading = true;

    /*if (this.model.user_type === UserType.Customer) {
      this.model.customer = <Customer>this.user_detail;
      this.model.customer.is_attendee = this.is_registrant_attendee;
      this.model.customer.attendees = this.attendees;
    } else if (this.model.user_type === UserRole.TutorRole) {
      this.model.tutor = <Tutor>this.user_detail;
    }*/
    this.userService.updateUserProfile(this.model).subscribe(
      data => {
        this.alertService.success('Profile updated successfully', true);
        // this.router.navigate(['/customers']);
        this.loading = false;
      },
      (error: HttpErrorResponse) => {
        console.log(`Error: ${error}`);
        this.alertService.error(error.error.message);
        this.loading = false;
      }
    );
  }
/*
  viewTermOfUse(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      template,
      Object.assign({}, this.config, { class: 'gray modal-lg' })
    );
  }*/
}
