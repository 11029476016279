import { Component, OnInit, TemplateRef } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { table } from 'console';
import { delay, filter } from 'rxjs/operators';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { HttpErrorResponse } from '@angular/common/http';
import { EventSummary, Item, Survey, SurveyStat } from 'src/app/_models';
import { AlertService, EventService, UserService, AnalyticService } from '../_services';
import { CurrentUser } from '../_models/index';
import { Meta, MetaDefinition, Title } from '@angular/platform-browser';
import { of } from 'rxjs';

@Component({
  selector: 'app-event-detail',
  templateUrl: './event-detail.component.html',
  styleUrls: ['./event-detail.component.scss']
})
export class EventDetailComponent implements OnInit {
  summary: EventSummary;
  modalRef: BsModalRef;
  survey: Survey;
  currentUser: CurrentUser;
  eventIdentifier: string;
  ownevent: boolean;
  surveyStat: SurveyStat;
  public filterValue:string = "all";

  get itemKind() {
    if (this.filterValue === 'giveaway')
      return true;
    else if (this.filterValue === 'sale')
      return false;
    else
      return null;
  }

  config = {
    animated: true,
    keyboard: true,
    backdrop: true,
    ignoreBackdropClick: true
  };
  mapUrl = "";
  tooltipText = "Copy";
  constructor(private eventService: EventService
    , private alertService: AlertService
    , private router: ActivatedRoute
    , private modalService: BsModalService
    , private userService: UserService
    , private analyticService: AnalyticService
    , private metaTagService: Meta
    , private titleService: Title
    , private clipboard: Clipboard) {
    this.currentUser = userService.sessionUser
  }

  get title(): string{
    return `Yard Sale Event - ${this.summary.location.sales_location}`;
  }

  ngOnInit(): void {
    this.eventIdentifier = this.router.snapshot.params.eventIndentifier;
    this.survey = {
      eventid : this.eventIdentifier,
      visit : false,
      interested : false,
      visit_and_buy : false,
      take_give_aways : false
    };
    var self = this;
    this.eventService.getEvent(this.eventIdentifier)
      .subscribe((data) => {

        if (data) {

          var currentUserEmail = (self.currentUser && self.currentUser.email) || "";
          var eventuserid = (data.location && data.location.customer_id) || "";
          if (currentUserEmail && currentUserEmail.toLowerCase() == eventuserid.toLowerCase())
          {
            this.ownevent = true;
          }

          this.summary = data;
          //this.model = data;
          this.summary.eventItems.map(function (item) {
            item.items.map(function (i) {
              var images = [];
              i.pictures.map(function (p) {
                p = p.replace('+', '%2B');
                images.push(p);
              })
              i.pictures = images;
              console.log(`Pictures: ${i.pictures}`);
            });
          });

          this.analyticService.eventEmitter("eventdetail", "get", this.eventIdentifier);


          //set title and meta tags.
          this.titleService.setTitle(this.summary.metaData.title);
          this.metaTagService.addTags([
            {name: 'description', content: this.summary.metaData.metaDetails},
            {name: 'keywords', content: this.summary.metaData.metaDetails}
          ]);

          //Get Map URL
          if(data.location && data.location.google_data){
            if(data.location.google_data.origin_geometry?.place_id != undefined && data.location.google_data.destination_geometry?.place_id != undefined){
              //Direction map URL
              this.mapUrl = `https://maps.google.com/maps/dir/${data.location.google_data.origin_geometry.formatted_address}/${data.location.google_data.destination_geometry.formatted_address}`;
            }
            else{
              //Place map URL
              this.mapUrl = data.location.google_data.origin_geometry.url || `https://maps.google.com/maps/place/${data.location.google_data.origin_geometry.formatted_address}`;
            }
          }

        }
      });
  }

  onFilterTabChange(tabValue){
    console.log(`${tabValue} items`);
    this.filterValue = tabValue;
    console.log(`is_for_giveaway = ${this.itemKind}`);
  }

  viewSurvey(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      template,
      Object.assign({}, this.config, { class: 'gray modal-lg' }));
  }

  viewSurveyResult(template: TemplateRef<any>) {

    this.eventService.getSurveyStat(this.eventIdentifier)
      .subscribe((data) => {
        if (data) {
          this.surveyStat = data;
        }
        else
        {
          this.surveyStat = {
            eventid: this.eventIdentifier,
            visit: 0,
            interested: 0,
            visit_and_buy: 0,
            take_give_aways: 0 }
        }
        this.modalRef = this.modalService.show(
          template,
          Object.assign({}, this.config, { class: 'gray modal-lg' }));
      });
  }

  submitSurvey(): void {
    this.survey.eventid = this.eventIdentifier;
    this.eventService.submitEventSurvey(this.survey)
      .then(
        (data) => {
          this.alertService.success("Thank you", true);
        },
        (error: HttpErrorResponse) => {
          console.log(`Error: ${error}`);
          this.alertService.error(error.error.message);
        }
      );
    this.modalRef.hide();
  }

  decline(): void {
    this.modalRef.hide();
  }

  copyToClipboard(btnCopy): void {
    console.log(this.mapUrl);
    const successful = this.clipboard.copy(this.mapUrl);
    const o = of([]);
    const o2 = o.pipe(
      delay(1500)
    );
    if(successful){
      this.tooltipText = "Copied!";
      btnCopy.firstChild.setAttribute("class","fa fa-check");
      o2.subscribe(r =>{
        this.tooltipText = "Copy"; 
        btnCopy.firstChild.setAttribute("class","fa fa-copy");
      });
    }
  }

}
