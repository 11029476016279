<div class="animated fadeIn infoBox col-8 mx-auto">
    <div class="row">        <div class="col-lg-12 customLG">
            <div class="card">
                <div class="card-header">
                    <b>Need help setting up a Yard, Garage or Moving Sale ?</b>
                </div>
                <div class="card-body">
                    <form name="form" (ngSubmit)="f.form.valid && sendAssistSetUpReqeust()" #f="ngForm" novalidate>
                        <h4> Fill out this quick form and submit it, someone will contact you and help you set up your
                            event.</h4>

                        <!-- <h5> LET US GET STARTED!!!</h5> -->

                        <p></p>
                        <p></p>

                        <h5> How do we contact you?</h5>

                        <div class="row">
                            <div class="form-group col-sm-4">
                                <label class="col-form-label" for="firstName">Contact Name</label>
                                <strong class="text-danger">*</strong>
                                <div class="controls">
                                    <div class="input-prepend input-group"
                                        [ngClass]="{ 'has-error': f.submitted && !firstName.valid }">
                                        <input placeholder="Contact Name" type="text" class="form-control"
                                            name="firstName" [(ngModel)]="model.firstName" #firstName="ngModel"
                                            required />
                                    </div>
                                    <div *ngIf="f.submitted && !firstName.valid" class="has-error">
                                        <span class="help-block">Name is required</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-sm-4">
                                <label class="col-form-label" for="phoneNumber">Phone Number</label>
                                <strong class="text-danger">*</strong>
                                <div class="controls">
                                    <div class="input-group"
                                        [ngClass]="{ 'has-error': f.submitted && !phoneNumber.valid }">
                                        <input placeholder="Phone Number" type="text" class="form-control"
                                            name="phoneNumber" [(ngModel)]="model.phoneNumber" #phoneNumber="ngModel"
                                            required />
                                    </div>
                                    <div *ngIf="f.submitted && !phoneNumber.valid" class="has-error">
                                        <span class="help-block">Phone Number is required</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="form-group col-sm-8">
                                <label class="col-form-label" for="firstName">Email Address</label>
                                <strong class="text-danger">*</strong>
                                <div class="controls">
                                    <div class="input-prepend input-group"
                                        [ngClass]="{ 'has-error': f.submitted && !email.valid }">
                                        <input placeholder="Email" type="text" class="form-control" name="email"
                                            [(ngModel)]="model.email" #email="ngModel" required email />
                                    </div>
                                    <div *ngIf="f.submitted && !email.valid" class="has-error">
                                        <span *ngIf="email?.errors?.required" class="help-block">Email address is
                                            required.</span>
<!--                                         <span *ngIf="!email?.errors?.required && email?.errors?.email"
                                            class="help-block">Invalid email
                                            address</span> -->
                                    </div>
                                </div>
                            </div>
                        </div>



                        <h5> Where are you having your sale?</h5>

                        <div class="row">
                            <div class="form-group col-sm-8">
                                <label class="col-form-label" for="address">Address</label>
                                <div class="controls">
                                    <div class="input-prepend input-group">
                                        <textarea name="Address" class="form-control" style="width:500px; height:50px;"
                                            [(ngModel)]="model.address" #address="ngModel"></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="form-group col-sm-8">
                                <label class="col-form-label" for="address1">Address additional</label>
                                <div class="controls">
                                    <div class="input-prepend input-group">
                                        <textarea name="Address1" class="form-control" style="width:500px; height:50px;"
                                            [(ngModel)]="model.address1" #address1="ngModel"></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="form-group col-sm-4">
                                <label class="col-form-label" for="city">City</label>
                                <div class="controls">
                                    <div class="input-prepend input-group">

                                        <input placeholder="City" type="text" class="form-control" name="email"
                                        [(ngModel)]="model.city" #city="ngModel" City />

                                        <!-- <textarea name="City" class="form-control"
                                            [(ngModel)]="model.city" #city="ngModel"></textarea> -->
                                    </div>
                                </div>
                            </div>

                            <div class="form-group col-sm-4">
                                <label class="col-form-label" for="StateProv">State Or Province</label>
                                <div class="controls">
                                    <div class="input-prepend input-group">
                                        
                                        <input placeholder="StateProv" type="text" class="form-control" name="stateprov"
                                        [(ngModel)]="model.stateprov" #stateprov="ngModel" State Or Province />

                                        <!-- <textarea name="StateProv" class="form-control"
                                            style="width:100px; height:25px;" [(ngModel)]="model.stateprov"
                                            #stateprov="ngModel"></textarea> -->
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="form-group col-sm-4">
                                <label class="col-form-label" for="country">Country</label>
                                <div class="controls">
                                    <div class="input-prepend input-group">
                                        <input placeholder="Country" type="text" class="form-control" name="country"
                                        [(ngModel)]="model.country" #country="ngModel" Country />

                                        <!-- <textarea name="Country" class="form-control" style="width:100px; height:50px;"
                                            [(ngModel)]="model.country" #country="ngModel"></textarea> -->
                                    </div>
                                </div>
                            </div>

                            <div class="form-group col-sm-4">
                                <label class="col-form-label" for="code">Zip/Postal Code</label>
                                <div class="controls">
                                    <div class="input-prepend input-group">

                                        <input placeholder="Zip/Postal Code" type="text" class="form-control" name="code"
                                        [(ngModel)]="model.code" #code="ngModel" Code />

                                        <!-- <textarea name="code" class="form-control" style="width:50px; height:50px;;"
                                            [(ngModel)]="model.code" #stateprov="ngModel"></textarea> -->
                                    </div>
                                </div>
                            </div>
                        </div>


                        <h5> Date And Time of your sale ?</h5>


                        <div class="row">
                            <div class="form-group col-sm-4">
                                <label class="col-form-label" for="date">Date</label>
                                <div class="controls">
                                    <div class="input-prepend input-group">
                                        <!-- <textarea name="Date" class="form-control" style="width:50px; height:50px;"
                                            [(ngModel)]="model.date" #date="ngModel"></textarea> -->
                                            <input placeholder="Date" type="text" class="form-control" name="date"
                                            [(ngModel)]="model.date" #code="ngModel" Date />

                                    </div>
                                </div>
                            </div>

                            <div class="form-group col-sm-4">
                                <label class="col-form-label" for="time">Time</label>
                                <div class="controls">
                                    <div class="input-prepend input-group">
                                        <input placeholder="Time" type="text" class="form-control" name="time"
                                        [(ngModel)]="model.time" #time="ngModel" Time />
                                        <!-- <textarea name="Time" class="form-control" style="width:50px; height:50px;"
                                            [(ngModel)]="model.time" #time="ngModel"></textarea> -->
                                    </div>
                                </div>
                            </div>
                        </div>

                        <h5> Additional Notes</h5>

                        <div class="row">
                            <div class="form-group col-sm-8">
                                <label class="col-form-label" for="comments">Comments</label>
                                <div class="controls">
                                    <div class="input-prepend input-group">
                                        <textarea name="Comments" class="form-control"
                                            style="width:500px; height:200px;" [(ngModel)]="model.comments"
                                            #comments="ngModel"></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>




                        <div class="form-actions">
                            <button [disabled]="loading" class="btn btn-primary mr-2">Send</button>
                            <button class="btn btn-secondary" type="reset">Reset</button>
                        </div>
                        <div class="row">
                            <div class="form-group col-sm-8">
                                <label> <br /><br />Also feel free to send additional inquiries, questions or comments
                                    to <a
                                        href="mailto:info@YardGarageMovingSales.com?subject=Yard, Garage And Moving Sale Inquiry">info@YardGarageMovingSales.com</a>
                                </label>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>