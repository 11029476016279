import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Country, Country1 } from '../_models/country';
import { State, State1 } from '../_models/state';
import { Observable } from 'rxjs';
import { Category, SubCategory } from '../_models';

@Injectable()
export class LookupService {

  constructor(private http: HttpClient) { }

  getCountries1(): Observable<Country1[]> {
    return this.http.get<Country1[]>('/config/lookup/lkpCountry');
      // .map((response: Response) => {
      //   return <Country1[]>response.json();
      //     // .map((item: any) => {
      //     //  // alert(item);
      //     //   return {
      //     //     id: item.id,
      //     //     name: item.name
      //     //   };
      //     // });
      // });
    // .map(response => <Country1[]>response.json());
  }

  getStates1(): Observable<State1[]> {
    return this.http.get<State1[]>('/config/lookup/lkpState');
      // .map((response: Response) => {
      //   return <State1[]>response.json();
      //     // .map((item: any) => {
      //     //  // alert(item);
      //     //   return {
      //     //     id: item.id,
      //     //     name: item.name
      //     //   };
      //     // });
      // });
    // .map(response => <Country1[]>response.json());
  }

  getCountries(): Observable<Country[]> {
    // return this.http.get('/config/lookups/lkpCountry')
    //   .map(response => <Country[]>response.json());
      // .map((response: Response) => response.json());
      return Observable.of([
          new Country(1, 'CA'),
          new Country(2, 'US'),
          new Country(3, 'INDIA')
      ]).map(response => response as Country[]);
  }

  getStates(countryId = 0): Observable<State[]> {
      // return this.http.get(`/lookups/states/${countryId}`).map(response => <State[]>response.json());
    return Observable.of([
      new State(1, 1, 'Alberta'),
      new State(2, 1, 'Labrador'),
      new State(3, 1, 'New Brunswick'),
      new State(4, 1, 'Nova Scotia'),
      new State(5, 1, 'North West Terr.'),
      new State(6, 1, 'Prince Edward Is.'),
      new State(7, 1, 'Saskatchewen'),
      new State(8, 1, 'British Columbia'),
      new State(9, 1, 'Manitoba'),
      new State(10, 1, 'Newfoundland'),
      new State(11, 1, 'Nunavut'),
      new State(12, 1, 'Ontario'),
      new State(13, 1, 'Quebec'),
      new State(14, 1, 'Yukon'),
      new State(15, 2, 'Alabama'),
      new State(16, 2, 'Arkansas'),
      new State(17, 2, 'Connecticut'),
      new State(18, 2, 'Florida'),
      new State(19, 2, 'Illinois'),
      new State(20, 2, 'Maryland'),
      new State(21, 2, 'Indiana'),
      new State(22, 2, 'New York'),
      new State(23, 3, 'Gujarat'),
      new State(24, 3, 'Maharastra'),
      new State(25, 3, 'Karnataka'),
      new State(26, 3, 'Punjab')
    ]).map(response => response as State[]);


  }

  getCategories(): Observable<Category[]> {
    return this.http.get<Category[]>('/config/lookup/lkpCategory');
  }

  getSubCategories(): Observable<SubCategory[]> {
    return this.http.get<SubCategory[]>('/config/lookup/lkpSubCategory');
  }

}
