import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EventItems, Item } from 'src/app/_models';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss']
})
export class ProductListComponent implements OnInit {

  @Input() productList: EventItems[]
  @Input() readOnly: boolean;
  @Input() displayReviewStatus: boolean;
  @Output() onProductSelected: EventEmitter<Item>
  private currentProduct: Item
  constructor() { 
    this.onProductSelected = new EventEmitter()
  }

  ngOnInit(): void {
  }

  clicked(product: Item): void {
    this.currentProduct = product
    this.onProductSelected.emit(product)
  }

  isSelected(product: Item): boolean {
    if (!product || !this.currentProduct) {
      return false
    }
    return true;
  }

  get productCount()
  {
    return this.productList?.map(eventItem => eventItem.items).concat().length;
  }
}
