<div class="flex-row align-items-center">
    <div class="container">
      <div class="row justify-content-center resetPasswordBox">
        <div class="col-md-6">
          <div class="card-group mb-0">
            <div class="card p-4">
              <div class="card-body">

                <h1>Reset Password</h1>
                <form name="form" (ngSubmit)="f.form.valid && fpassword()" #f="ngForm" novalidate>
                  <!-- <p class="text-muted">Provide your registered email address to send password reset request.</p> -->
                  <div class="input-group mt-1">
                    <span class="input-group-addon">
                      <i class="icon-envelope"></i>
                    </span>
                    <input type="text" readonly class="form-control" placeholder="Email" name="email" [(ngModel)]="model.email" />
                  </div>
                  <!-- <div *ngIf="f.submitted && !username.valid" class="has-error">
                    <span class="help-block">Email is required</span>
                  </div> -->
                  <div class="input-group mt-1" [ngClass]="{ 'has-error': f.submitted && !password.valid }">
                    <span class="input-group-addon">
                      <i class="icon-lock"></i>
                    </span>
                    <input type="password" class="form-control" name="password" placeholder="Password" [(ngModel)]="model.password" #password="ngModel"
                      required />

                  </div>
                  <div *ngIf="f.submitted && !password.valid" class="has-error">
                    <span class="help-block">Password is required</span>
                  </div>

                  <div class="input-group mt-1" [ngClass]="{ 'has-error': f.submitted && !confirmPassword.valid }">
                    <span class="input-group-addon">
                      <i class="icon-lock"></i>
                    </span>
                    <input type="password" placeholder="Confirm Password" class="form-control" ngModel name="confirmPassword" #confirmPassword="ngModel"
                      [equalTo]="password" />
                  </div>
                  <div *ngIf="f.submitted && !confirmPassword.valid" class="has-error">
                    <span class="help-block">Confirm password must be matched with password</span>
                  </div>
                  <div class="form-group mt-3">
                    <button [disabled]="loading" class="btn btn-primary">Reset Password</button>
                    <a [routerLink]="IsAuthenticated ? ['/home']:['/login']" class="btn btn-link withoutBg">Cancel</a>
                  </div>
                 
                </form>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
