<div class="animated fadeIn infoBox col-8 mx-auto">
    <div class="row">
        <div class="col-lg-12 customLG">
            <div class="card">
                <div class="card-header">
                    <!-- <i class="fa fa-info"></i> -->
                    <header id="Heading1">
                        <h1>Benefits and Use </h1>
                    </header>
                </div>
                <div class="card-body text-justify">

                    <header id="Heading2">
                        <h2>Benefits of Organizing a Yard, Moving or Garage Sale </h2>
                    </header>


                    <p>
                        A yard sale, also known as a garage sale or tag sale, can offer several benefits for both the
                        seller and the
                        buyer.
                        Here are some of the benefits of having a yard sale. A moving sale is sale you are having as
                        part of you moving
                        to a new location.
                    </p>
                    <header id="Heading4">
                        <h4>1. Decluttering</h4>
                    </header>
                    <p>
                        A yard sale is an excellent opportunity to get rid of items that you no longer need or use.
                        By selling them, you can free up space in your home and reduce clutter.
                    </p>
                    <header id="Heading4">
                        <h4>2. Making money</h4>
                    </header>
                    <p>
                        Holding a yard sale is a great way to make some extra money by selling items that you no longer
                        need.
                        You can use the money you make to pay bills, save for a vacation, or buy something you've been
                        wanting.
                    </p>
                    <header id="Heading4">
                        <h4>3. Recycling and reducing waste</h4>
                    </header>
                    <p>
                        By selling items you no longer need, you are recycling and reducing waste.
                        Instead of throwing them away, someone else can make use of them. If you are moving, by selling
                        your unwanted
                        items,
                        you are helping to reduce waste and minimize the environmental impact of your move.
                        Rather than throwing items away, you are giving them a new life with someone who will use and
                        appreciate them.
                    </p>
                    <header id="Heading4">
                        <h4>4. Meeting new people</h4>
                    </header>
                    <p>
                        Yard sales are an excellent way to meet new people in your community.
                        You may meet neighbors you have never met before, and you can share stories about the items
                        you're selling.
                        Supporting the community: Yard sales are often community events, with many people participating
                        in the sale.
                        By participating in a yard sale, you can support your community and help to build a sense of
                        togetherness.
                    </p>
                    <header id="Heading4">
                        <h4>5. Bargain hunting</h4>
                    </header>

                    <p>
                        Buyers can find great deals at yard sales on items they need or want.
                        You may be able to find gently used items for a fraction of the price you would pay in a store.
                    </p>

                    <header id="Heading4">
                        <h4>6. Reduce moving cost and headaches</h4>
                    </header>

                    <p>
                        If you are planning on moving, reducing the number of items to move,
                        by selling some of your belongings before your move, you can lighten your load and reduce the
                        amount of items
                        you need to pack and transport.
                        This can save you money on moving expenses, as well as make the moving process less stressful.
                    </p>

                    <p>
                        <a [routerLink]='"."' [fragment]='"EasySteps"'>Let us help you organize a memorable event, give
                            it a try by clicking here and following
                            these steps</a>
                    </p>

                    <header id="Heading2">
                        <h2>What are the benefits of using this Site ? How does our site help? </h2>
                    </header>

                    <header id="Heading4">
                        <h4>Publicity at no cost</h4>
                    </header>
                    <p>
                        Our site provides you with the ability and opportunity to market and advertise your sale
                        event
                        to all those in your neighbourhood with little effort and no cost.
                        Your sale event and items for sale will be targeted to the customers who want your items.
                        No need for flyers, posters, etc.
                    </p>
                    <header id="Heading4">
                        <h4>Moving sale helper</h4>
                    </header>
                    <p>
                        You are moving, and plan to get rid of some of your belongings,
                        why not schedule a moving sale and use this site to help others who might be interested in some
                        of your items.
                        Use our site to publicize your event within a short period of time.
                    </p>
                    <header id="Heading4">
                        <h4>Avoid single / double item listings repetition</h4>
                    </header>
                    <p>
                        Avoid the hassle of selling single items at different dates and
                        times by having a one or two event sale.
                        This site enables you to plan and use your time more effectively. Invite all prospective buyers
                        to your sale
                        event.
                    </p>
                    <header id="Heading4">
                        <h4>Scheduling and timing</h4>
                    </header>
                    <p>
                        Searching our site, you could plan and schedule a sales event around the same period
                        as other scheduled sales events in your neighbourhood
                        exposing your event to a larger audience. You eliminate the guess work.
                    </p>

                    <header id="Heading4">
                        <h4>Decluttering and then what?</h4>
                    </header>
                    <p>
                        As you declutter, take away the headache of disposal and make some cash while the
                        items you no longer need are
                        picked up by those who find a need for them. With our site, you can specify which items are free
                        and which are
                        not.
                    </p>

                    <header id="Heading4">
                        <h4>Time savings</h4>
                    </header>
                    <p>
                        Potential buyers can view your items and provide some feedback ahead of your sale event.
                    </p>
                    <header id="Heading4">
                        <h4>Environmentally friendly</h4>
                    </header>
                    <p>
                        Finally, be a friend to the environment by keeping items away from the landfill a bit
                        longer by encouraging “REUSE”
                    </p>

                    <header id="Heading4">
                        <h4 id="EasySteps"> Go through these 5 easy steps from registration to broadcast.</h4>
                    </header>
                    <p>
                        1. Register; enrol by clicking on the register button and creating an account using your email
                        address as your
                        user
                        name.
                        <button type="button" tooltip="Click here to Register"
                            class="btn button button-black btn-primary mt-3" [routerLink]="['/register']">Click here to
                            Register</button>
                    </p>
                    <p>
                        2. Create a yard, garage or moving sale event; simply provide the location (street
                        intersection), city and date
                        of the sale event
                        event.
                    </p>
                    <p>
                        3. Enter a list or category of items you have on sale and upload pictures if possible.
                    </p>
                    <p>
                        4. Broadcast the event.
                    </p>
                    <p>
                        5. After a review and approval process, your event is broadcasted through an extensive network
                        of marketing
                        outlets.
                    </p>
                    <p>
                        To search for sale events.
                    </p>
                    <p>
                        <button type="button" tooltip="Click here to Search for a Sale Event"
                            class="btn button button-black btn-primary mt-3" [routerLink]="['/search-sale']">Click here
                            to
                            Search</button>
                    </p>
                    <p>
                        Try it today!!!!.
                    </p>
                    <br/>
                    <br/>
                    <br/>
                </div>
            </div>
        </div>
    </div>
</div>