import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-grid-view',
  templateUrl: './grid-view.component.html',
  styleUrls: ['./grid-view.component.scss']
})
export class GridViewComponent implements OnInit {
  @Input() get title(): string { return this._title; }
  @Input() get distance(): string { return this._distance;}
  @Input() get mapLink(): string { return this._mapLink;}
  @Input() get price(): string { return this._price;}
  @Input() get eventDate(): string { return this._eventDate;}
  @Input() get eventImage(): string { return this._eventImage;}


  set title(title: string) {
    this._title = (title && title.trim()) || '';
  }
  set distance(distance: string) {
    this._distance = (distance && distance) || '';
  }
  set mapLink(mapLink: string) {
    this._mapLink = '/event-detail/'+ (mapLink && mapLink) || '';
  }
  set price(price: string) {
    this._price = (price && price) || '0';
  }
  set eventDate(eventDate: string) {
    console.log(`Event Date: ${eventDate}`);
    this._eventDate = (eventDate && eventDate) || '0';
  }
  set eventImage(eventImage: string) {
    this._eventImage = eventImage ? eventImage : '../../../assets/img/no-image.png';
  }

  public _title = '';
  public _distance = '';
  public _mapLink = '';
  public _price = '';
  public _eventDate = '';  
  public _eventImage = '';

  constructor() { }

  ngOnInit(): void {
  }

}
