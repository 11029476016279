<style>
  .lable {
    width: 30%;
    text-align: right;
  }
</style>
<div class="animated fadeIn infoBox col-8 mx-auto">
  <div class="row">
    <div class="col-lg-12 customLG">
      <div class="card">
        <div class="card-header">
          <i class="fa fa-edit"></i>My Profile
        </div>
        <div class="card-body">
          <form class="form-horizontal" name="form" (ngSubmit)="f.form.valid && updateProfile()" #f="ngForm" novalidate>
            <!-- <pre>Model: {{model | json}}</pre> -->
            <div class="row">
              <div class="col-lg-12">
                <div *ngIf="model.user_type !== userType.Admin" class="row">
                  <div class="form-group col-sm-8">
                    Registration No: <strong>{{user_detail.reg_no}}</strong>
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-sm-4">
                    <label class="col-form-label" for="firstName">First Name</label>
                    <strong class="text-danger">*</strong>
                    <div class="controls" [ngClass]="{ 'has-error': f.submitted && !firstName.valid }">
                      <div class="input-prepend input-group">
                        <input placeholder="First Name" type="text" class="form-control" name="firstName"
                          (ngModelChange)="user_detail.first_name=$event" [(ngModel)]="user_detail.first_name"
                          #firstName="ngModel" required />
                      </div>
                      <div *ngIf="f.submitted && !firstName.valid" class="has-error">
                        <span class="help-block">First Name is required</span>
                      </div>
                    </div>
                  </div>
                  <div class="form-group col-sm-4">
                    <label class="col-form-label" for="lastName">Last Name</label>
                    <strong class="text-danger">*</strong>
                    <div class="controls" [ngClass]="{ 'has-error': f.submitted && !lastName.valid }">
                      <div class="input-group">
                        <input placeholder="Last Name" type="text" class="form-control" name="lastName"
                          (ngModelChange)="user_detail.last_name=$event" [(ngModel)]="user_detail.last_name"
                          #lastName="ngModel" required />
                      </div>
                      <div *ngIf="f.submitted && !lastName.valid" class="has-error">
                        <span class="help-block">Last Name is required</span>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="model.user_type !== userType.Admin" class="form-group col-sm-4">
                    <label class="col-form-label" for="gender">Gender</label>
                    <div class="controls" [ngClass]="{ 'has-error': f.submitted && !gender.valid }">
                      <div class="input-group">
                        <select name="gender" class="form-control" [(ngModel)]="user_detail.gender" #gender="ngModel">
                          <option value="">--Select Gender--</option>
                          <option value="M">Male</option>
                          <option value="F">Female</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div *ngIf="model.user_type !== userType.Admin" class="row">
                  <div class="form-group col-sm-6">
                    <label class="col-form-label" for="address1">Address</label>
                    <div class="controls" [ngClass]="{ 'has-error': f.submitted && !address1.valid }">
                      <div class="input-prepend input-group">
                        <input type="text" placeholder="Address line 1" class="form-control" name="address1"
                          [(ngModel)]="user_detail.primary_address.address1" #address1="ngModel" />
                      </div>

                    </div>
                  </div>
                  <div class="form-group col-sm-6">
                    <label class="col-form-label" for="lastName">&nbsp;</label>
                    <div class="controls">
                      <div class="input-group">
                        <input type="text" placeholder="Address line 2" class="form-control" name="address2"
                          [(ngModel)]="user_detail.primary_address.address2" #address2="ngModel" />
                      </div>
                    </div>
                  </div>

                </div>
                <div *ngIf="model.user_type !== userType.Admin" class="row">
                  <div class="form-group col-sm-5">
                    <label class="col-form-label" for="telephone">Mobile Number</label>
                    <div class="controls" [ngClass]="{ 'has-error': f.submitted && !telephone.valid }">
                      <div class="input-group">

                        <p-inputMask class="p-input-form-control" styleClass="form-control" mask="(999) 999-9999"
                          unmask="true" name="telephone" [(ngModel)]="user_detail.tel_no" #telephone="ngModel"
                          placeholder="(999) 999-9999"></p-inputMask>
                      </div>
                      <div *ngIf="f.submitted && !telephone.valid" class="has-error">
                        <span class="help-block">Mobile number should be valid</span>
                      </div>
                    </div>
                  </div>
                  <div class="form-group col-sm-3">
                    <label class="col-form-label" for="country">Country</label>
                    <strong class="text-danger">*</strong>
                    <div class="controls"
                      [ngClass]="{ 'has-error': f.submitted && user_detail.primary_address.country == '' }">
                      <div class="input-prepend input-group">
                        <select name="country" class="form-control" [(ngModel)]="user_detail.primary_address.country">
                          <option value="">--Select Country--</option>
                          <option *ngFor="let country of countries" value="{{country.country_name}}">
                            {{country.country_name}}</option>
                        </select>
                      </div>
                      <div *ngIf="f.submitted && user_detail.primary_address.country == ''" class="has-error">
                        <span class="help-block">Country is required</span>
                      </div>
                    </div>
                  </div>
                  <div class="form-group col-sm-4">
                    <label class="col-form-label" for="state">{{ user_detail.primary_address.country == 'CA' ?
                      "Province" : "State" }}</label>
                    <strong class="text-danger">*</strong>
                    <div class="controls"
                      [ngClass]="{ 'has-error': f.submitted && user_detail.primary_address.prov_state == '' }">
                      <div class="input-group">
                        <select name="prov_state" class="form-control"
                          [(ngModel)]="user_detail.primary_address.prov_state">
                          <option value="">--Select {{ user_detail.primary_address.country == 'CA' ? "Province" :
                            "State" }}--</option>
                          <option
                            *ngFor="let state of states | KeyFilter: 'country_name' : user_detail.primary_address.country"
                            value="{{state.prov_state_code}}">{{state.prov_state}}
                          </option>
                        </select>
                      </div>
                      <div *ngIf="f.submitted && user_detail.primary_address.prov_state == ''" class="has-error">
                        <span class="help-block">{{ user_detail.primary_address.country == 'CA' ? "Province" : "State"
                          }} is required</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div *ngIf="model.user_type !== userType.Admin" class="row">
                  <div class="form-group col-sm-4">
                    <label class="col-form-label" for="city">City</label>
                    <strong class="text-danger">*</strong>
                    <div class="controls" [ngClass]="{ 'has-error': f.submitted && !city.valid }">
                      <div class="input-group">
                        <input type="text" placeholder="City" class="form-control" name="city"
                          [(ngModel)]="user_detail.primary_address.city" #city="ngModel" required />
                      </div>
                      <div *ngIf="f.submitted && !city.valid" class="has-error">
                        <span class="help-block">City is required</span>
                      </div>
                    </div>
                  </div>
                  <div class="form-group col-sm-4">
                    <label class="col-form-label" for="postalCode">{{ user_detail.primary_address.country == 'CA' ?
                      "Postal Code" : "Zip code" }}</label>
                    <div class="controls" [ngClass]="{ 'has-error': f.submitted && !postalCode.valid }">
                      <div class="input-group">
                        <input type="text"
                          placeholder="{{ user_detail.primary_address.country == 'CA' ? 'Postal Code' : 'Zip code' }}"
                          class="form-control" name="postalCode" [(ngModel)]="user_detail.primary_address.postal_code"
                          #postalCode="ngModel"
                          pattern="{{ user_detail.primary_address.country == 'CA' ? '(^[ABCEGHJKLMNPRSTVXYabceghjklmnprstvxy]\\d[ABCEGHJKLMNPRSTVWXYZabceghjklmnprstvwxyz]( )?\\d[ABCEGHJKLMNPRSTVWXYZabceghjklmnprstvwxyz]\\d$)' : '(^\\d{5}(-\\d{4})?$)' }}" />
                      </div>

                    </div>
                  </div>
                </div>


                <!-- <div *ngIf="false" class="input-group mt-3 form-check">
                  
                          <span class="mr-2 custom-control-description ">I have read the
                            <button type="button" class="btn-link" (click)="viewTermOfUse(termOfUse)">Terms and Agreement</button>and accept it.</span>
                          <label class="switch switch-text switch-pill switch-primary">
                            <input class="switch-input" type="checkbox" name="is_agreement_accepted" [(ngModel)]="user_detail.is_agreement_accepted">
                            <span class="switch-label" data-off="No" data-on="Yes"></span>
                            <span class="switch-handle"></span>
                          </label>
                        </div> -->
              </div>
            </div>
            <div class="form-actions">
              <button *ngIf="model.user_type !== userType.Admin" [disabled]="loading"
                tooltip="Click here to save your entries" class="btn btn-primary mr-2">Save</button>
              <a [routerLink]="['/home']" class="btn btn-secondary">Cancel</a>
            </div>
          </form>

          <ng-template class="modal-dialog" #termOfUse>
            <div class="modal-header">
              <h4 class="modal-title pull-left">Terms and Condition</h4>
              <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body modal-lg" style="height: 450px;overflow-y: auto">
              <div class="col-xs-12">

                <p><strong>Rechoz Ventures Inc</strong></p>
                <p>Universal Terms of Service Agreement (last Revised Nov 29<sup>th</sup> 2023)</p>
                <p><strong>PLEASE READ THIS UNIVERSAL TERMS OF SERVICE AGREEMENT CAREFULLY, AS IT CONTAINS IMPORTANT
                    INFORMATION REGARDING YOUR LEGAL RIGHTS AND REMEDIES.</strong></p>
                <ol style="padding-left:15px; font-size: medium; font-weight: 500">
                  <li style="font-variant-caps: small-caps;">Overview</li>
                </ol>
                <p style="text-align: justify; padding-left: 45px">This Universal Terms of Service Agreement (this
                  &ldquo;Agreement&rdquo;) is entered into by and
                  between Rechoz Ventures Inc, LLC, a corporation incorporated in Ontario ("Rechoz Ventures Inc") and
                  you, and is made effective as of the date of your use of this website ("Site") or the date of
                  electronic acceptance. This Agreement sets forth the general terms and conditions of your use of the
                  Site and the products and services purchased or accessed through this Site (individually and
                  collectively, the &ldquo;Services&rdquo;), and is in addition to (not in lieu of) any specific terms
                  and conditions that apply to the particular Services.</p>
                <p style="text-align: justify; padding-left: 45px">The terms &ldquo;we&rdquo;, &ldquo;us&rdquo; or
                  &ldquo;our&rdquo; shall refer to Rechoz Ventures
                  Inc. The terms &ldquo;you&rdquo;, &ldquo;your&rdquo;, &ldquo;User&rdquo; or &ldquo;customer&rdquo;
                  shall refer to any individual or entity who accepts this Agreement, has access to your account or
                  uses the Services. Nothing in this Agreement shall be deemed to confer any third-party rights or
                  benefits.</p>
                <ol style="padding-left:15px; font-size: medium; font-weight: 500" start="2">
                  <li style="font-variant-caps: small-caps;">Modification Of Agreement, Site Or Services</li>
                </ol>
                <p style="text-align: justify; padding-left: 45px">Rechoz Ventures Inc may, in its sole and absolute
                  discretion, change or modify this Agreement, and
                  any policies or agreements which are incorporated herein, at any time, and such changes or
                  modifications shall be effective immediately upon posting to this Site. Your use of this Site or the
                  Services after such changes or modifications have been made shall constitute your acceptance of this
                  Agreement as last revised. If you do not agree to be bound by this Agreement as last revised, do not
                  use (or continue to use) this Site or the Services. In addition, Rechoz Ventures Inc may
                  occasionally notify you of changes or modifications to this Agreement by email. Rechoz Ventures Inc
                  assumes no liability or responsibility for your failure to receive an email notification if such
                  failure results from an inaccurate email address. In addition, Rechoz Ventures Inc may terminate
                  Your use of Services for any violation or breach of any of the terms of this Agreement by You.
                  RECHOZ VENTURES INC RESERVES THE RIGHT TO MODIFY, CHANGE, OR DISCONTINUE ANY ASPECT OF THIS SITE OR
                  THE SERVICES, INCLUDING WITHOUT LIMITATION PRICES AND FEES FOR THE SAME, AT ANY TIME.</p>
                <ol style="padding-left:15px; font-size: medium; font-weight: 500" start="3">
                  <li style="font-variant-caps: small-caps;">Your Account</li>
                </ol>
                <p style="text-align: justify; padding-left: 45px">In order to access some of the features of this
                  Site or use some of the Services, you will have to
                  create an Account. You represent and warrant to Rechoz Ventures Inc that all information you submit
                  when you create your Account is accurate, current and complete, and that you will keep your Account
                  information accurate, current and complete. If Rechoz Ventures Inc has reason to believe that your
                  Account information is untrue, inaccurate, out-of-date or incomplete, Rechoz Ventures Inc reserves
                  the right, in its sole and absolute discretion, to suspend or terminate your Account. You are solely
                  responsible for the activity that occurs on your Account, whether authorized by you or not, and you
                  must keep your Account information secure, including without limitation your customer number/login,
                  password. Rechoz Ventures Inc will not be liable for any loss you incur due to any unauthorized use
                  of your Account.</p>
                <ol style="padding-left:15px; font-size: medium; font-weight: 500" start="4">
                  <li style="font-variant-caps: small-caps;">General Rules Of Conduct</li>
                </ol>
                <p>You acknowledge and agree that:</p>
                <ol type="I">
                  <li>Your use of this Site and the Services , including any content you submit, will comply with this
                    Agreement and all applicable local, state, national and international laws, rules and regulations.
                  </li>
                  <li>You will not collect or harvest (or permit anyone else to collect or harvest) any User Content
                    (as defined below) or any non-public or personally identifiable information about another User or
                    any other person or entity without their express prior written consent.</li>
                  <li>You will <strong>NOT use this Site</strong> or the Services in a manner (as determined by Rechoz
                    Ventures Inc in its sole and absolute discretion) that:
                    <ol type="a">
                      <li>Is illegal, or promotes or encourages illegal activity;</li>
                      <li>Promotes, encourages or engages in child pornography or the exploitation of children;</li>
                      <li>Promotes, encourages or engages in terrorism, violence against people, animals, or property;
                      </li>
                      <li>Promotes, encourages or engages in any spam or other unsolicited bulk email, or computer or
                        network hacking or cracking;</li>
                      <li>That violates the Fight Online Sex Trafficking Act of 2017 or similar legislation, or
                        promotes or facilitates prostitution and/or sex trafficking;</li>
                      <li>That infringe on the intellectual property rights of another User or any other person or
                        entity;</li>
                      <li>Violates the privacy or publicity rights of another User or any other person or entity, or
                        breaches any duty of confidentiality that you owe to another User or any other person or
                        entity;</li>
                      <li>Interferes with the operation of this Site or the Services found at this Site;</li>
                      <li>Contains or installs any viruses, worms, bugs, Trojan horses or other code, files or
                        programs designed to, or capable of, disrupting, damaging or limiting the functionality of any
                        software or hardware; or</li>
                      <li>Contains false or deceptive language, or unsubstantiated or comparative claims, regarding
                        Rechoz Ventures Inc or Rechoz Ventures Inc&rsquo;s Services.</li>
                    </ol>
                  </li>
                  <li>You will not copy or distribute in any medium any part of this Site or the Services , except
                    where expressly authorized by Rechoz Ventures Inc.</li>
                  <li>You will not modify or alter any part of this Site or the Services found at this Site or any of
                    its related technologies.</li>
                  <li>You will not access Rechoz Ventures Inc Content (as defined below) or User Content through any
                    technology or means other than through this Site itself, or as Rechoz Ventures Inc may designate.
                  </li>
                  <li>You will not re-sell or provide the Services for a commercial purpose, including any of Rechoz
                    Ventures Inc&rsquo;s related technologies, without Rechoz Ventures Inc's express prior written
                    consent.</li>
                  <li>You will not to circumvent, disable or otherwise interfere with the security-related features of
                    this Site or the Services found at this Site (including without limitation those features that
                    prevent or restrict use or copying of any Rechoz Ventures Inc Content or User Content) or enforce
                    limitations on the use of this Site or the Services found at this Site, the Rechoz Ventures Inc
                    Content or the User Content therein.</li>
                </ol>

                <ol style="padding-left:15px; font-size: medium; font-weight: 500" start="5">
                  <li style="font-variant-caps: small-caps;">Consent and Privacy Policy</li>
                </ol>
                <p>If you use our Services, you consent to this Privacy Policy, including our Cookies Policy.</p>
                <ol type="I">
                  <li>Consent:
                    <p>If you use any of our sites or services, you consent to the collection, use and sharing of your
                      data under this Privacy Policy (which includes our Cookie Policy and other documents referenced
                      in
                      this Privacy Policy) and agree to the User Agreement. Where applicable, we will provide you
                      choices
                      that allow you to opt-out or control how we use and share your data.</p>
                    <p>The only personal data we collect and store is information entered by you during your
                      registration.
                      We do not store information.&nbsp;</p>
                    <p>This Privacy Policy is subject to modification, if you do not consent to any future changes you
                      are
                      under no obligation to keep your account open.</p>
                  </li>
                  <li>Registration Data:
                    <p>To create an account you provide data which includes City of residence, Country of residence,
                      Province / State of residence and associated Postal Code / Zip Code</p>
                  </li>
                  <li>Cookies, Web Beacons and Other Similar Technologies:
                    <p>We collect data through cookies and similar technologies.</p>
                    <p>We use cookies and similar technologies to authenticate you and/or your device(s) on, off and
                      across different Services and devices. We also collect this data to improve the services we
                      provide
                      you.</p>
                  </li>
                  <li>Polls and surveys:
                    <p>Polls and surveys are conducted by us and others through our Services. You are under no
                      obligation
                      to respond to polls or surveys and you have choices about the information</p>
                  </li>
                  <li>Credit Card and payment information:
                    <p>All payments are processed through a third party vendor; we collect information from these
                      vendors
                      confirming the receipt of payment and the amount paid. This information is used for auditing and
                      financial reporting purposes.</p>
                  </li>
                  <li>System and Customer Support:
                    <p>We collect and review data for system improvement and maintenance. Data is collected to help
                      resolve customer issues and complaints.</p>
                  </li>
                  <li>Change to Policy:
                    <p>This policy is subject to change. We will notify you well in advance of any impending changes
                      to
                      this policy</p>
                  </li>
                </ol>
                <ol style="padding-left:15px; font-size: medium; font-weight: 500" start="6">
                  <li style="font-variant-caps: small-caps;">Fees And Payments</li>
                </ol>
                <p style="text-align: justify; padding-left: 40px">(GENERAL TERMS, INCLUDING AUTOMATIC RENEWAL
                  TERMS)</p>
                <ol type="I">
                  <li>Payment Due at Time of Order; Non-Refundable. You agree to pay all amounts due for Services at
                    the time you order them. All amounts are non-refundable unless otherwise noted in the Refund
                    Policy.</li>
                  <li>Price Changes. Rechoz Ventures Inc reserves the right to change its prices and fees at any
                    time,
                    and such changes shall be posted online at this Site and effective immediately without need for
                    further notice to you. If you have purchased or obtained Services for a period of months or
                    years,
                    changes in prices and fees shall be effective when the Services enters the new monthly billing
                    cycle unless otherwise stipulated</li>
                  <li>Payment Types / Payment Means. Payments transactions are handled entirely through a third
                    party
                    payment processing company. All fees associated with sending your payments to us are entirely
                    your
                    responsibility and are dictated by the third party company. The available payment types allowed
                    are controlled by the third party payment processing company. The Third party Company we elect
                    to
                    use will be clearly identified on your &ldquo;Payment and Billing Page&rdquo;. The third party
                    payment processing company we elect to use is entirely at our discretion and is subject to
                    change.
                    In the event that this has to change, we will provide a minimum of 45 days notice before
                    switching.</li>
                  <li>Refunds Issued. All services are non-refundable.</li>
                  <li>Monthly Billing Date. If you are being billed on a monthly basis, your monthly billing date
                    will
                    be based on the date of the month you purchased the Services, unless that date falls after the
                    28th of the month, in which case your billing date will be the 28th of each month.</li>
                  <li>Auto-Renewal Terms. Billing is done on a month by month basis , hence renewal is automatic
                    every
                    month. If you cancel a service between billing cycles, you will not be refunded however you will
                    not be billed for the subsequent month.</li>
                  <li>Cancellation Due to Non Payment: We have the right to terminate your service, if your account
                    is
                    over 60 days. This will usually be preceded by a written notice corresponded via email.</li>
                  <li>Rechoz Ventures Inc may offer product-level pricing in various currencies. The third party
                    payment company will conduct the transaction which will be processed in the selected currency
                    and
                    the pricing displayed during the checkout process will be the actual amount submitted for
                    payment.
                    For certain Payment Methods, the issuer of your Payment Method may charge you a foreign
                    transaction fee or other charge, which may be added to the final amount that appears on your
                    bank
                    statement or post as a separate amount. Please check with the issuer of your Payment Method for
                    details. In addition, regardless of the selected currency, you acknowledge and agree that you
                    may
                    be charged Value Added Tax ("VAT"), Goods and Services Tax ("GST"), or other localized fees
                    and/or
                    taxes, based on your bank and/or the country indicated in your billing address section.</li>
                </ol>
              </div>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>