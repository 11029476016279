import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
declare let gtag: Function;

@Injectable()

export class AnalyticService {

  constructor(@Inject(PLATFORM_ID) private platformId: Object) { }

  public eventEmitter(
    eventCategory: string,
    eventAction: string,
    eventLabel: string = null) {
    if (isPlatformBrowser(this.platformId)) {
      gtag('event', 'page_view', {
        page_title: eventCategory,
        page_location: eventLabel,
        page_path: eventAction
      })
    }
  }
}
