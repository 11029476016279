<div class="animated fadeIn infoBox col-8 mx-auto">
  <div class="row">
    <div class="col-lg-12 customLG">
      <div class="card">
        <div class="card-header">
          <i class="fa fa-copyright"></i> Copyright © 2024 Rechoz Ventures Inc 
        </div>
        <div class="card-body text-justify">
          <p>
            The copyright in this website and the material on this website including and not
            limited to the text, programming code, art, pictures, images, audio, and video
            material is owned exclusively by Rechoz Ventures Inc</p>
          <p>Rechoz Ventures Inc grants to you the right to use and view this website and its
            contents through the use of a browser on your computing device be it mobile or
            stationary, to store the necessary files or data on your device which will
            enable you to fully utilize the functionalities of this website. Materials
            viewed or printed from this website is strictly for personal use. All other
            rights are reserved.</p>
          <p>You must not change, alter, republish, circulate, share broadcast, rebroadcast or
            demonstrate this website, its contents, or its data in public without written
            consent from Rechoz Ventures Inc's</p>

          <p>Request permission to use the copyright materials on this website by writing to Info@YardGarageMovingSales.com.
          </p>
          <p>Rechoz Ventures Inc takes the protection of its copyright very seriously.
            In the event that you have contravened this copyright licence, Rechoz Ventures
            Inc may bring legal proceedings against you seeking monetary damages and a ruling to stop you
            from further use of any materials in question. </p>
          <p>If you become aware of any use of Rechoz Ventures Inc’s copyright materials that contravenes or
            may contravene the license above, please report this by email to info@YardGarageMovingSales.com.</p>

        </div>
      </div>
    </div>
  </div>
</div>