import { Injectable, Injector } from '@angular/core';
// tslint:disable-next-line:import-blacklist
import { Observable, BehaviorSubject } from 'rxjs/Rx';
import { throwError } from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/finally';

import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent
} from '@angular/common/http';
import { LoaderService } from './loader/loader.service';
import { environment } from '../environments/environment';
import { AuthenticationService, ErrorService } from './_services';
import { CurrentUser, ExchangeRefreshToken } from './_models';
// import { sessionUser } from "./_common/util";
// import  *  as AWS from  './aws-sdk-2.45.0';
// import AWS = require('aws-sdk');
declare var AWS: any;
declare var $: any;

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {
  currentUser: CurrentUser;
  private refreshTokenInProgress = false;
  private authService: AuthenticationService;
  private errorService: ErrorService;
  requestCount = 0;
  // Refresh Token Subject tracks the current token, or is null if no token is currently
  // available (e.g. refresh pending).
  private refreshTokenSubject: BehaviorSubject<ExchangeRefreshToken> = new BehaviorSubject<ExchangeRefreshToken>(null);

  constructor(
    private loaderService: LoaderService,
    private injector: Injector
  ) { }
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    console.log('Intercept request');
    this.requestCount++;
    this.showLoader();
    this.authService = this.injector.get(AuthenticationService); // get it here within intercept
    this.errorService = this.injector.get(ErrorService);
    return next
      .handle(this.addAuthenticationToken(request))
      .catch(error => {
        // We don't want to refresh token for some requests like login or refresh token itself
        // So we verify url and we throw an error if it's the case
        if (
          request.url.includes('refreshtoken') ||
          request.url.includes('authenticate')
        ) {
          // We do another check to see if refresh token failed
          // In this case we want to logout user and to redirect it to login page

          if (request.url.includes('refreshtoken')) {
            this.authService.logout();
          }

          return throwError(error);
        }
        // If error status is different than 401 we want to skip refresh token
        // So we check that and throw the error if it's the case
        if (error.status && (error.status !== 401)) {
          if (error.status !== 404 && !(error?.error?.message))
            this.errorService.viewError(error.name || "Error", error.message || error, String(error.status || ""), JSON.stringify(request), JSON.stringify(error));
          return throwError(error);
        }
        if (this.refreshTokenInProgress) {
          // If refreshTokenInProgress is true, we will wait until refreshTokenSubject has a non-null value
          // – which means the new token is ready and we can retry the request again
          return this.refreshTokenSubject
            .filter(result => result !== null)
            .take(1)
            .switchMap(() => next.handle(this.addAuthenticationToken(request)));
        } else {
          this.refreshTokenInProgress = true;

          // Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
          this.refreshTokenSubject.next(null);

          return this.authService
            .refreshAccessToken()
            .switchMap((token: ExchangeRefreshToken) => {
              this.setTokens(token);
              // When the call to refreshToken completes we reset the refreshTokenInProgress to false
              // for the next time the token needs to be refreshed
              this.refreshTokenInProgress = false;
              this.refreshTokenSubject.next(token);

              return next.handle(this.addAuthenticationToken(request));
            })
            .catch((err: any) => {
              this.refreshTokenInProgress = false;

              this.authService.logout();
              if (err.status && err.status === 401) {
                this.authService.redirectToLogin();
              }
              else {
                  this.errorService.viewError(err.name || "Failed to refresh token", err.message || error, String(error.status || ""), JSON.stringify(request), JSON.stringify(err));
              }
              return throwError(err);
            });
        }
      })
      .finally(() => {
        this.requestCount--;
        if (this.requestCount === 0) {
          this.onEnd();
        }

      });
  }

  addAuthenticationToken(request: HttpRequest<any>) {
    //Return original request 
    if (request.url.includes('api.openweathermap.org'))
      return request;

    console.log('Add auth header');
    // Get access token from Local Storage
    const accessToken = this.getAccessToken();

    const req = request.clone({
      url: request.url.includes('assets/data/search-result.json') ? request.url : this.updateUrl(request.url),
      setHeaders: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    });

    // If access token is null this means that user is not logged in
    // And we return the original request
    if (!accessToken) {
      console.log('Access token is null return actual request');
      return req;
    }

    // We clone the request, because the original request is immutable
    return req.clone({
      setHeaders: {
        Authorization: `Bearer ${accessToken}`
      }
    });
  }

  updateUrl(url: string) {
    console.log(`Update URL as: ${environment.api_origin}${url}`);
    return `${environment.api_origin}${url}`;
  }

  getAccessToken() {
    this.currentUser = this.authService.sessionUser;
    if (this.currentUser && this.currentUser.token) {
      return this.currentUser.token;
    }
    return null;
  }

  private onEnd(): void {
    this.hideLoader();
  }

  private showLoader(): void {
    this.loaderService.show();
  }

  private hideLoader(): void {
    this.loaderService.hide();
  }

  private setTokens(token: ExchangeRefreshToken) {
    const currentUser: CurrentUser = this.authService.sessionUser;
    currentUser.token = token.access_token;
    currentUser.refresh_token = token.refresh_token;
    localStorage.setItem('currentUser', JSON.stringify(currentUser));
  }
}
