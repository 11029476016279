import { Component, Input, ChangeDetectionStrategy, EventEmitter, Output } from '@angular/core';
import { GalleryState } from '../models/gallery.model';

@Component({
  selector: 'gallery-edit',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="g-edit">
      <span class="btn btn-primary btn-xs" (click)="deleteClick.emit(state.currIndex)">
        <span class="fa fa-remove "></span>
      </span>
    </div>
  `
})
export class GalleryEditComponent {
  @Input() state: GalleryState;
  @Output() deleteClick = new EventEmitter<number>();
}
