import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContactDto } from 'src/app/_models';
import { UserService, AlertService } from 'src/app/_services';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
  model: ContactDto;
  loading = false;
  constructor(private userService: UserService
    , private alertService: AlertService
    , private router: Router) {
      this.model = {
        firstName: '',
        lastName: '',
        email: '',
        comments: '',
      };
     }

  ngOnInit() {
  }

  sendContactReqeust() {
    this.loading = true;
    this.userService.sendContactUsRequest(this.model)
      .subscribe(
        data => {
          this.loading = false;
          this.alertService.success(data.message, true);
          this.router.navigate(['/home']);
        },
        (error: HttpErrorResponse) => {
          console.log(`Error: ${error}`);
          this.loading = false;
        }
      );
  }
 
}
