<div role="document">
    <div class="col-md-12">
      <form
        name="form"
        (ngSubmit)="f.form.valid && saveItem()"
        #f="ngForm"
        novalidate
      >
        <div class="modal-header">
            <h4 class="modal-title pull-left">{{ itemResult.item.name }} > Pictures</h4>
          <button
            type="button"
            class="close pull-right"
            aria-label="Close"
            (click)="onClose()"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <gallery *ngIf="images.length > 0" id="gallerize-ex" gallerize [items]="images"></gallery>
            <h5 *ngIf="images.length===0" style="width: 100%;" class="pull-left"> There are no pictures. </h5>
        </div>
        <div class="modal-footer">
          <!-- <button type="button" class="btn btn-default" data-dismiss="modal" (click)="onClose()">Close</button>
          <button type="submit" class="btn btn-primary">Submit</button> -->
        </div>
      </form>
    </div>
  </div>
  