export class State {
  constructor(
      public _id: number,
      public country_id: number,
      public prov_state: string
  ) { }
}

export class State1 {
prov_state: string;
prov_state_code: string;
country_name: string;
// constructor(
//     public key: string,
//     public prov_state_code: string,
//     public prov_state: string
// ) { }
}