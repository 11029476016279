import { Component, OnInit, EventEmitter, Output, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Item, ItemResult, Category, SubCategory } from '../../_models';
import { LookupService, AuthenticationService } from '../../_services';
import { AngularFileUploaderComponent } from 'angular-file-uploader';
import * as _ from 'underscore';
import { HttpRequest } from '@angular/common/http';
import { Observable, Subject, of, concat } from 'rxjs';
import { distinctUntilChanged, tap, switchMap, catchError, delay } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { GalleryItem, ImageItem } from '../../shared/index';
// import { IDropdownSettings } from 'ng-multiselect-dropdown';
// import { AngularFileUploaderConfig } from 'angular-file-uploader';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'item-picture',
  templateUrl: './item-picture.component.html',
  styleUrls: ['./item-picture.component.scss']
})
export class ItemPictureComponent implements OnInit {
  itemResult: ItemResult;
  itemChangeEvent: EventEmitter<ItemResult> = new EventEmitter();
  isEdit: boolean;
  eventIdentifier: string;
  categories: Category[];
  subCategories: SubCategory[];

  subCategories$: Observable<SubCategory[]>;
  peopleLoading = false;
  subcategoryInput$ = new Subject<string>();
  images: GalleryItem[];
  public addTagNowRef: (name) => void;

  // dropdownSettingsMulti: IDropdownSettings;
  // dropdownSettingsSingle: IDropdownSettings;
  // resetUpload1: boolean;
  // title: string;

  @ViewChild('fileUpload')
  private fileUpload: AngularFileUploaderComponent;

  /*itemResult: ItemResult = {
    status: '',
    item: {
      name: '',
      category: '',
      associated_sub_category: [{ name: '' }],
      cost: null,
      notes: '',
      pictures: null
    }
  };*/

  afuConfig1: any;

  get title() {
    return this.isEdit ? `Edit: ${this.itemResult.item.name}` : 'Add Item';
  }

  constructor(private bsModalRef: BsModalRef, private lookupService: LookupService, private authService: AuthenticationService) {
    this.getCategories();
    this.getSubCategories();
    // console.log(`Initial Item: ${JSON.stringify(this.itemResult)}`);
    // console.log(`Is Edit: ${this.isEdit}`);
    // console.log(`EventIdentifier: ${this.eventIdentifier}`);
    this.addTagNowRef = (name) => this.addNewSubCategory(name);
  }

  ngOnInit() {
    // this.dropdownSettingsMulti = {
    //   singleSelection: false,
    //   idField: 'name',
    //   textField: 'name',
    //   selectAllText: 'Select All',
    //   unSelectAllText: 'UnSelect All',
    //   itemsShowLimit: 2,
    //   allowSearchFilter: true,
    //   limitSelection: 2
    // };

    // this.dropdownSettingsSingle = {
    //   singleSelection: true,
    //   idField: 'name',
    //   textField: 'name',
    //   selectAllText: 'Select All',
    //   unSelectAllText: 'UnSelect All',
    //   itemsShowLimit: 1,
    //   allowSearchFilter: true
    // };
    console.log(`On Init Item: ${JSON.stringify(this.itemResult)}`);
    console.log(`On Is Edit: ${this.isEdit}`);
    console.log(`On EventIdentifier: ${this.eventIdentifier}`);
    this.images = [];
    this.itemResult.item.pictures.map(picture=>{
      this.images.push(
        new ImageItem({ src: decodeURIComponent(picture).replace('+','%2B'), thumb: decodeURIComponent(picture).replace('+','%2B') }),
      );
    })

    this.afuConfig1 = {
      multiple: true,
      limitSelection: 2,
      uploadAPI: {
        url: `${environment.api_origin}/event`,
        headers: {
          Authorization: `Bearer ${this.authService.sessionUser.token}`,
          EventIdentifier: this.eventIdentifier,
          PicturesToDeleteCount: '0'
        }
      },
      hideResetBtn: true
    };

  }

  getCategories() {
    this.lookupService.getCategories().subscribe(data => {
      this.categories = data;
    });
  }

  getSubCategories() {
    this.lookupService.getSubCategories().subscribe(data => {
      this.subCategories = data;
      // this.subCategories$ = of(data);
      // this.loadSubcategories();
    });
  }

  // getFilterSubCategories(term: string = null): Observable<SubCategory[]> {
  //   console.log(`Term: ${term}`);
  //   let items = this.subCategories;
  //   if (term) {
  //     items = items.filter(x => x.name.toLocaleLowerCase().indexOf(term.toLocaleLowerCase()) > -1);
  //   }
  //   return of(items).pipe(delay(500));
  // }

  // private loadSubcategories() {
  //   this.subCategories$ = concat(
  //     of([]), // default items
  //     this.subcategoryInput$.pipe(
  //       distinctUntilChanged(),
  //       tap(() => this.peopleLoading = true),
  //       switchMap(term => this.getFilterSubCategories(term).pipe(
  //         catchError(() => of([])), // empty list on error
  //         tap(() => this.peopleLoading = false)
  //       ))
  //     )
  //   );
  // }

  // trackByFn(item: SubCategory) {
  //   console.log(`Track: ${JSON.stringify(item)}`);
  //   return item.category_name;
  // }

  onClose() {
    this.bsModalRef.hide();
  }

  addNewSubCategory(tag: string) {
    console.log(`Category: ${JSON.stringify(this.itemResult)}`);
    return { category_name: this.itemResult.item.category, name: tag };
  }

  DocUpload(httpRequest) {
    this.itemResult.status = 'Ok';
    this.itemResult.item.pictures = JSON.parse(httpRequest.response);
    console.log(`item: ${JSON.stringify(this.itemResult)}`);
    this.itemChangeEvent.emit(this.itemResult);
    this.bsModalRef.hide();
  }

  saveItem() {
    if (this.fileUpload.selectedFiles.length) {
      this.fileUpload.headers = {...this.afuConfig1.uploadAPI.headers, PicturesToDeleteCount: this.itemResult.item.pictures_to_delete.length.toString()};
      //this.afuConfig1.uploadAPI.headers = {...this.afuConfig1.uploadAPI.headers, PicturesToDeleteCount: this.itemResult.item.pictures_to_delete.length.toString()}
      this.fileUpload.uploadFiles();
    } else {
      this.itemResult.status = 'Ok';
      console.log(`item: ${JSON.stringify(this.itemResult)}`);
      this.itemChangeEvent.emit(this.itemResult);
      this.bsModalRef.hide();
    }
  }

  onItemSelect(item: any) {
    console.log(item);
  }
  onSelectAll(items: any) {
    console.log(items);
  }

}
