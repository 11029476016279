export function isPresent(obj: any): boolean {
  return obj !== undefined && obj !== null;
}

export function isDate(obj: any): boolean {
  return !/Invalid|NaN/.test(new Date(obj).toString());
}

export function sessionUser(): any {
  const user = JSON.parse(localStorage.getItem('currentUser'));
  // tslint:disable-next-line:curly
  if (user) return user;
  return null;
}

export function GetErrorMessage(error): string {
  return (error.error || JSON.parse(error)).message;
}

export function uniqId() {
  // Math.random should be unique because of its seeding algorithm.
  // Convert it to base 36 (numbers + letters), and grab the first 9 characters
  // after the decimal.
  return '_' + Math.random().toString(36).substr(2, 9);
}
