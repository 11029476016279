import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';

import { LoaderState } from './loader';

@Injectable()
export class LoaderService {
  private loaderSubject = new Subject<LoaderState>();
  isEnable = true;

  loaderState = this.loaderSubject.asObservable();

  constructor() {}

  show() {
    if (this.isEnable) {
      this.loaderSubject.next({ show: true } as LoaderState);
    }
  }

  hide() {
    this.loaderSubject.next({ show: false } as LoaderState);
  }
}
