<app-header></app-header>
<div class="app-body">
  <app-menu></app-menu>
  <!-- Main content -->
  <main class="main">
    <!-- Breadcrumb -->
    <ol class="breadcrumb">
      <!-- <app-breadcrumbs></app-breadcrumbs> -->

      <!-- Breadcrumb Menu-->
      <!--<li class="breadcrumb-menu hidden-md-down">
        <div class="btn-group" role="group" aria-label="Button group with nested dropdown">
          <a class="btn btn-secondary" href="#"><i class="icon-speech"></i></a>
          <a class="btn btn-secondary" [routerLink]="['/dashboard']"><i class="icon-graph"></i> &nbsp;Dashboard</a>
          <a class="btn btn-secondary" href="#"><i class="icon-settings"></i> &nbsp;Settings</a>
        </div>
      </li>
      -->
    </ol>

    <div class="container-fluid">
      <alert></alert>
      <router-outlet></router-outlet>
    </div>
    <!--/.conainer-fluid -->
  </main>
</div>
<app-footer></app-footer>
