<div class="sidebar">
  <!-- Anonymous -->
  <nav *ngIf="!IsUserLoggedIn" class="sidebar-nav ps ps--active-y">
    <ul class="nav">
      <li class="nav-item nav-dropdown" routerLinkActive="open" appNavDropdown>
        <a class="nav-link nav-dropdown-toggle" href="#" appNavDropdownToggle><i class="icon-magnifier"></i> Search</a>
        <ul class="nav-dropdown-items">
          <li class="nav-item">
            <a class="nav-link" routerLinkActive="active" [routerLink]="['/search-sale']"><i class="icon-event"></i>
              Sale Event</a>
          </li>
        </ul>
      </li>
      <li class="nav-item" routerLinkActive="active">
        <a class="nav-link" routerLinkActive="active" href="https://youtu.be/1vwtR5PEKfE" target="_blank"><i class="icon-film"></i>Tour Guide</a>
      </li>
      <li class="divider"></li>

      <li class="nav-item" routerLinkActive="active">
        <a class="nav-link" routerLinkActive="active" [routerLink]="['/help-assist-set-up']" target="_blank"><i class="icon-film"></i>Need Help?</a>
      </li>
      <li class="nav-item" routerLinkActive="active">
        <a class="nav-link" routerLinkActive="active" [routerLink]="['/subscribe']"><i class="icon-film"></i>Keep Me Posted</a>
      </li>
      <li class="divider"></li>

    </ul>
  </nav>

  <!-- LoggedIn -->
  <nav *ngIf="IsUserLoggedIn" class="sidebar-nav ps ps--active-y">
    <ul class="nav">
      <li class="nav-item nav-dropdown" routerLinkActive="open" appNavDropdown>
        <a class="nav-link nav-dropdown-toggle" href="#" appNavDropdownToggle><i class="icon-people"></i> My Profile</a>
        <ul class="nav-dropdown-items">
          <!-- <li class="nav-item">
            <a class="nav-link" routerLinkActive="active" [routerLink]="['/customers']"><i class="icon-people"></i> Customers</a>
          </li> -->
          <li class="nav-item">
            <a class="nav-link" routerLinkActive="active" [routerLink]="['/user/profile']"><i class="icon-note"></i>
              Registration Info</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLinkActive="active" [routerLink]="['/changepassword']"><i
                class="icon-refresh"></i> Reset Password</a>
          </li>
        </ul>
      </li>
      <li class="divider"></li>

      <li class="nav-item nav-dropdown" routerLinkActive="open" appNavDropdown>
        <a class="nav-link nav-dropdown-toggle" href="#" appNavDropdownToggle><i class="fa fa-book"></i>
          Yard/Garage/Moving Sale</a>
        <ul class="nav-dropdown-items">
          <li class="nav-item">
            <a class="nav-link" routerLinkActive="active" [routerLink]="['/event/location']"><i
                class="icon-location-pin"></i> Event Location</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLinkActive="active" [routerLink]="['/event/schedule']"><i
                class="icon-calendar"></i> Schedule Event</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLinkActive="active" [routerLink]="['/event/items']"><i class="icon-grid"></i>
              Setup Items</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLinkActive="active" [routerLink]="['/event/summary']"><i class="icon-present"></i>
              Summary Review</a>
          </li>
        </ul>
      </li>
      <li class="divider"></li>
      <li class="nav-item">
        <a class="nav-link" routerLinkActive="active" [routerLink]="['/search-sale']"><i class="icon-event"></i>
          Search Event</a>
      </li>
    </ul>
  </nav>
  <button class="sidebar-minimizer brand-minimizer" type="button" appSidebarMinimizer>&#9855;</button>
</div>