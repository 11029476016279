import { Component, OnInit } from '@angular/core';
import { CurrentUser } from '../../_models/index';
import { UserService, AuthenticationService } from '../../_services/index';
import { environment} from '../../../environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  currentUser: CurrentUser;
  isTest: boolean;

  constructor(private userService: UserService, private authService: AuthenticationService) {
    this.currentUser = userService.sessionUser;
    this.isTest = !environment.production;
  }

  ngOnInit() {
  }

  get IsUserLoggedIn(): boolean {
    return this.authService.isAuthenticated();
  }

  public toggled(open: boolean): void {
    console.log('Dropdown is now: ', open);
  }

}
