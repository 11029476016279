import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { User, Country1, State1, UserType, Customer, CurrentUser } from '../_models';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { UserService, AlertService, LookupService, AuthenticationService } from '../_services';
import { GetErrorMessage } from '../_common/util';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit, OnDestroy {
  model: User;
  user_detail: Customer;
  countries: Country1[];
  states: State1[];
  loading = false;
  modalRef: BsModalRef;

  config = {
    animated: true,
    keyboard: true,
    backdrop: true,
    ignoreBackdropClick: true
  };

  constructor(
    private router: Router
    , private userService: UserService
    , private alertService: AlertService
    , private lookupService: LookupService
    , private modalService: BsModalService
    , private authenticationService: AuthenticationService
  ) {
    this.countries = [];
    this.states = [];
    this.user_detail = {
      first_name: '',
      last_name: '',
      tel_no: '',
      fax_no: '',
      gender: '',
      reg_no: '',
      // initial: '',
      primary_address: {
        country: '',
        prov_state: '',
        address1: '',
        address2: '',
        city: '',
        postal_code: ''
      },
      billing_address: null,
      is_billing_address_same: false,
      is_agreement_accepted: false
    };


    this.model = {
      email: '',
      password: '',
      reset_token: '',
      user_type: UserType.Customer,
      user_details: {
        customer: this.user_detail,
        admin: null
      },
      kindOfSale: '',
      opt1RegEmailLetters: false,
      opt2NotifyOfSale: false,
      /*refresh_token: '',
      request_ip: '',
      last_login_on: null,
      password_hash: '',
      password_salt: '',
      inserted_date: '',
      updated_date: null,
      inserted_by: '',
      updated_by: '',
      _version: null*/
    };

    this.lookupService.getCountries1().subscribe(data => {
      this.countries = data;
    });

    this.lookupService.getStates1().subscribe(data => {
      this.states = data;
    });
  }
  kindOfSaleOptions: string[] = ["Yard", "Garage", "Moving", "Thrift", "Store Closing", "Estate", "Liquidation"];
  opt1RegEmailLettersLabel = "I want to receive e-news, e-publications, e-letters, offers, and articles on Yard, Garage,\nMoving or Other Sale Events.";
  opt2NotifyOfSaleLabel = "Kindly notify me of ongoing sales in my area.";
  getzipname(): string {
    return this.user_detail.primary_address.country == 'CA' ? "Postal Code" : "Zip code";
  }

  ngOnDestroy(): void {
    const bodyTag = document.body;
    bodyTag.classList.remove('sidebar-hidden');
  }

  ngOnInit() {
    const bodyTag = document.body;
    bodyTag.classList.add('sidebar-hidden');
  }

  register() {
    this.loading = true;
    this.userService.registerUser(this.model)
      .subscribe(
        data => {
          console.log(`Data: ${data}`);
          this.alertService.success('Registration successful', true);
          //login user and redirect to quick-event
          this.authenticationService
            .login(this.model.email, this.model.password)
            .subscribe(
              (data: CurrentUser) => {
                // tslint:disable-next-line:one-line
                if (data) {
                  // store user to local storage
                  localStorage.setItem('currentUser', JSON.stringify(data));
                }
                this.router.navigate(['/event/quick-event']);
                this.loading = false;
                this.authenticationService
                  .auditLogin(this.model.email)
                  .subscribe();
              },
              error => {
                console.log(error);
                console.log(error.error.message);
                // console.log(JSON.parse(error.error));
                // this.alertService.error(JSON.parse(error._body || error).messsage);
                this.alertService.error(error.error.message);
                this.loading = false;
              }
            );

          //this.router.navigate(['/login']);
        },
        error => {
          // console.log(`Error: ${error}`);
          // console.log(`Error 1: ${JSON.parse(error.error)}`);
          // console.log(`Error 2: ${JSON.parse(error)}`);
          // this.alertService.error(JSON.parse(error._body).messsage);
          this.alertService.error(GetErrorMessage(error));
          this.loading = false;
        });
  }

  viewTermOfUse(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      template,
      Object.assign({}, this.config, { class: 'gray modal-lg' }));
  }

}
