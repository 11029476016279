<div class="animated fadeIn infoBox col-10 mx-auto">
    <div class="row">
        <div class="col-lg-12 customLG">
            <div class="card">
                <div class="card-header">
                    <b>Subscribe (Keep Me Posted)</b>
                </div>
                <div class="card-body">
                    <form name="form" (ngSubmit)="f.form.valid && eFarmingSubscribe()" #f="ngForm" novalidate>
                        <h5>If you wish to be notified of upcoming sales in your area,  kindly subscribe by filling out the form below.</h5>

                        <!-- <h5> LET US GET STARTED!!!</h5> -->
                        <p></p>
                        <p></p>
                        <div class="row">
                            <div class="col-md-8">
                                <div class="row">
                                    <div class="form-group col-sm-6">
                                        <label class="col-form-label" for="contact_name">Contact Name</label>
                                        <strong class="text-danger">*</strong>
                                        <div class="controls">
                                            <div class="input-prepend input-group"
                                                [ngClass]="{ 'has-error': f.submitted && !contact_name.valid }">
                                                <input placeholder="Contact Name" type="text" class="form-control"
                                                    name="contact_name" [(ngModel)]="model.contact_name"
                                                    #contact_name="ngModel" required />
                                            </div>
                                            <div *ngIf="f.submitted && !contact_name.valid" class="has-error">
                                                <span class="help-block">Contact Name is required</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group col-sm-6">
                                        <label class="col-form-label" for="email">Email Address</label>
                                        <strong class="text-danger">*</strong>
                                        <div class="controls">
                                            <div class="input-prepend input-group"
                                                [ngClass]="{ 'has-error': f.submitted && !email.valid }">
                                                <input placeholder="Email" type="email" class="form-control"
                                                    name="email" [(ngModel)]="model.email" #email="ngModel" required
                                                    email />
                                            </div>
                                            <div *ngIf="f.submitted && !email.valid" class="has-error">
                                                <span *ngIf="email?.errors?.required" class="help-block">Email is
                                                    required.</span>
                                                <span *ngIf="!email?.errors?.required && email?.errors?.email"
                                                    class="help-block">Invalid email address</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="form-group col-sm-3">
                                        <label class="col-form-label" for="city">Country</label>
                                        <strong class="text-danger">*</strong>
                                        <div class="controls">
                                            <div class="input-prepend input-group" [ngClass]="{ 'has-error': f.submitted && model.country == '' }">
                                                <select name="country" class="form-control" [(ngModel)]="model.country"
                                                    required>
                                                    <option value="">Select Country</option>
                                                    <option *ngFor="let country of countries"
                                                        value="{{country.country_name}}">
                                                        {{country.country_name}}
                                                    </option>
                                                </select>
                                            </div>
                                            <div *ngIf="f.submitted && model.country == ''" class="has-error">
                                                <span class="help-block">Country is required</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group col-sm-5">
                                        <label class="col-form-label" for="city">State or Province</label>
                                        <strong class="text-danger">*</strong>
                                        <div class="controls">
                                            <div class="input-prepend input-group" [ngClass]="{ 'has-error': f.submitted && model.prov_state == '' }">
                                                <select name="prov_state" class="form-control"
                                                    [(ngModel)]="model.prov_state" required>
                                                    <option value="">Select {{ model.country == 'CA' ? "Province" :
                                                        "State" }}
                                                    </option>
                                                    <option
                                                        *ngFor="let state of states | KeyFilter: 'country_name' : model.country"
                                                        value="{{state.prov_state_code}}">{{state.prov_state}}
                                                    </option>
                                                </select>
                                            </div>
                                            <div *ngIf="f.submitted && model.prov_state == ''" class="has-error">
                                                <span class="help-block">{{ model.country == 'CA' ? "Province" : "State" }} is required.</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group col-sm-4">
                                        <label class="col-form-label" for="city">City</label>
                                        <strong class="text-danger">*</strong>
                                        <div class="controls">
                                            <div class="input-prepend input-group" [ngClass]="{ 'has-error': f.submitted && !city.valid }">
                                                <input placeholder="City" type="text" class="form-control" name="city"
                                                    [(ngModel)]="model.city" #city="ngModel" required />
                                            </div>
                                            <div *ngIf="f.submitted && !city.valid" class="has-error">
                                                <span class="help-block">City is required</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    
                                    <div class="form-group col-sm-4">
                                        <label class="col-form-label" for="code">Zip/Postal Code</label>
                                        <strong class="text-danger">*</strong>
                                        <div class="controls">
                                            <div class="input-prepend input-group" [ngClass]="{ 'has-error': f.submitted && !postalCode.valid }">
                                                <input type="text" placeholder="{{ getzipname() }}" class="form-control"
                                                    name="postalCode" [(ngModel)]="model.postal_code"
                                                    #postalCode="ngModel" required
                                                    pattern="{{ model.country == 'CA' ? '(^[ABCEGHJKLMNPRSTVXYabceghjklmnprstvxy]\\d[ABCEGHJKLMNPRSTVWXYZabceghjklmnprstvwxyz]( )?\\d[ABCEGHJKLMNPRSTVWXYZabceghjklmnprstvwxyz]\\d$)' : '(^\\d{5}(-\\d{4})?$)' }}" />
                                            </div>
                                            <div *ngIf="f.submitted && !postalCode.valid" class="has-error">
                                                <span *ngIf="postalCode?.errors?.required" class="help-block">{{
                                                    model.country
                                                    == 'CA' ? "Postal Code" : "Zip code"
                                                    }} is
                                                    required.</span>
                                                <span
                                                    *ngIf="!postalCode?.errors?.required && postalCode?.errors?.pattern"
                                                    class="help-block">{{
                                                    model.country == 'CA' ? "Postal Code" : "Zip code" }}
                                                    is invalid.</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <h5>I am intrested in (check as needed)</h5>
                                <div class="row">
                                    <div class="col">
                                        <div class="input-group mt-2">
                                            <p-checkbox name="chkAll" label="All" binary="true"
                                                [(ngModel)]="chkAllSelection"
                                                (onChange)="onTypeOfSaleAllChange($event)"></p-checkbox>
                                        </div>
                                    </div>
                                </div>
                                <div *ngFor="let saleKind of kindOfSaleOptions" class="p-field-checkbox"
                                    disabled="true">
                                    <div class="row">
                                        <div class="col">
                                            <div class="input-group mt-1">
                                                <p-checkbox name="group2" [label]="saleKind.value"
                                                    [value]="saleKind.value" [(ngModel)]="selectedSales"
                                                    [disabled]="saleKind.isDisable" [inputId]="saleKind"></p-checkbox>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                               
                            </div>
                        </div>

                        <div class="row">
                            <div class="col">
                                <div class="input-group mt-3">
                                    <p-checkbox name="chkOptEmailLetters" label={{opt1RegEmailLettersLabel}}
                                        [(ngModel)]="model.opt_email_letters" binary="true"></p-checkbox>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="input-group mt-3">
                                    <p-checkbox name="chkOptNotifySale" label={{opt2NotifyOfSaleLabel}}
                                        [(ngModel)]="model.opt_notify_sale" binary="true"></p-checkbox>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="input-group mt-3">
                                    <p-checkbox name="chkOptWantInfo" label={{opt3WantInfoLabel}}
                                        [(ngModel)]="model.opt_want_info" binary="true"></p-checkbox>
                                </div>
                            </div>
                        </div>

                        <div class="form-actions mt-3">
                            <button [disabled]="loading" class="btn btn-primary mr-2">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>