import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-list-view',
  templateUrl: './list-view.component.html',
  styleUrls: ['./list-view.component.scss']
})
export class ListViewComponent implements OnInit {
  @Input() get title(): string { return this._title; }
  @Input() get distance(): string { return this._distance;}
  @Input() get mapLink(): string { return this._mapLink;}
  @Input() get price(): string { return this._price;}
  @Input() get eventDate(): string { return this._eventDate;}
  @Input() get eventImage(): string { return this._eventImage;}
  @Input() get eventDetails(): string { return this._eventDetails;}

  set title(title: string) {
    this._title = (title && title.trim()) || '';
  }
  set distance(distance: string) {
    this._distance = (distance && distance) || '';
  }
  set mapLink(mapLink: string) {
    this._mapLink = '/event-detail/'+ (mapLink && mapLink) || '';
  }
  set price(price: string) {
    this._price = (price && price) || '0';
  }
  set eventDate(eventDate: string) {
    this._eventDate = (eventDate && eventDate) || '0';
  }
  set eventImage(eventImage: string) {
    this._eventImage = eventImage ? eventImage : '../../../assets/img/no-image.png';
  }
  set eventDetails(eventDetails: string) {
    this._eventDetails = eventDetails ? eventDetails.toString() : '';
  }

  public _title = '';
  public _distance = '';
  public _mapLink = '';
  public _price = '';
  public _eventDate = '';  
  public _eventImage = '';
  public _eventDetails = '';

  constructor() { }

  ngOnInit(): void {
  }

}
