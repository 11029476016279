<style>
  .lable {
    width: 30% !important;
    text-align: right;
  }

  ol>li {
    padding-left: 10px;
    text-align: justify;
  }
</style>
<div class="app flex-row align-items-center registerBox">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-8">
        <div class="card">
          <div class="card-body p-4">
            <h1>Create Account</h1>
            <p class="text-muted">Join YardGarageMovingSales.com</p>
            <form name="form" (ngSubmit)="f.form.valid && register()" #f="ngForm" novalidate>
              <div class="row">
                <div class="col">
                  <div class="input-group mt-3" [ngClass]="{ 'has-error': f.submitted && !firstName.valid }">
                    <input placeholder="First Name*" type="text" class="form-control" name="firstName"
                      [(ngModel)]="user_detail.first_name" #firstName="ngModel" required />
                  </div>
                  <div *ngIf="f.submitted && !firstName.valid" class="has-error">
                    <span class="help-block">First Name is required</span>
                  </div>
                </div>
                <div class="col">
                  <div class="input-group mt-3" [ngClass]="{ 'has-error': f.submitted && !lastName.valid }">
                    <input placeholder="Last Name*" type="text" class="form-control" name="lastName"
                      [(ngModel)]="user_detail.last_name" #lastName="ngModel" required />
                  </div>
                  <div *ngIf="f.submitted && !lastName.valid" class="has-error">
                    <span class="help-block">Last Name is required</span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="input-group mt-3" [ngClass]="{ 'has-error': f.submitted && !email.valid }">
                    <input type="email" placeholder="Email*" class="form-control" name="email" [(ngModel)]="model.email"
                      #email="ngModel" required email />
                  </div>
                  <div *ngIf="f.submitted && !email.valid" class="has-error">
                    <span *ngIf="email?.errors?.required" class="help-block">Email is required.</span>
                    <span *ngIf="!email?.errors?.required && email?.errors?.email" class="help-block">Invalid email
                      address</span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="input-group mt-3"
                    [ngClass]="{ 'has-error': password.invalid && (password.dirty || password.touched) }">

                    <input type="password" placeholder="Password*" class="form-control" name="password"
                      [(ngModel)]="model.password" #password="ngModel" required />
                  </div>
                  <div *ngIf="password.invalid && (password.dirty || password.touched)" class="has-error">
                    <span *ngIf="password.errors.required" class="help-block">Password is required</span>
                  </div>
                </div>
                <div class="col">
                  <div class="input-group mt-3"
                    [ngClass]="{ 'has-error': confirmPassword.invalid && (confirmPassword.dirty || confirmPassword.touched) }">
                    <input type="password" placeholder="Confirm Password*" class="form-control" ngModel
                      name="confirmPassword" #confirmPassword="ngModel" [equalTo]="password" required />
                  </div>
                  <div *ngIf="confirmPassword.invalid && (confirmPassword.dirty || confirmPassword.touched) "
                    class="has-error">
                    <span *ngIf="confirmPassword?.errors?.required" class="help-block">Confirm password is
                      required.</span>
                    <span *ngIf="!confirmPassword?.errors?.required && confirmPassword?.errors?.equalTo"
                      class="help-block">Confirm password must be matched with password</span>
                  </div>

                </div>
              </div>
              <!-- <div class="row">
                <div class="col">
                  <div class="input-group mt-4" [ngClass]="{ 'has-error': f.submitted && !address1.valid }">
                    <input type="text" placeholder="Address line 1" class="form-control" name="address1"
                      [(ngModel)]="user_detail.primary_address.address1" #address1="ngModel" />
                  </div>
                  <div *ngIf="f.submitted && !address1.valid" class="has-error">
                    <span class="help-block">Address1 is required</span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="input-group mt-3">
                    <input type="text" placeholder="Address line 2" class="form-control" name="address2"
                      [(ngModel)]="user_detail.primary_address.address2" #address2="ngModel" />
                  </div>
                </div>
              </div> -->
              <div class="row">
                <div class="col">
                  <div class="input-group mt-3"
                    [ngClass]="{ 'has-error': f.submitted && user_detail.primary_address.country == '' }">
                    <select name="country" class="form-control" [(ngModel)]="user_detail.primary_address.country"
                      required>
                      <option value="">Select Country*</option>
                      <option *ngFor="let country of countries" value="{{country.country_name}}">
                        {{country.country_name}}
                      </option>
                    </select>
                  </div>
                  <div *ngIf="f.submitted && user_detail.primary_address.country == ''" class="has-error">
                    <span class="help-block">Country is required</span>
                  </div>
                </div>
                <div class="col">
                  <div class="input-group mt-3"
                    [ngClass]="{ 'has-error': f.submitted && user_detail.primary_address.prov_state == '' }">

                    <select name="prov_state" class="form-control" [(ngModel)]="user_detail.primary_address.prov_state"
                      required>
                      <option value="">Select {{ user_detail.primary_address.country == 'CA' ? "Province" : "State" }}*
                      </option>
                      <option
                        *ngFor="let state of states | KeyFilter: 'country_name' : user_detail.primary_address.country"
                        value="{{state.prov_state_code}}">{{state.prov_state}}
                      </option>
                    </select>
                  </div>
                  <div *ngIf="f.submitted && user_detail.primary_address.prov_state == ''" class="has-error">
                    <span class="help-block">{{ user_detail.primary_address.country == 'CA' ? "Province" : "State" }} is
                      required.</span>
                  </div>
                </div>
                <!-- <div class="col">
                  <div class="input-group mt-3" [ngClass]="{ 'has-error': f.submitted && !city.valid }">
                    <input type="text" placeholder="City*" class="form-control" name="city"
                      [(ngModel)]="user_detail.primary_address.city" #city="ngModel" required />
                  </div>
                  <div *ngIf="f.submitted && !city.valid" class="has-error">
                    <span class="help-block">City is required</span>
                  </div>
                </div>
                <div class="col">
                  <div class="input-group mt-3" [ngClass]="{ 'has-error': f.submitted && !postalCode.valid }">
                    <input type="text" placeholder="{{ getzipname() }}" class="form-control" name="postalCode"
                      [(ngModel)]="user_detail.primary_address.postal_code" #postalCode="ngModel"
                      pattern="{{ user_detail.primary_address.country == 'CA' ? '(^[ABCEGHJKLMNPRSTVXYabceghjklmnprstvxy]\\d[ABCEGHJKLMNPRSTVWXYZabceghjklmnprstvwxyz]( )?\\d[ABCEGHJKLMNPRSTVWXYZabceghjklmnprstvwxyz]\\d$)' : '(^\\d{5}(-\\d{4})?$)' }}" />
                  </div>
                  <div *ngIf="f.submitted && !postalCode.valid" class="has-error">
                    <span *ngIf="postalCode?.errors?.pattern" class="help-block">{{ user_detail.primary_address.country
                      == 'CA' ? "Postal Code" : "Zip code" }}
                      is invalid.</span>
                  </div>
                </div> -->
              </div>
              <div class="row">
                <div class="col">
                  <div class="input-group mt-3" [ngClass]="{ 'has-error': f.submitted && !telephone.valid }">
                    <p-inputMask class="p-input-form-control" styleClass="form-control" mask="(999) 999-9999"
                      unmask="true" name="telephone" [(ngModel)]="user_detail.tel_no" #telephone="ngModel"
                      placeholder="(999) 999-9999">
                    </p-inputMask>
                    <!-- <input type="text" placeholder="Telephone number" class="form-control" name="telephone" [(ngModel)]="user_detail.mobile_no" #telephone="ngModel"
                      required /> -->
                  </div>
                  <div *ngIf="f.submitted && !telephone.valid" class="has-error">
                    <span class="help-block">Mobile number should be valid</span>
                  </div>
                </div>
                <div class="col"></div>
                <!-- <div class="col">
                  <div class="input-group mt-3" [ngClass]="{ 'has-error': f.submitted && !gender.valid }">
                    <select name="gender" class="form-control" [(ngModel)]="user_detail.gender" #gender="ngModel">
                      <option value="">--Select Gender--</option>
                      <option value="M">Male</option>
                      <option value="F">Female</option>
                    </select>
                  </div>
                </div> -->
              </div>
              <div class="row">
                <div class="col">
                  <div class="input-group mt-3" [ngClass]="{ 'has-error': f.submitted && model.kindOfSale == '' }">
                    <select name="kindOfSale" class="form-control" [(ngModel)]="model.kindOfSale" required>
                      <option value="">Select from the list the type of sale event you are organizing*</option>
                      <option *ngFor="let saleop of kindOfSaleOptions" value="{{saleop}}">
                        {{saleop}}
                      </option>
                    </select>
                  </div>
                  <div *ngIf="f.submitted && model.kindOfSale == ''" class="has-error">
                    <span class="help-block">Type of sale is required</span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="input-group mt-3">
                    <p-checkbox name="chkOpt1RegEmailLetters" label={{opt1RegEmailLettersLabel}} [(ngModel)]="model.opt1RegEmailLetters" binary="true"></p-checkbox>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="input-group mt-3">
                    <p-checkbox name="chkOpt2NotifyOfSale" label={{opt2NotifyOfSaleLabel}} [(ngModel)]="model.opt2NotifyOfSale" binary="true"></p-checkbox>
                  </div>
                </div>
              </div>
              <!-- <div class="row">
                <div class="col">
                  <div class="input-group mt-3">
                    <span class="mr-2 custom-control-description ">I want to receive e-news, e-publications, e-letters,
                      offers, and articles on Yard, Garage, Moving or Other Sale Events.<strong
                        class="text-danger">*</strong></span>
                    <label class="switch switch-text switch-pill switch-primary">
                      <input class="switch-input" type="checkbox" name="opt1RegEmailLetters"
                        [(ngModel)]="model.opt1RegEmailLetters">
                      <span class="switch-label" data-off="No" data-on="Yes"></span>
                      <span class="switch-handle"></span>
                    </label>
                  </div>
                </div>
              </div> -->
              <!-- <div class="row">
                <div class="col">
                  <div class="input-group mt-3">
                    <span class="mr-2 custom-control-description ">Kindly notify me of ongoing sales in my area. <strong
                        class="text-danger">*</strong></span>
                    <label class="switch switch-text switch-pill switch-primary">
                      <input class="switch-input" type="checkbox" name="opt2NotifyOfSale"
                        [(ngModel)]="model.opt2NotifyOfSale">
                      <span class="switch-label" data-off="No" data-on="Yes"></span>
                      <span class="switch-handle"></span>
                    </label>
                  </div>
                </div>
              </div> -->
              <div class="row">
                <div class="col">
                  <div class="input-group mt-3">
                    <span class="mr-2 custom-control-description ">I have read the<button type="button" class="btn-link"
                        (click)="viewTermOfUse(termOfUse)">Terms and Agreement</button>and accept it. <strong
                        class="text-danger">*</strong></span>
                    <label class="switch switch-text switch-pill switch-primary">
                      <input class="switch-input" type="checkbox" name="is_agreement_accepted"
                        [(ngModel)]="user_detail.is_agreement_accepted">
                      <span class="switch-label" data-off="No" data-on="Yes"></span>
                      <span class="switch-handle"></span>
                    </label>
                    <h6 class="mt-1">Please read the terms and agreement document and shift button to Yes to Accept</h6>
                  </div>
                </div>
              </div>
              <div class="form-group align-items-center mt-1">
                <button [disabled]="loading || !user_detail.is_agreement_accepted"
                  class="btn btn-primary button">Register</button>
                <a [routerLink]="['/login']" class="btn btn-link withoutBg">Cancel</a>
                <p></p>
                <a [routerLink]="['/help-assist-set-up']" class="btn btn-link px-0 search-btn" target="_blank"> Need
                  help setting up your Event Sale? Click here</a>

              </div>
            </form>
            <ng-template class="modal-dialog" #termOfUse>
              <div class="modal-header">
                <h4 class="modal-title pull-left">Terms and Conditions</h4>
                <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body modal-lg" style="height: 450px;overflow-y: auto">
                <div class="col-xs-12">

                  <h5>Rechoz Ventures Inc</h5>
                  <p>Universal Terms of Service Agreement (last Revised Jan 27<sup>th</sup> 2024)</p>
                  <p><strong>PLEASE READ THIS UNIVERSAL TERMS OF SERVICE AGREEMENT CAREFULLY, AS IT CONTAINS IMPORTANT
                      INFORMATION REGARDING YOUR LEGAL RIGHTS AND REMEDIES.</strong></p>
                  <ol style="padding-left:15px; font-size: medium; font-weight: 500">
                    <li style="font-variant-caps: small-caps;">Overview</li>
                  </ol>
                  <p style="text-align: justify; padding-left: 45px">This Universal Terms of Service Agreement (this
                    &ldquo;Agreement&rdquo;) is entered into by and
                    between Rechoz Ventures Inc, LLC, a corporation incorporated in Ontario ("Rechoz Ventures Inc") and
                    you, and is made effective as of the date of your use of this website ("Site") or the date of
                    electronic acceptance. This Agreement sets forth the general terms and conditions of your use of the
                    Site and the products and services purchased or accessed through this Site (individually and
                    collectively, the &ldquo;Services&rdquo;), and is in addition to (not in lieu of) any specific terms
                    and conditions that apply to the particular Services.</p>
                  <p style="text-align: justify; padding-left: 45px">The terms &ldquo;we&rdquo;, &ldquo;us&rdquo; or
                    &ldquo;our&rdquo; shall refer to Rechoz Ventures
                    Inc. The terms &ldquo;you&rdquo;, &ldquo;your&rdquo;, &ldquo;User&rdquo; or &ldquo;customer&rdquo;
                    shall refer to any individual or entity who accepts this Agreement, has access to your account or
                    uses the Services. Nothing in this Agreement shall be deemed to confer any third-party rights or
                    benefits.</p>
                  <ol style="padding-left:15px; font-size: medium; font-weight: 500" start="2">
                    <li style="font-variant-caps: small-caps;">Modification Of Agreement, Site Or Services</li>
                  </ol>
                  <p style="text-align: justify; padding-left: 45px">Rechoz Ventures Inc may, in its sole and absolute
                    discretion, change or modify this Agreement, and
                    any policies or agreements which are incorporated herein, at any time, and such changes or
                    modifications shall be effective immediately upon posting to this Site. Your use of this Site or the
                    Services after such changes or modifications have been made shall constitute your acceptance of this
                    Agreement as last revised. If you do not agree to be bound by this Agreement as last revised, do not
                    use (or continue to use) this Site or the Services. In addition, Rechoz Ventures Inc may
                    occasionally notify you of changes or modifications to this Agreement by email. Rechoz Ventures Inc
                    assumes no liability or responsibility for your failure to receive an email notification if such
                    failure results from an inaccurate email address. In addition, Rechoz Ventures Inc may terminate
                    Your use of Services for any violation or breach of any of the terms of this Agreement by You.
                    RECHOZ VENTURES INC RESERVES THE RIGHT TO MODIFY, CHANGE, OR DISCONTINUE ANY ASPECT OF THIS SITE OR
                    THE SERVICES, INCLUDING WITHOUT LIMITATION PRICES AND FEES FOR THE SAME, AT ANY TIME.</p>
                  <ol style="padding-left:15px; font-size: medium; font-weight: 500" start="3">
                    <li style="font-variant-caps: small-caps;">Your Account</li>
                  </ol>
                  <p style="text-align: justify; padding-left: 45px">In order to access some of the features of this
                    Site or use some of the Services, you will have to
                    create an Account. You represent and warrant to Rechoz Ventures Inc that all information you submit
                    when you create your Account is accurate, current and complete, and that you will keep your Account
                    information accurate, current and complete. If Rechoz Ventures Inc has reason to believe that your
                    Account information is untrue, inaccurate, out-of-date or incomplete, Rechoz Ventures Inc reserves
                    the right, in its sole and absolute discretion, to suspend or terminate your Account. You are solely
                    responsible for the activity that occurs on your Account, whether authorized by you or not, and you
                    must keep your Account information secure, including without limitation your customer number/login,
                    password. Rechoz Ventures Inc will not be liable for any loss you incur due to any unauthorized use
                    of your Account.</p>
                  <ol style="padding-left:15px; font-size: medium; font-weight: 500" start="4">
                    <li style="font-variant-caps: small-caps;">General Rules Of Conduct</li>
                  </ol>
                  <p>You acknowledge and agree that:</p>
                  <ol type="I">
                    <li>Your use of this Site and the Services , including any content you submit, will comply with this
                      Agreement and all applicable local, state, national and international laws, rules and regulations.
                    </li>
                    <li>You will not collect or harvest (or permit anyone else to collect or harvest) any User Content
                      (as defined below) or any non-public or personally identifiable information about another User or
                      any other person or entity without their express prior written consent.</li>
                    <li>You will <strong>NOT use this Site</strong> or the Services in a manner (as determined by Rechoz
                      Ventures Inc in its sole and absolute discretion) that:
                      <ol type="a">
                        <li>Is illegal, or promotes or encourages illegal activity;</li>
                        <li>Promotes, encourages or engages in child pornography or the exploitation of children;</li>
                        <li>Promotes, encourages or engages in terrorism, violence against people, animals, or property;
                        </li>
                        <li>Promotes, encourages or engages in any spam or other unsolicited bulk email, or computer or
                          network hacking or cracking;</li>
                        <li>That violates the Fight Online Sex Trafficking Act of 2017 or similar legislation, or
                          promotes or facilitates prostitution and/or sex trafficking;</li>
                        <li>That infringe on the intellectual property rights of another User or any other person or
                          entity;</li>
                        <li>Violates the privacy or publicity rights of another User or any other person or entity, or
                          breaches any duty of confidentiality that you owe to another User or any other person or
                          entity;</li>
                        <li>Interferes with the operation of this Site or the Services found at this Site;</li>
                        <li>Contains or installs any viruses, worms, bugs, Trojan horses or other code, files or
                          programs designed to, or capable of, disrupting, damaging or limiting the functionality of any
                          software or hardware; or</li>
                        <li>Contains false or deceptive language, or unsubstantiated or comparative claims, regarding
                          Rechoz Ventures Inc or Rechoz Ventures Inc&rsquo;s Services.</li>
                      </ol>
                    </li>
                    <li>You will not copy or distribute in any medium any part of this Site or the Services , except
                      where expressly authorized by Rechoz Ventures Inc.</li>
                    <li>You will not modify or alter any part of this Site or the Services found at this Site or any of
                      its related technologies.</li>
                    <li>You will not access Rechoz Ventures Inc Content (as defined below) or User Content through any
                      technology or means other than through this Site itself, or as Rechoz Ventures Inc may designate.
                    </li>
                    <li>You will not re-sell or provide the Services for a commercial purpose, including any of Rechoz
                      Ventures Inc&rsquo;s related technologies, without Rechoz Ventures Inc's express prior written
                      consent.</li>
                    <li>You will not to circumvent, disable or otherwise interfere with the security-related features of
                      this Site or the Services found at this Site (including without limitation those features that
                      prevent or restrict use or copying of any Rechoz Ventures Inc Content or User Content) or enforce
                      limitations on the use of this Site or the Services found at this Site, the Rechoz Ventures Inc
                      Content or the User Content therein.</li>
                  </ol>

                  <ol style="padding-left:15px; font-size: medium; font-weight: 500" start="5">
                    <li style="font-variant-caps: small-caps;">Consent and Privacy Policy</li>
                  </ol>
                  <p>If you use our Services, you consent to this Privacy Policy, including our Cookies Policy.</p>
                  <ol type="I">
                    <li>Consent:
                      <p>If you use any of our sites or services, you consent to the collection, use and sharing of your
                        data under this Privacy Policy (which includes our Cookie Policy and other documents referenced
                        in
                        this Privacy Policy) and agree to the User Agreement. Where applicable, we will provide you
                        choices
                        that allow you to opt-out or control how we use and share your data.</p>
                      <p>The only personal data we collect and store is information entered by you during your
                        registration.
                        We do not store information.&nbsp;</p>
                      <p>This Privacy Policy is subject to modification, if you do not consent to any future changes you
                        are
                        under no obligation to keep your account open.</p>
                    </li>
                    <li>Registration Data:
                      <p>To create an account you provide data which includes City of residence, Country of residence,
                        Province / State of residence and associated Postal Code / Zip Code</p>
                    </li>
                    <li>Cookies, Web Beacons and Other Similar Technologies:
                      <p>We collect data through cookies and similar technologies.</p>
                      <p>We use cookies and similar technologies to authenticate you and/or your device(s) on, off and
                        across different Services and devices. We also collect this data to improve the services we
                        provide
                        you.</p>
                    </li>
                    <li>Polls and surveys:
                      <p>Polls and surveys are conducted by us and others through our Services. You are under no
                        obligation
                        to respond to polls or surveys and you have choices about the information</p>
                    </li>
                    <li>Credit Card and payment information:
                      <p>All payments or donations are processed through a third party vendor; we collect information
                        from these
                        vendors
                        confirming the receipt of payment and the amount paid or donated. This information is used for
                        investigative, auditing and
                        financial reporting purposes.</p>
                    </li>
                    <li>System and Customer Support:
                      <p>We collect and review data for system improvement and maintenance. Data is collected to help
                        resolve customer issues and complaints.</p>
                    </li>
                    <li>Change to Policy:
                      <p>This policy is subject to change. We will notify you well in advance of any impending changes
                        to
                        this policy</p>
                    </li>
                  </ol>
                  <ol style="padding-left:15px; font-size: medium; font-weight: 500" start="6">
                    <li style="font-variant-caps: small-caps;">Prohibited Items</li>
                  </ol>
                  <p>If you use our Services, you consent to not use our site to promote your events which sell or
                    distribute the following prohibited items or services</p>
                  <ol type="I">
                    <li>Adult
                      <p>- Including pornography, nude photos, videos, articles, or erotic products.
                        - Including prostitution, escort, or accompanying services.
                      </p>
                    </li>
                    <li>Alcohol
                      <p>- Including the delivery of alcohol and related beverages
                        - Note: Selling empty bottles, labels, or caps as collectibles is permitted.
                        - Note: Recruitment of personnel such as bartender, front desk, or salesman is permitted in the
                        Jobs category.
                      </p>
                    </li>
                    <li>Baby Walkers</li>
                    <li>Baby Nests</li>
                    <li>Blood, Bodily Fluids, and Body Parts
                      <p>- Including organs
                        - Including surrogacy, fertility, and donor requests (including sperm)
                      </p>
                    </li>
                    <li>Burglary Tools</li>
                    <li>Cannabis and Cannabis-based Products
                      <p>- Including extracts, edibles, and oils
                        - Note: Hookahs, pipes, bongs, and vaporizers are permitted.
                      </p>
                    </li>
                    <li>Car Seats and Booster Seats (manufactured prior to January 1st, 2012)</li>
                    <li>Child Adoption services (seeking and offering)</li>
                    <li>Combustible Items (including flares)
                      <p>- Note: Emergency and distress flares are permitted only if they are designed and intended for
                        signaling purposes.
                      </p>
                    </li>
                    <li>Counterfeit Items and Distribution of Copyrighted Material
                      <p>
                        - Including currency, stamps, and coins
                        - Including e-books
                      </p>
                    </li>
                    <li>Digital Items or Accounts
                      <p>
                        - Including gaming accounts, accounts with multiple device allowances, and digital copies of
                        movies, books, or software
                        - Click here for more details on our digital items and accounts policy
                      </p>
                    </li>

                    <li>Embargoed Goods</li>

                    <li>Event Tickets
                      <p>
                        - Note: Certain community events tickets are permitted.
                        - Click here for more details on our event ticket sales policy
                      </p>
                    </li>
                    <li>Government Issued IDs, Badges, Uniforms and Licenses
                      <p>
                        - Including transit, military, police, postal, etc.
                      </p>
                    </li>

                    <li>Hazardous Materials
                      <p>
                        - Including chemicals, restricted, suspended or cancelled pest control products, and fireworks
                      </p>
                    </li>

                    <li>High-Powered Laser Pointers</li>

                    <li>Identity Documents, Personal Financial Records, and Personal Information
                      <p>
                        - In any form, including mailing lists
                      </p>
                    </li>

                    <li>Illegal Drugs and Drug Paraphernalia (or any mention of related items or activities)
                      <p>
                        - Including narcotics, steroids, etc.
                      </p>
                    </li>
                    <li>Illegal Items and Services
                      <p>
                        - Including any device that is primarily useful for the covert interception of private
                        communications
                        - Including listings or products that promote the use of photographic devices to covertly view
                        or record individuals for sexual purposes
                      </p>
                    </li>

                    <li>Illegal or Unauthorized Wildlife Products
                      <p>
                        - Including bear traps (regardless of size)
                      </p>
                    </li>

                    <li>Inclined Sleepers (recalled or not)</li>

                    <li>Ivory or Bone
                      <p>
                        - Including fossilized mammoth tusks
                        - Note: Antiques that contain 5% or less of real ivory and were made before the year 1900,
                        specifically musical instruments and furniture, are permitted. The year the item was
                        manufactured as well as an image must be included in the ad.
                        - Note: Bone, including fossilized teeth or bone, from non-ivory producing animals is permitted.
                        The species must be indicated in the ad.
                      </p>
                    </li>

                    <li>Knives
                      <p>
                        - Note: Collectable knives and swords (unsharpened, fantasy/antique) and kitchen utensils and
                        cutlery are permitted.
                      </p>
                    </li>
                    <li>Lottery Tickets, Sweepstakes Entries, and Coin Operated Slot Machines</li>
                    <li>Massage Services
                      <p>
                        - Ad images are to be limited to head shots or photographs of the facility (no full body images)
                        - Note: Ads posted by a Registered Massage Therapist (RMT) who is able to provide insurance
                        receipts for customers is permitted.
                        - Note: Massage as part of an aesthetics package by a registered Aesthetician, but not offered
                        on its own, is permitted.
                      </p>
                    </li>

                    <li>Offensive and Obscene Material
                      <p>
                        - Including Nazi related artifacts and collectibles
                        - Note: This excludes stamps, letters, and envelopes with the Nazi symbol, currency issued by
                        the Nazi government, military scripts, and historically accurate WWII military model kits that
                        have Nazi symbols.
                      </p>
                    </li>
                    <li>Prescription Drugs and Devices
                      <p>
                        - Note: Continuous Positive Airway Pressure (CPAP), Bilevel Positive Airway Pressure (BiPAP)
                        devices, and Variable Positive Airway Pressure (VPAP) devices and accessories require a
                        prescription and are therefore not permitted. Over the counter (OTC) versions of these devices
                        and accessories are permitted
                      </p>
                    </li>
                    <li>Products That Are Unauthorized by U.S. Department of Health and Human Services or Health Canada,
                      depending on your residency. </li>
                    <li>Radio Equipment or Other Devices That Violate the Radiocommunication Act.</li>
                    <li>Recalled Items.</li>
                    <li>Restricted Food Products
                      <p>
                        - Including, but not limited to:
                        - Unpasteurized dairy products
                        - Fruit or vegetable juice that hasn't been heat pasteurized
                        - Wild mushrooms
                        - Ackee fruit (including seeds)
                        - Food items that are subject to recall by the U.S. Food and Drug Administration or Canadian
                        Food Inspection Agency.
                        - Expired food items and baby formula
                        - Food items or baby formula that has been opened or tampered with
                        - Meals Ready to Eat (MREs) that have been expired for more than 3 years
                        - Government assistance benefits including, but not limited to:
                        - Special Supplemental Nutrition Program for Women, Infants, and Children (WIC)
                        - Supplemental foods such as infant formula and baby food
                        - Cash-value vouchers
                        - Food instruments such as vouchers, checks, electronic benefit transfer (EBT) cards, and
                        coupons
                        - Note: Perishable items are permitted; however, the sale of food items is highly regulated by
                        the federal, state, (or provincial) governments. When you list any food products, you are
                        certifying that the item may be sold and complies with all health, safety, and labelling
                        requirements. For more information, please visit U.S. Food and Drug Administration website for
                        US citizens and Health Canada website for Canadian citizens.
                      </p>
                    </li>
                    <li>Stocks and Other Securities
                      <p>
                        - Including binary options
                        - Note: For US citizens and residents, verify with the Securities and Exchange Commission. For
                        Canadian citizens and residents, On September 28th, 2017 the Canadian Securities Administrators
                        (CSA) announced a complete ban on the advertisement, offer, sale, or trade of binary options.
                      </p>
                    </li>
                    <li>Stolen Property</li>
                    <li>Tobacco and Other Nicotine Products
                      <p>
                        - Including e-cigarettes, e-juice, herbal blends, etc.
                        - Note: Hookahs, pipes, bongs, and vaporizers are permitted.
                        - Click here for more details on our tobacco product policies
                      </p>
                    </li>
                    <li>Traditional Drop-Side Cribs</li>
                    <li>Used Cosmetics</li>
                    <li>Weapons (or any mention of related items, services or activities)
                      <p>
                        - Including, but not limited to:
                        - Firearms
                        - Firearm parts and magazines
                        - Ammunition
                        - BB and pellet guns
                        - Replica weapons
                        - Tear gas
                        - Stun guns
                        - Crossbows
                        - Martial arts weapons
                        - Including Airsoft guns, parts, and ammunition
                        - Note: Archery and paintball equipment are permitted.

                      </p>
                  </ol>
                </div>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>