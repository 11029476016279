<style>
    .header2Pt {
        background-color: rgb(240, 244, 244);
        color: black;
        border: 2px solid black;
        margin: 20px;
        padding: 20px;
    }

    .header2PtA {
        background-color: rgb(179, 204, 238);
        color: black;
        border: 2px solid black;
        margin: 20px;
        padding: 20px;
    }

    .header3ptB {
        background-color: rgb(179, 204, 238);
        color: black;
        border: 4px solid black;
        margin: 20px;
        padding: 20px;

    }

    .header4ptword {
        background-color: white;
        color: black;
        border: 4px solid black;
        margin: 15px;
        padding: 15px;
    }
</style>


<div class="animated fadeIn infoBox col-8 mx-auto">
    <div class="row">
        <div class="col-lg-12 customLG">
            <div class="card">
                <div class="card-header">
                    <!-- <i class="fa fa-info"></i> -->
                    <header id="Heading1">
                        <h1>Reviews and Gratuity </h1>
                    </header>

                </div>
                <div class="card-body text-justify">
                    <p>
                        <a [routerLink]='"."' [fragment]='"FreewillPay"'>Donations, Gratuities, Tips</a>
                    </p>
                    <div class="header2PtA">
                        <header id="Heading2">
                            <h2>Reviews</h2>
                        </header>
                    </div>

                    <div class="header2Pt">
                        <header id="Reviews">
                            <h4>We Value Your Feedback!</h4>
                        </header>

                        <p>
                            We strive to provide the best experience for our users, and your feedback is crucial to our
                            improvement.
                            If you found yardgaragemovingsales.com of benefit to you, we'd love to hear about it! Your
                            reviews help us understand what we're doing right and where we can make enhancements.
                        </p>
                        <p>
                            Please take a moment to share your thoughts by leaving a review on any of the social media
                            platform:
                        </p>
                        <p>
                            <a href="https://www.facebook.com/YardGarageMovingSales/" target="_blank">Facebook:</a>
                            <a href="https://www.facebook.com/YardGarageMovingSales/"
                                target="_blank">https://www.facebook.com/YardGarageMovingSales/</a>

                        </p>
                        <p>
                            OR
                        </p>
                        <p>
                            <a href="https://yardgaragemovingsalessspace.quora.com/" target="_blank">Quora:</a>
                            <a href="https://yardgaragemovingsalessspace.quora.com/"
                                target="_blank">https://yardgaragemovingsalessspace.quora.com/</a>
                        </p>
                        <p>
                            Whether it's a suggestion, compliment, or constructive criticism, your input is highly
                            appreciated.
                            You can also send us an email message directly at
                            <a href="mailto:info@YardGarageMovingSales.com">info@YardGarageMovingSales.com</a>
                        </p>
                        <p>
                            Thank you for being a part of yardgaragemovingsales.com
                            Best regards,
                            The yardgaragemovingsales.com Team
                            Rechoz Ventures Inc.
                        </p>
                    </div>
                    <div class="header2PtA">
                        <header id="FreewillPay">
                            <h2>We ask you, voluntarily, to assist in the upkeep and growth of our community-based
                                site, yardgaragemovingsales.com</h2>
                        </header>
                    </div>
                    <div class="header2Pt">
                        <header id="Support">
                            <h4>Keeping yardgaragemovingsales.com charge free</h4>
                        </header>

                        <p>
                            Thank you for using our site to advertise your event, we hope you found
                            yardgaragemovingsales.com useful in promoting your sale event.
                            We are currently self-funded, we rely on freewill
                            contributions from users like you to assist us in
                            keeping this site up and running.
                        </p>
                    </div>

                    <div class="header3ptB">
                        <header id="PmtProcess">
                            <h4>Processing Section </h4>
                        </header>
                    </div>
                    <div class="header4ptword">
                        <h6>Any gratuity is greatly appreciated. We intend on keeping yardgaragemovingsales.com charge free.
                            No amount is too small and every cent counts.
                            <p>
                                Your free will contributions can be sent through any of the following third-party
                                vendors:
                            </p>

                        </h6>
                        <p>

                        </p>

                        <p>
                            Through PayPal:<a href="https://www.paypal.com/donate/?hosted_button_id=SUKGNJH9NWBBC"
                                target="_blank">https://www.paypal.com/donate/?hosted_button_id=SUKGNJH9NWBBC</a>
                        </p>
                        <p>
                            Through Square:<a href="https://square.link/u/1rCoeynl"
                                target="_blank">https://square.link/u/1rCoeynl</a>
                        </p>

                        <p>
                            Through Email Electronic Transfer (eTransfer Canada Only)
                            payforward@yardgaragemovingsales.com
                        </p>



                    </div>
                    <div class="header2Pt">
                        <p>
                            If you have any suggestions or feedback, kindly feel free to send a note to
                            <a href="mailto:info@YardGarageMovingSales.com">info@YardGarageMovingSales.com</a>
                        </p>
                        <p>
                            Thank you for your contribution.
                        </p>
                        <p>
                            Management,
                            Rechoz Ventures Inc.
                        </p>
                    </div>
                    <p>
                        <a [routerLink]='"."' [fragment]='"Reviews"'>Feedback and Reviews</a>
                    </p>

                </div>
            </div>
        </div>
    </div>
</div>