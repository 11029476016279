import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { KeyFilterPipe } from '../_pipe/key.pipe';
import { ModalModule, BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ProductComponent } from './product/product.component';
import { ProductListComponent } from './product-list/product-list.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { WeatherWidgetComponent } from './weather-widget/weather-widget.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AdBannerComponent } from './ad-banner/ad-banner.component';
import {CarouselModule} from 'primeng/carousel';

@NgModule({
 imports:      [ CommonModule, ModalModule.forRoot(), TooltipModule, CarouselModule ],
 declarations: [ KeyFilterPipe, ProductComponent, ProductListComponent, WeatherWidgetComponent, AdBannerComponent ],
 exports:      [ KeyFilterPipe,
                 CommonModule, FormsModule, ProductComponent, ProductListComponent, TooltipModule, WeatherWidgetComponent, AdBannerComponent],
 providers:[BsModalService, BsModalRef]
})
export class SharedModule { }
