<div class="app flex-row">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-11">
        <div class="card-group card-group-noshadow mb-0">
          <div class="card p-2">
            <div class="card-body">
              <div class="button-box">
                <div class="button-box-icon">
                  <i class="icon-cursor"></i>
                </div>
                <div class="button-box-title">
                  Broadcast
                </div>
                <button [routerLink]="['/event/location']" class="btn btn-primary btn-lg btn-block button"
                  type="button">Yard/Garage/Moving Sale
                </button>
              </div>
            </div>
          </div>
          <div class="card p-2" style="width:100%">
            <div class="card-body text-center">
              <div class="button-box">
                <div class="button-box-icon">
                  <i class="icon-magnifier"></i>
                </div>
                <div class="button-box-title">
                  Search
                </div>
                <button [routerLink]="['/search-sale']" class="btn btn-primary btn-lg btn-block button"
                  type="button">Yard/Garage/Moving Sale
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-11">
        <div class="card-group card-group-noshadow mb-0">
          <div class="card p-2">
            <div class="card-body">
              <div class="video-box">
                <div class="video-box-title">
                  Are you planning a Yard, Garage or Moving Sale?
                </div>
                <iframe width="410" height="190" [src]="videoUrl1" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
              </div>
            </div>
          </div>
          <div class="card p-2" style="width:100%">
            <div class="card-body text-center">
              <div class="video-box">
                <div class="video-box-title">
                  Benefits of having a Yard, Garage or Moving Sale.
                </div>
                <iframe width="410" height="190"  [src]="videoUrl2" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
              </div>
            </div>
          </div>
        </div>
        <app-ad-banner></app-ad-banner>
      </div>
    </div>
  </div>
</div>