import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { AlertService, UserService, AuthenticationService } from '../_services/index';
import { CurrentUser, User, UserType } from '../_models/index';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  moduleId: module.id.toString(),
  templateUrl: 'resetpassword.component.html'
})
export class ResetPasswordComponent implements OnInit {
  model: User;
  currentUser: CurrentUser;
  loading = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private alertService: AlertService,
    private authService: AuthenticationService
  ) {
    this.model = {
      email: '',
      password: '',
      reset_token: '',
      user_type: UserType.Admin,
      user_details: null,
      kindOfSale: '',
      opt1RegEmailLetters: false,
      opt2NotifyOfSale: false
    };
  }

  get IsAuthenticated(): boolean {
    return this.authService.isAuthenticated();
  }

  ngOnInit() {
    // Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    // Add 'implements OnInit' to the class.
    // this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    // tslint:disable-next-line:max-line-length
    this.model.email = this.IsAuthenticated ? this.userService.sessionUser.email : decodeURIComponent(this.route.snapshot.queryParams.email);
    this.model.reset_token = this.route.snapshot.queryParams.token;
  }
  fpassword() {
    this.loading = true;
    this.userService
      .resetPassword(this.model)
      .subscribe(
        data => {
          // alert(JSON.stringify(data));
          this.loading = false;
          this.alertService.success(data.message, true);
          if (this.IsAuthenticated) {
            this.router.navigate(['/home']);
          } else {
            this.router.navigate(['/login']);
          }
        },
        (error: HttpErrorResponse) => {
          console.log(`Error: ${error}`);
          this.alertService.error(error.error.message);
          this.loading = false;
        }
      );
  }
}
