<div role="document">
  <div class="col-md-12 theme-new-green">
    <form name="form" (ngSubmit)="f.form.valid && saveItem()" #f="ngForm" novalidate>
      <div class="modal-header">
        <h4 class="modal-title pull-left">{{ title }}</h4>
        <button type="button" class="close pull-right" aria-label="Close" [disabled]="loading" (click)="onClose()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <!-- <div class="row adjust-for-add-popup">
          <pre>{{ itemResult | json}}</pre>
        </div> -->
        <div class="row adjust-for-add-popup">

          <div class="col-sm-4">
            <div class="form-group">
              <label title="Enter a short name for the Item which you wish to sell." class="col-sm-12 control-label"
                for="textinput" title="Enter the Name of Item">Item
                Name</label>
              <div class="col-sm-12" [ngClass]="{ 'has-error': f.submitted && !itemName.valid }">
                <div class="input-group">
                  <input type="text" name="item_name" #itemName="ngModel" placeholder="Enter Item Name"
                    [(ngModel)]="itemResult.item.name" class="form-control" required />
                </div>
                <div *ngIf="f.submitted && !itemName.valid" class="has-error">
                  <span class="help-block">Item Name is required</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="form-group">
              <label title="Choose or enter one category or group for your Item. *Required*"
                class="col-sm-12 control-label">Cateogry</label>
              <div class="col-sm-12" [ngClass]="{ 'has-error': f.submitted && !itemResult.item.category }">
                <ng-select name="categories" [items]="categories" bindLabel="name" bindValue="name"
                  [(ngModel)]="itemResult.item.category" required>
                </ng-select>
                <div *ngIf="f.submitted && !itemResult.item.category" class="has-error">
                  <span class="help-block">Category is required</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="form-group">
              <label
                title="Choose one or more additional categories or groups (Max 5) which further describes your item. *Optional*"
                class="col-sm-12 control-label">Sub-Cateogry</label>
              <div class="col-sm-12"
                [ngClass]="{ 'has-error': f.submitted && (!itemResult.item.associated_sub_category || itemResult.item.associated_sub_category.length === 0) }">
                <ng-select name="subCategories"
                  [items]="subCategories | KeyFilter: 'category_name' : itemResult.item.category" bindValue="name"
                  bindLabel="name" [addTag]="addTagNowRef" [multiple]="true" [hideSelected]="true"
                  [(ngModel)]="itemResult.item.associated_sub_category">
                </ng-select>
              </div>
            </div>
          </div>
        </div>
        <div class="row adjust-for-add-popup">
          <div class="col-sm-4" *ngIf="item_kind === 'sale'">
            <div class="form-group">
              <label title="Enter a preferred sale price or cost for the Item" class="col-sm-12 control-label">Cost or
                Price</label>
              <div class="col-sm-12" [ngClass]="{ 'has-error': f.submitted && !cost.valid }">
                <div class="input-group">
                  <input type="text" name="cost" #cost="ngModel" (change)="transformAmount($event)" placeholder="Cost"
                    [(ngModel)]="itemResult.item.formattedCost" class="form-control" required />
                </div>
                <div *ngIf="f.submitted && !cost.valid" class="has-error">
                  <span class="help-block">Cost is required</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-8">
            <div class="form-group">
              <label
                title="If you have additional notes or comments you wish to add which describes the item which you are selling."
                class="col-sm-12 control-label" for="">Notes or Comments</label>
              <div class="col-sm-12">
                <textarea rows="2" cols="50" name="notes"
                  placeholder="Enter notes, comments  or short description of the item." [(ngModel)]="itemResult.item.notes"
                  class="form-control">
                </textarea>
              </div>
            </div>
          </div>
        </div>
        <div class="row adjust-for-add-popup">
          <div class="col-sm-12">
            <div class="form-group mb-0">
              <label
                title="Choose a picture to be uploaded with the Item File type (.jpg,.png,.gif,.jpe) file size should be less than 20.00 MB"
                class="col-sm-12 control-label">Upload picture</label>
              <div class="col-sm-12">
                <angular-file-uploader #fileUpload [config]="afuConfig1" (ApiResponse)="DocUpload($event)" class="afuc">
                </angular-file-uploader>
              </div>
            </div>
          </div>
        </div>

        <div class="row adjust-for-add-popup">
          <div class="col-sm-12">
            <div class="form-group">
              <div class="col-sm-12">
                <gallery *ngIf="images.length > 0" id="gallerize-ex" gallerize [items]="images" [edit]="true"
                  (deleteClick)="onDeleteClick($event)"></gallery>
                <!-- <pre>
                  {{ itemResult | json}}
                </pre> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="form-group col-md-12 align-items-center mt-1">
          <button tooltip="Click here to Save your entries" class="btn btn-primary" [disabled]="loading">Save</button>
          <button type="button" tooltip="Click here to close" class="btn default-hollow-btn"
            [disabled]="loading" data-dismiss="modal" (click)="onClose()">
            Close
          </button>
        </div>
      </div>
    </form>
  </div>
</div>