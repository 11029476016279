import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ad-banner',
  templateUrl: './ad-banner.component.html',
  styleUrls: ['./ad-banner.component.scss']
})
export class AdBannerComponent implements OnInit {

  ads: { adLink: string, imagePath: string, label: string, imageWidth:string, imageHeight:string }[] = [
     { "adLink": "https://76866vppm657quibnbqgv6lnfh.hop.clickbank.net/?&traffic_source=YargarSite&traffic_type=paid&campaign=Aug2024&creative=c1&ad=Ag2SearchPage", "imagePath": "../assets/img/adverts/img/recprofit.png", "label": "recprofit:  Recession Profit Secrets V2 Ad", "imageWidth": "600", "imageHeight": "200" },
     { "adLink": "https://4ff43obio638tsj5o852ga3ked.hop.clickbank.net/?&traffic_source=YargarSite&traffic_type=paid&campaign=Aug2024&creative=b1&ad=Ag1HomePage", "imagePath": "../assets/img/adverts/img/Ikaria.png", "label": "lbjuice:  Ikaria Juice Ad","imageWidth": "600", "imageHeight": "200"  },
     { "adLink": "https://3a751ulhw83huhrxx-sd-8plqf.hop.clickbank.net/?&traffic_source=YargarSite&traffic_type=paid&campaign=Jun2024&creative=b1&ad=Ag1HomePage", "imagePath": "../assets/img/adverts/img/brainwclub.png", "label": "brainwclub: Brainwave Ad","imageWidth": "600", "imageHeight": "200"  },
     { "adLink": "https://df633vhmnxwlwtgew3xzte3m8u.hop.clickbank.net/?&traffic_source=YargarSite&traffic_type=paid&campaign=Jun2024&creative=b1&ad=Ag1HomePage", "imagePath": "../assets/img/adverts/img/buildacontainerhome.png", "label": "imcoders: Build Container Home Ad","imageWidth": "600", "imageHeight": "200"  },
     { "adLink": "https://eb9c7ieci-xgumo1lmkhw6rfnp.hop.clickbank.net/?&traffic_source=YargarSite&traffic_type=paid&campaign=Aug2024&creative=b1&ad=Ag1HomePage", "imagePath": "../assets/img/adverts/img/diyhomeene.png", "label": "diyhomeene: DIY Home Energy Ad","imageWidth": "600", "imageHeight": "200"  },
     { "adLink": "https://447b8rmhv4uhygh22lj3x22ve4.hop.clickbank.net/?&traffic_source=YargarSite&traffic_type=Paid&campaign=Aug2024&creative=b1&ad=Ag1HomePage", "imagePath": "../assets/img/adverts/img/homedoc.png", "label": "homedoc: Home Doctor Ad","imageWidth": "600", "imageHeight": "200"  },
     { "adLink": "https://07811komo73bzjriqijxzlom17.hop.clickbank.net/?&traffic_source=YargarSite&traffic_type=paid&campaign=Aug2024&creative=b1&ad=Ag1HomePage", "imagePath": "../assets/img/adverts/img/JavaBurn.png", "label": "JavaBurn: JavaBurn Ad","imageWidth": "600", "imageHeight": "200"  },
     { "adLink": "https://84b15ldnv8vi2pobnn4i-39r0k.hop.clickbank.net/?&traffic_source=YargarSite&traffic_type=paid&campaign=Aug2024&creative=b1&ad=Ag1HomePage", "imagePath": "../assets/img/adverts/img/LivPure.png", "label": "livpure: Liv Pure Ad" ,"imageWidth": "600", "imageHeight": "200" },
     { "adLink": "https://43a28uddo6070hg9w3ygs5q0cd.hop.clickbank.net/?&traffic_source=YargarSite&traffic_type=Paid&campaign=Aug2024&creative=b1&ad=Ag1HomePage", "imagePath": "../assets/img/adverts/img/mmarriage.png", "label": "mmarriage: Mend the Marriage Ad","imageWidth": "600", "imageHeight": "200"  },
	   { "adLink": "https://4bd92pjksx6h2ni8mhvao58oe9.hop.clickbank.net/?&traffic_source=YargarSite&traffic_type=paid&campaign=Aug2024&creative=b1&ad=Ag1HomePage", "imagePath": "../assets/img/adverts/img/NaganoTonic.png", "label": "lbtonic: Nagano tonic Ad","imageWidth": "600", "imageHeight": "200"  },
	   { "adLink": "https://a6fe0lkcm6vhzsp3co8m3b3ztl.hop.clickbank.net/?&traffic_source=YargarSite&traffic_type=paid&campaign=Aug2024&creative=b1&ad=Ag1HomePage", "imagePath": "../assets/img/adverts/img/easywriter.png", "label": "easywriter: paidonlinewritingjobs.com Ad","imageWidth": "600", "imageHeight": "200"  },
     { "adLink": "https://57c90opiw84d0fjft3r5xdihjx.hop.clickbank.net/?&traffic_source=YargarSite&traffic_type=paid&campaign=Aug2024&creative=b1&ad=Ag1HomePage", "imagePath": "../assets/img/adverts/img/socialpaid.png", "label": "socialpaid: payingsocialmediajobs.com Ad","imageWidth": "600", "imageHeight": "200"  },
     { "adLink": "https://c9931rjjn6581rkirlw9mc4t92.hop.clickbank.net/?&traffic_source=YargarSite&traffic_type=paid&campaign=Jun2024&creative=b1&ad=Ag1HomePage", "imagePath": "../assets/img/adverts/img/Septifix.png", "label": "Septifix: Septifix Ad","imageWidth": "600", "imageHeight": "200"  },
     { "adLink": "https://3cc6fshkv9virjj6cod7ctarcq.hop.clickbank.net/?&traffic_source=yargarsite&traffic_type=paid&campaign=Aug2024&creative=b1&ad=Ag1HomePage", "imagePath": "../assets/img/adverts/img/j1r2c.png", "label": "j1r2c: John Crestani Autowebinar Ad","imageWidth": "600", "imageHeight": "200"  },
     { "adLink": "https://8982cmqqi3wipmomk01on41i16.hop.clickbank.net/?&traffic_source=YargarSite&traffic_type=paid&campaign=Jun2024&creative=b1&ad=Ag1HomePage", "imagePath": "../assets/img/adverts/img/Tedswoodworking.png", "label": "tedsplans: Tedswoodworking Ad","imageWidth": "600", "imageHeight": "200"  },
     { "adLink": "https://fc064uopk1ue3knomfq9jxyg0k.hop.clickbank.net/?&traffic_source=YargarSite&traffic_type=paid&campaign=Jun2024&creative=b1&ad=Ag1HomePage", "imagePath": "../assets/img/adverts/img/VinCheckUp.png", "label": "vincheckup: VIN Check UP Ad","imageWidth": "600", "imageHeight": "200"  },
	   { "adLink": "https://fc9eainmu30kwftdyhs7omap3d.hop.clickbank.net/?&traffic_source=YargarSite&traffic_type=paid&campaign=Jun2024&creative=b1&ad=Ag1HomePage", "imagePath": "../assets/img/adverts/img/vipindicators.png", "label": "fxlife: VIP Indicators Ad","imageWidth": "600", "imageHeight": "200"  },
     { "adLink": "https://fa51apcdh85bzhirh9sow6svdj.hop.clickbank.net/?&traffic_source=YargarSite&traffic_type=paid&campaign=Aug2024&creative=b1&ad=Ag1HomePage", "imagePath": "../assets/img/adverts/img/wealthdna.png", "label": "wealthdna: Wealth DNA Code Ad","imageWidth": "600", "imageHeight": "200"  },
     { "adLink": "https://9c6cfrdnqv0kpjqsi5xovmv79n.hop.clickbank.net/?&traffic_source=YargarSite&traffic_type=paid&campaign=Aug2024&creative=b1&ad=Ag1HomePage", "imagePath": "../assets/img/adverts/img/wealthsig.png", "label": "wealthsig: Wealth Signal Ad","imageWidth": "600", "imageHeight": "200"  },
     { "adLink": "https://dfb86nejq6ygujgux4wcs8zetd.hop.clickbank.net/?&traffic_source=YargarSite&traffic_type=paid&campaign=Jun2024&creative=b1&ad=Ag1HomePage", "imagePath": "../assets/img/adverts/img/whatliesin.png", "label": "whatliesin: What Lies in your Debt","imageWidth": "600", "imageHeight": "200"  }
  ]
  ;
  constructor() {

  }

  ngOnInit(): void {
  }

}
