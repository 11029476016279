import { Injectable } from '@angular/core';
import {
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivateChild
} from '@angular/router';
import { AuthenticationService } from '../_services/index';

@Injectable()
export class RoleGuard implements CanActivateChild {
  constructor(
    private router: Router,
    private authService: AuthenticationService
  ) {}

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const loggedInUser = this.authService.sessionUser;
    console.log(`Loggendin: ${JSON.stringify(loggedInUser)}`);

    if (childRoute.data.expectedRole) {
      const expectedRole = childRoute.data.expectedRole;

      if (
        this.authService.isAuthenticated() &&
        expectedRole.includes(loggedInUser.user_type)
      ) {
        // logged in so return true
        return true;
      } else {
        // // not logged in so redirect to login page with the return url
        this.router.navigate(['/noaccess']);
        return false;
      }
    } else {
      return true;
    }
  }
}
