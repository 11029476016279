<div class="product theme-new-green">
    <div class="image-box">
        <div class="giveaway-badge">
            <span *ngIf="isForGiveAway" class="badge badge-pill badge-secondary">Give Away</span>
            <span *ngIf="displayReviewStatus"
                [ngClass]="{true: 'badge badge-pill badge-warning', false: 'badge badge-pill badge-secondary'}[product.approval_status == 'Pending']">{{product.approval_status
                == 'Pending' ? 'Under Review' : 'Reviewed' }}</span>
        </div>
        <div class="images">
            <img class="image" [src]="product.pictures[0]" />
        </div>
    </div>
    <div class="text-box">
        <div class="edit-bar" *ngIf="isEditable">
            <button title="Edit" type="button" (click)="editProduct()">
                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="18" height="18" viewBox="0 0 24 24"
                    style=" fill:#ffffff;">
                    <path d="M 18.414062 2 C 18.158188 2 17.902031 2.0974687 17.707031 2.2929688 L 16 4 L 20 8 L 21.707031 6.2929688 C 22.098031 
                        5.9019687 22.098031 5.2689063 21.707031 4.8789062 L 19.121094 2.2929688 C 18.925594 2.0974687 18.669937 2 18.414062 2 z M 14.5 
                        5.5 L 3 17 L 3 21 L 7 21 L 18.5 9.5 L 14.5 5.5 z">
                    </path>
                </svg>
            </button>
        </div>
        <div class="delete-bar" *ngIf="isEditable">
            <button title="Delete" type="button" (click)="deleteProduct()">
                <svg class="svg-icon" viewBox="0 0 20 20">
                    <path
                        d="M17.114,3.923h-4.589V2.427c0-0.252-0.207-0.459-0.46-0.459H7.935c-0.252,0-0.459,0.207-0.459,0.459v1.496h-4.59c-0.252,0-0.459,0.205-0.459,0.459c0,0.252,0.207,0.459,0.459,0.459h1.51v12.732c0,0.252,0.207,0.459,0.459,0.459h10.29c0.254,0,0.459-0.207,0.459-0.459V4.841h1.511c0.252,0,0.459-0.207,0.459-0.459C17.573,4.127,17.366,3.923,17.114,3.923M8.394,2.886h3.214v0.918H8.394V2.886z M14.686,17.114H5.314V4.841h9.372V17.114z M12.525,7.306v7.344c0,0.252-0.207,0.459-0.46,0.459s-0.458-0.207-0.458-0.459V7.306c0-0.254,0.205-0.459,0.458-0.459S12.525,7.051,12.525,7.306M8.394,7.306v7.344c0,0.252-0.207,0.459-0.459,0.459s-0.459-0.207-0.459-0.459V7.306c0-0.254,0.207-0.459,0.459-0.459S8.394,7.051,8.394,7.306">
                    </path>
                </svg>
            </button>
        </div>
        <h2 class="item">{{ product.name }}</h2><label for="item-1-quantity">{{ product.category }}</label>

        <h3 class="price">{{ product.cost && !isForGiveAway ? (product.cost | currency) : "Free" }}</h3>
        <!-- Below fields needs to show/hide based on the page-->
        <p class="description">{{ product.notes? product.notes : '' }} </p>
    </div>
</div>

