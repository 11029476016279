import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Item } from 'src/app/_models';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {
  @Input() product: Item
  @Input() isForGiveAway: boolean
  @Input() isEditable: boolean;
  @Input() displayReviewStatus: boolean;
  
  @Output() onProductEdit: EventEmitter<Item>
  @Output() onProductDelete: EventEmitter<Item>
  constructor() {
    this.onProductEdit = new EventEmitter();
    this.onProductDelete = new EventEmitter();
  }

  ngOnInit(): void {
  }

  editProduct() {
    console.log('Edit product');
    this.onProductEdit.emit(this.product);
  }

  deleteProduct() {
    this.onProductDelete.emit(this.product);
  }
}
