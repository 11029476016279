import { Component, OnInit, ViewChild, ChangeDetectorRef, AfterViewInit, ElementRef, NgZone } from '@angular/core';
import { AgmGeocoder, AgmMap, LatLngBounds, GeocoderResult, MouseEvent, MapsAPILoader } from '@agm/core';
import { EventService, AnalyticService } from '../_services';
import { BroadcastEventDTO } from '../_models';
import * as $ from 'jquery';
import { uniqId } from '../_common/util';
import { ViewportScroller } from '@angular/common';
import { FormControl, Validators } from '@angular/forms';
declare var google: any;

@Component({
  selector: 'app-event-search',
  templateUrl: './event-search.component.html',
  styleUrls: ['./event-search.component.scss']
})
export class EventSearchComponent implements OnInit, AfterViewInit {
  constructor(private eventService: EventService,
    private analyticService: AnalyticService,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private geoCodeService: AgmGeocoder,
    private ref: ChangeDetectorRef) { }

  events: BroadcastEventDTO[];
  address = '';
  mapInstance: any;
  selectedRadius = 5000.0;
  GeoCodeCalcEarthRadius = 3956.0; // for km 6367.0, for mile 3956.0
  radioses = [{ "text": "1", "value": 1000 }, { "text": "2", "value": 2000 }, { "text": "5", "value": 5000 }, { "text": "10", "value": 10000 }];

  countryRestrict = { country: ['ca', 'us'] };
  radiusLocation: any;

  //public placeControl: FormControl;
  autocompletePlace: any;

  @ViewChild('place')
  public placeElementRef: ElementRef;


  @ViewChild('AgmMap') agmMap: AgmMap;
  private _vps: ViewportScroller;

  // center = { lat: 53.3034153, lng: -103.3367244 };
  center = { lat: 40.741770272127916, lng: -73.98871912583508 };

  openedWindow = ''; // alternative: array of numbers

  public resultViewType = "ListView";

  ngAfterViewInit(): void {
    console.log(this.agmMap);
    this.agmMap.mapReady.subscribe(map => {
      this.mapInstance = map;
      this.fitBound();
      // const bounds: LatLngBounds = new google.maps.LatLngBounds();
      // for (const mm of this.markers) {
      //   bounds.extend(new google.maps.LatLng(mm.lat, mm.lng));
      // }
      // map.fitBounds(bounds);
    });
  }

  private fitBound() {
    const bounds: LatLngBounds = new google.maps.LatLngBounds();
    // for (const mm of this.stores) {
    //   bounds.extend(new google.maps.LatLng(mm.latitude, mm.longitude));
    // }

    const centerSfo = new google.maps.LatLng(this.center.lat, this.center.lng);
    const circle = new google.maps.Circle({ radius: this.selectedRadius, center: centerSfo });
    const circleBounds = circle.getBounds();

    // bounds.extend(new google.maps.LatLng(this.center.lat, this.center.lng));
    bounds.union(circleBounds);
    this.mapInstance.fitBounds(bounds);
    this.ref.detectChanges();
  }

  openWindow(id: string) {
    console.log(`ID: ${id}`);
    var scrollTo = document.getElementById(id);
    scrollTo.scrollIntoView({ behavior: 'smooth' });
    //this._vps.scrollToAnchor(id);
    const selectedLocation = $(`.bh-sl-loc-list li[id='${id}']`);
    this.openedWindow = id; // alternative: push to array of numbers
    if (selectedLocation.length > 0) {
      console.log(`SELECTED Location: ${selectedLocation}`);
      $('.bh-sl-loc-list li').removeClass('list-focus');
      selectedLocation.addClass('list-focus');

      // Scroll list to selected marker
      const container = $('.bh-sl-loc-list');
      // container.animate({
      //   scrollTop: selectedLocation.offset().top - container.offset().top + container.scrollTop()
      // });



      // const elem = document.getElementById(id);
      // elem.className = "list-focus";
      // console.log(elem);
      // elem.scrollIntoView({ behavior: 'smooth' });
    }
  }

  isInfoWindowOpen(id) {
    return this.openedWindow === id; // alternative: check if id is in array
  }

  ngOnInit(): void {
    // create search FormControl
    //this.placeControl = new FormControl('', [Validators.required]);

    // load Places Autocomplete
    this.mapsAPILoader.load().then(() => {
      //   const autocompleteStart = new google.maps.places.Autocomplete(this.startElementRef.nativeElement, {
      //       types: ['address']
      //   });

      //   const autocompleteEnd = new google.maps.places.Autocomplete(this.endElementRef.nativeElement, {
      //       types: ['address']
      //   });
      this.autocompletePlace = new google.maps.places.Autocomplete(this.placeElementRef.nativeElement,
        {
          types: ['address'],
          componentRestrictions: this.countryRestrict
        });

      this.setupPlaceChangedListener(this.autocompletePlace);
      this.setCurrentLocation();
    });
  }

  private setupPlaceChangedListener(autocomplete: any) {
    autocomplete.addListener('place_changed', () => {
      this.ngZone.run(() => {
        // get the place result
        this.radiusLocation = autocomplete.getPlace();
        // verify result
        if (this.radiusLocation.geometry === undefined) {
          // Inform the user that a place was not found and return.
          alert('Place not found!!');
          return;
        }

        if($.isFunction(this.radiusLocation.geometry.location.lat))
        {
          this.center = 
          {
            lat: this.radiusLocation.geometry.location.lat(), 
            lng: this.radiusLocation.geometry.location.lng()
          };
        }
        else{
          this.center = 
          {
            lat: this.radiusLocation.geometry.location.lat, 
            lng: this.radiusLocation.geometry.location.lng
          };
        }

        // this.place_longitude = this.radiusLocation.geometry.location.lng();
        // this.place_latitude = this.radiusLocation.geometry.location.lat();
        // this.zoom = 12;

        console.log(`Place : ${JSON.stringify(this.radiusLocation)}`);

      });

    });
  }

  search() {
    this.getEvents();
    this.ref.detectChanges();
    this.analyticService.eventEmitter("eventsearch", "search", this.address);
  }

  // Get Current Location Coordinates
  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.center = { lat: position.coords.latitude, lng: position.coords.longitude };
        this.updateCenterPoint();
      });
    }
  }

  encodedUri(uri: string): string {
    return encodeURIComponent(uri);
  }

  private getEvents() {
    this.eventService.queryEventByRadius(this.center.lat, this.center.lng, this.selectedRadius)
      .subscribe(data => {
        data.forEach(loc => {
          loc.animation = 'DROP';
          loc.id = uniqId();
          // store.dest = store.address.replace(/_/g, ',');

          // tslint:disable-next-line:max-line-length
          const distance = this.geoCodeCalcCalcDistance(this.center.lat, this.center.lng, loc.latitude, loc.longitude, this.GeoCodeCalcEarthRadius);
          loc.distance = this.roundNumber(distance, 2);

          // // Alternative distance length unit
          // if (this.settings.lengthUnit === 'm') {
          //   // Miles to kilometers
          //   data.altdistance = parseFloat(data.distance)*1.609344;
          // } else if (this.settings.lengthUnit === 'km') {
          //   // Kilometers to miles
          //   data.altdistance = parseFloat(data.distance)/1.609344;
          // }

        });
        this.events = data;
        this.fitBound();
      });
  }

  markerOver(m: BroadcastEventDTO) {
    m.animation = 'BOUNCE';
  }

  markerOut(m: BroadcastEventDTO) {
    m.animation = '';
  }

  radiusChanged() {
    // this.getEvents();
    this.fitBound();
    this.ref.detectChanges();
  }

  updateCenter($event: MouseEvent) {
    console.log(`Event: ${JSON.stringify($event)}`);
    this.center = {
      lat: $event.coords.lat,
      lng: $event.coords.lng,
    }
    this.updateCenterPoint();
  }

  updateCenterPoint() {
    
    // Remove existing markers
    this.events = [];

    // this.center = {
    //   lat: $event.coords.lat,
    //   lng: $event.coords.lng,
    // }

    const geoCodeReq = { location: this.center };
    this.geoCodeService.geocode(geoCodeReq).subscribe(results => {
      console.log(`GeoCode: ${JSON.stringify(results[0])}`);
      const result: GeocoderResult = results[0];
      this.address = result.formatted_address;
      this.center = { lat: result.geometry.location.lat(), lng: result.geometry.location.lng() };
      this.getEvents();
      // this.ref.detectChanges();
    });

    // this.getEvents();

  }


  /**
     * Distance calculations
     */
  geoCodeCalcToRadian(v) {
    return v * (Math.PI / 180);
  }
  geoCodeCalcDiffRadian(v1, v2) {
    return this.geoCodeCalcToRadian(v2) - this.geoCodeCalcToRadian(v1);
  }
  geoCodeCalcCalcDistance(lat1, lng1, lat2, lng2, radius) {
    // tslint:disable-next-line:max-line-length
    return radius * 2 * Math.asin(Math.min(1, Math.sqrt((Math.pow(Math.sin((this.geoCodeCalcDiffRadian(lat1, lat2)) / 2.0), 2.0) + Math.cos(this.geoCodeCalcToRadian(lat1)) * Math.cos(this.geoCodeCalcToRadian(lat2)) * Math.pow(Math.sin((this.geoCodeCalcDiffRadian(lng1, lng2)) / 2.0), 2.0)))));
  }

  roundNumber(num, dec) {
    return Math.round(num * Math.pow(10, dec)) / Math.pow(10, dec);
  }

  onViewRadioBtnClick(value) {
    this.resultViewType = value;
    console.log(this.resultViewType);
  }
}
