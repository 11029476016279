<div class="animated fadeIn infoBox col-8 mx-auto">
  <div class="row">
    <div class="col-lg-12 customLG">
      <div class="card">
        <div class="card-header">
          <i class="fa fa-lock"></i> Privacy Policy
        </div>
        <div class="card-body text-justify">
          <!-- <p id="body"> -->
          <h3>Consent:</h3>
          <p>

            If you use any of our sites or services, you consent to the collection, use and sharing of your data under
            this
            Privacy Policy (which includes our Cookie Policy and other documents referenced in this Privacy Policy) and
            agree to
            the User Agreement. Where applicable, we will provide you choices that allow you to opt-out or control how
            we use
            and share your data.
            The only personal data we collect and store is information entered by you during your registration. We do
            not store
            information.</p>
          <p>
            This Privacy Policy is subject to modification, if you do not consent to any future changes you are under no
            obligation to keep your account open.
          </p>

          <h3>Registration data: </h3>
          <p>
            To create an account you provide data which includes City of residence, Country of residence,
            Province / State of residence and associated zip code or postal code.
          </p>

          <h3>Cookies, Web Beacons and Other Similar Technologies:</h3>
          <p>
            We collect data through cookies and similar technologies.
            We use cookies and similar technologies to authenticate you and/or your device(s) on, off and across
            different
            services and devices.
          </p>
          <p>
            We also collect this data to improve the services we provide you.
          </p>
          <h3>Polls and surveys:</h3>
          <p>Polls and surveys are conducted by us and others through our Services. You are under no obligation to
            respond to
            polls
            or surveys and you have choices about the information you wish to answer.
          </p>
          <p>
            Data collected for analysis is not directly associated to you but to attributes associated with you which do
            not
            uniquely identify you.</p>

          <h3>System and Customer Support:</h3>
          <p>We collect and review data for system improvement and maintenance.
            Data is collected to help resolve customer issues and complaints.</p>

          <h3>Change to Policy:</h3>
          <p>This policy is subject to change. We will notify you well in advance of any impending changes to this
            policy </p>

        </div>
      </div>
    </div>
  </div>
</div>