<div class="animated fadeIn infoBox col-8 mx-auto">
  <div class="row">
    <div class="col-lg-12 customLG">
      <div class="card">
        <div class="card-header">
          <i class="fa fa-users"></i>About Us
        </div>
        <div class="card-body">
          <p class="text-justify">
              We are a socially responsible company dedicated to providing a platform for you to organize, market and
            promote your yard, garage or moving sale. Our goal is to help you broadcast your sale to those within your community
            and others who might be attend from beyond your community. We are extremely excited to assist you in a
            simple and direct way to market your items to a larger audience at no cost.
            Give it a try today, you have nothing to lose and a lot to gain!!!

          </p>
        </div>
      </div>
    </div>
  </div>
</div>