import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { UserService } from '../../_services/user.service';
import { AlertService } from '../../_services/alert.service';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {

  errorTitle?: string;
  errorMsg?: string;
  statusCode?: string;
  feedback? : string;

  animated: boolean = true;
  keyboard: boolean = true;
  backdrop: boolean = true;
  ignoreBackdropClick : boolean = true;

  constructor(public modalRef: BsModalRef, private userService: UserService, private alertService: AlertService) {}
  ngOnInit(): void {
  }

  submitFeedback() : void{
    // send to sns
    this.userService.sendError({
      'subject': this.errorTitle,
      'message': this.errorMsg,
      'statuscode' : this.statusCode,
      'feedback' : this.feedback
    })
    .subscribe(
      data => {
        console.log(`Data: ${data}`);
        this.alertService.success('Error sent', true);
        this.modalRef.hide();
    },
      error => {
      this.alertService.error('Failed to send error');
      console.log(error);
    });
  }

  decline(): void {
    this.modalRef.hide();
  }
}
