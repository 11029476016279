import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { AssistSetUpDto } from 'src/app/_models';
import { UserService, AlertService } from 'src/app/_services';

@Component({
  selector: 'app-help-assist-set-up',
  templateUrl: './help-assist-set-up.component.html',
  styleUrls: ['./help-assist-set-up.component.scss']
})
export class HelpAssistSetUpComponent implements OnInit {
  model: AssistSetUpDto;
  loading = false;

  constructor(private userService: UserService
    , private alertService: AlertService
    , private router: Router) {
    this.model = {
      firstName: '',
      phoneNumber: '',
      email: '',
      address: '',
      address1: '',
      city: '',
      stateprov: '',
      code: '',
      country: '',
      date: '',
      time: '',
      comments: '',
    };
  }
  ngOnInit(): void {
  }
  sendAssistSetUpReqeust() {
    this.loading = true;
    this.userService.sendAssistSetUpReqeust(this.model)
      .subscribe(
        data => {
          this.loading = false;
          this.alertService.success(data.message, true);
          this.router.navigate(['/home']);
        },
        (error: HttpErrorResponse) => {
          console.log(`Error: ${error}`);
          this.loading = false;
        }
      );
  }

}
