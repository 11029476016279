import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-public',
  templateUrl: './simple-layout.component.html',
})
export class SimpleLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void { }
}
